import React from 'react';
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";

export default ({tagTitulo, urlBack}) => {
  
  const regresar = urlBack
    ? (<Link to={urlBack} className="linkBack">
      <BackwardFilled/>
      Regresar
    </Link>)
    : null;
  
  return (
    <Row>
      <Col xs={24} sm={24} md={24}>
        <h2 style={{marginBottom: "10px", flexGrow: 1}}>
          {tagTitulo}
          
          {regresar}
        </h2>
      
      </Col>
    </Row>
  );
};