import React from 'react';
import {Col, Row} from "antd";
import CDetalleVentaCliente from "./CDetalleVentaCliente";
import CDetalleVentaFactura from "./CDetalleVentaFactura";
import CDetalleVentaEnvio from "./CDetalleVentaEnvio";
import CDetalleVentaItems from "./CDetalleVentaItems";

const CDetalleVenta = ({venta}) => {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={16}>
          <div style={{paddingRight:"20px"}}>
            <CDetalleVentaItems venta={venta} />
          </div>
          
        </Col>
        <Col xs={24} sm={24} md={8}>
          <div className="mB20 pR5">
            <CDetalleVentaCliente venta={venta}/>
          </div>
          <div className="mB20 pR5">
            <CDetalleVentaEnvio venta={venta}/>
          </div>
          <CDetalleVentaFactura venta={venta}/>
        </Col>
      </Row>
    </>
  );
};

export default CDetalleVenta 