export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    },
    {
      campo: "tipo",
      tipo: "cbx",
      label: "Buscar X Edo",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {"key": 'df', "label": 'Ciudad de México'},
        {"key": 'mex', "label": 'Estado de México'},
        {"key": 'mor', "label": 'Morelos'},
        {"key": 'hgo', "label": 'Hidalgo'},
        {'key': 'ags', 'label': 'Aguascalientes'},
        {'key': 'bc', 'label': 'Baja California'},
        {'key': 'bcs', 'label': 'Baja California Sur'},
        {'key': 'cam', 'label': 'Campeche'},
        {'key': 'chi', 'label': 'Chihuahua'},
        {'key': 'chp', 'label': 'Chiapas'},
        {'key': 'coa', 'label': 'Coahuila'},
        {'key': 'col', 'label': 'Colima'},
        {'key': 'dur', 'label': 'Durango'},
        {'key': 'gro', 'label': 'Guerrero'},
        {'key': 'gto', 'label': 'Guanajuato'},
        {'key': 'jal', 'label': 'Jalisco'},
        {'key': 'mic', 'label': 'Michoacán'},
        {'key': 'nay', 'label': 'Nayarit'},
        {'key': 'nl', 'label': 'Nuevo León'},
        {'key': 'oax', 'label': 'Oaxaca'},
        {'key': 'pue', 'label': 'Puebla'},
        {'key': 'qro', 'label': 'Querétaro'},
        {'key': 'roo', 'label': 'Quintana Roo'},
        {'key': 'sin', 'label': 'Sinaloa'},
        {'key': 'slp', 'label': 'San Luis Potosí'},
        {'key': 'son', 'label': 'Sonora'},
        {'key': 'tab', 'label': 'Tabasco'},
        {'key': 'tam', 'label': 'Tamaulipas'},
        {'key': 'tlx', 'label': 'Tlaxcala'},
        {'key': 'ver', 'label': 'Veracruz'},
        {'key': 'yuc', 'label': 'Yucatán'},
        {'key': 'zac', 'label': 'Zacatecas'}
      ]
    }
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -") {
      return true;
    }
    const valorNom = filtroValor.trim().toLowerCase();
    let isIncluir = true;
    
    if (isIncluir && filtroNombre === "Buscar Texto") {
      if (valorNom !== "") {
        isIncluir = `${item.nombre}|${item.email}|${item.tel}`.toLowerCase().includes(valorNom);
      }
    }
  
    if (isIncluir && filtroNombre === "Buscar X Edo" && filtroValor !== "") {
    
      if (valorNom !== "" && item.data.edo !== filtroValor) {
        isIncluir = false;
      }
    }
    
    return isIncluir
  }
}
;

