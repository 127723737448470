export default (listaHeader, listaBody) => {
  
  const htmlRowHeader = listaHeader.reduce((total, item) => {
    total += `<td>${item}</td>`;
    return total
  }, '');
  
  const htmlRowBody = listaBody.reduce((total, row) => {
    
    const htmlRow = row.reduce((totalRow, col) => {
      totalRow += `<td>${col}</td>`;
      return totalRow;
    }, '');
    total += `<tr>${htmlRow}</tr>`;
    return total
  }, '');
  
  const htmlFull = `<table><thead>${htmlRowHeader}</thead><tbody>${htmlRowBody}</tbody></table>`;
  
  var temp = window.document.createElement("INPUT");
  document.body.append(temp);
  temp.value = htmlFull;
  temp.select();
  document.execCommand("copy");
  temp.remove();
}

