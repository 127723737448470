import React from 'react';

export default (props) => {
  
  return (
    <table className="tableDataForm fix">
      <tbody>
      <tr>
        <th>
          Contenido
        </th>
        <td/>
      </tr>
      </tbody>
    </table>
  );
};