import Button from "antd/es/button";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import SmileFilled from "@ant-design/icons/lib/icons/SmileFilled";
import React from "react";

export default ({id_pag_contenido,setIsModoEdit,indexItem ,subtitulo})=>{

  return (
    <div className="wrapperItemContenido itemSincontenido">
    
      <div className="hasTextCenter p5All" title={"ID:" + id_pag_contenido}>
        <Button onClick={() => setIsModoEdit(true)} style={{marginRight: "10px"}}>
          <EditFilled/>
        </Button>
        <strong>Elemento {(indexItem + 1)} Opcional </strong> - {subtitulo} <SmileFilled/>
      </div>
    </div>
  );
};