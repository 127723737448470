import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import FormUsuario from "./FormUsuario";
import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";
import CEditPermisos from "./CEditPermisos";


/*
{
"idUsuario","userCreateDate",
"userLastModifiedDate","enabled",
"estatus","phone_number","sucursales",
"email":"david@productividadti.com.mx","tipo":"adm"
}]
Col 2
Col 3333333

setRutaActual( {Path:UrlPage.ADMIN_USUARIO_EDIT, id:idUsuario});

* */


export default () => {
  
  const history = useHistory();
  const {idUsuario} = useParams();
  
  const [usuario, setUsuario] = useState(null);
  const [listaTipoPermiso, setListaPermisoTipo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
      
      const respuesta = await DataService.Admin.Usuario.Ver(idUsuario, true);
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Editar " + idUsuario, respuesta.msg);
        return;
      }
      
      const u = respuesta.data.usuario;
      setUsuario(u);
      setListaPermisoTipo(respuesta.data.listaTipoPermiso);
      setIsLoaded(true);
      
    })();
    
  }, [idUsuario]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  const onUsuarioSaved = () => {
    let titulo = (idUsuario === null) ? "Nuevo Usuario" : `Actualización ${usuario.nombre} `;
    DataStore.UsuarioIndex.SetIdSelected( idUsuario);
    FlashService.Registrar.Info(titulo, "OK");
    history.push(UrlPage.ADMIN_USUARIO_INDEX);
    
  };
  
  const onFormPermisoSubmited = (listaIdPermisoTipoNew) => {
  
  }
  
  const tagTitulo = !!usuario.idUsuario ? "Editar" : "Crear";
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{display: "flex", width: "100%"}}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          {tagTitulo} Usuario
        </h2>
      </Col>
    </Row>
    
    <Row>
      <Col xs={24} sm={24} md={12}>
        <FormUsuario
          usuario={usuario}
          onUsuarioSaved={onUsuarioSaved}/>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <CEditPermisos
          idUsuario={usuario.id_usuario}
          listaPermisos={usuario.listaPermiso}
          listaTipoPermiso={listaTipoPermiso}
          onFormSubmited={onFormPermisoSubmited}
        />
    </Col>
    </Row>
  </>);
  
}