import LibCopyXls from "../../../../lib/LibCopyXls";


export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id_pag', "titulo","url_rel", "urlAbs","descripction"];
  
  const listaCampos=listaHeader.filter(s =>s !=="#");
  
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    
    const listaValor = [(indexk + 1)];
    
    listaCampos.forEach(key => {
      listaValor.push(k[key]);
    });
    
    return listaValor;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}