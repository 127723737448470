export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    },
    {
      campo: "tipo",
      tipo: "cbx",
      label: "Buscar Tipo de Usuario",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {key: "adm", label: "Administrador"},
        {key: "test", label: "Cuenta para Pruebas"},
        {key: "asesor", label: "Asesor"}
      ]
    }
  
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -") {
      return true;
    }
    const valorNom = filtroValor.trim().toLowerCase();
    let isIncluir = true;
    
    //aplicar filtro -----------------------------------
    if (isIncluir && filtroNombre === "Buscar Tipo de Usuario" && filtroValor !== "") {
      
      if (valorNom !== "" && item.tipo !== filtroValor) {
        isIncluir = false;
      }
    }
    
    if (isIncluir && filtroNombre === "Buscar Texto") {
      if (valorNom !== "") {
        isIncluir = `${item.email}|${item.nombre}|${item.phone_number}|${item.sucursales}`.toLowerCase().includes(valorNom);
      }
    }
    
    return isIncluir
  }
}
;

