import React, {useEffect, useState} from 'react';
import CLoading from "../../../../components/page/CLoading";
import './contenidoHtml.css'
import HeaderElementoContenido from "./HeaderElementoContenido";


import CSinContenido from "./CSinContenido";
import CS3Uploader from "./CS3Uploader";
import DataService from "../../../../service/data/DataService";
import FileFilled from "@ant-design/icons/lib/icons/FileFilled";


export default ({itemContenido, indexItem, onItemUpdated, onShowDelElementModal}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [body, setBody] = useState(null);
  const [isEnProceso, setIsEnProceso] = useState(false);
  
  
  useEffect(() => {
    
    setBody(itemContenido.body || '');
    setIsModoEdit(false);
    setIsLoaded(true);
    
  }, [itemContenido]);
  
  
  if (!isLoaded) {
    return <CLoading mensaje="Loading Contenido"/>
  }
  
  
  /* MODO EDIT *********************************************** */
  if (isModoEdit) {
    
    const onClickSave = () => {
      
      if (isEnProceso) {
        return
      }
      
      setIsEnProceso(true);
      
      const cbOnFinishUpdate = (isSuccess) => {
        setIsEnProceso(false);
        if (isSuccess === true) {
          setIsModoEdit(false)
        }
      };
      
      onItemUpdated(body, indexItem, cbOnFinishUpdate);
    }
    
    
    const onUrlUploaded = (url) => {
      setBody(url);
    };
    
    const msgEnProceso = isEnProceso
      ? <CLoading mensaje="Guardando Elemento"/>
      : null;
    
    
    return (
      <div className="wrapperEditorHtml">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          onClickSave={onClickSave}
          subtitulo = "Archivo de Descarga"
          onClickDelete={() => onShowDelElementModal(indexItem)}
          setIsModoEdit={setIsModoEdit}
          isEdit={true}
          id={itemContenido.id||''}
          codigo={itemContenido.codigo||''}
        />
        
        <div className="wrapperEditImg">
          <CS3Uploader
            url={body}
            accept={["file/*","application/*"]}
            onUrlUploaded={onUrlUploaded}
            fnRenderUrl={(url) => <p>{url}</p>}
            getUploadS3Url={DataService.Editor.PagContenido.getUploadS3UrlFile}
            subtitulo="El archivo de descarga (PDF/Office)"
            codigo={itemContenido.codigo||''}
          />
        </div>
        
        {msgEnProceso}
      </div>
    );
    
  }
  
  
  /* MODO render  ********************************************* */
  
  
  if (body) {
    
    
    return (
      <div className="wrapperItemContenido">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          setIsModoEdit={setIsModoEdit}
          subtitulo = "Archivo de Descarga"
          isEdit={false}
          id={itemContenido.id_pag_contenido||''}
        />
        
        <div className="wrapperRenderImg mtopMax">
          <FileFilled style={{fontSize:"5em"}} />
          <div className="hasTextCenter">
            <a href={body} target="_blank" rel="noopener noreferrer">{body}</a>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <CSinContenido id_pag_contenido={itemContenido.id_pag_contenido}
                   setIsModoEdit={setIsModoEdit}
                   indexItem={indexItem}
                   subtitulo="Sin Link"
    />
  );
  
  
}