import React from "react";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import PicCenterOutlined from "@ant-design/icons/lib/icons/PicCenterOutlined";


export default ({model, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "update", icon: <EditFilled/>, label: "Editar", style: {color: "green"}},
    {key: "additemprom", icon: <PicCenterOutlined />, label: "Agregar a Items Recomendados"},
  ];
  
  const tituloModal = (
    <>
      <span>Actualización </span>
      {/*<strong style={{color: "green"}}>{item.freg}</strong>*/}
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={model}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}