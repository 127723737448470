import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import { Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import pagFiltroParam from "./tablaPag/PagFiltroParam";
import CmdPagRow from "./tablaPag/CmdPagRow";
import ATabla from "../../../components/tabla/ATabla";
import CmdPagIndex from "./tablaPag/CmdPagIndex";
import FnCopyXlsPag from "./tablaPag/FnCopyXlsPag";
import IeOutlined from "@ant-design/icons/lib/icons/IeOutlined";
import CeldaID from "../../../components/page/CeldaID";


export default () => {
  const history = useHistory();
  
  const [listaPag, setListaPag] = useState([]);
  
  const [filtroPag, setFiltroPag] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaPagFiltrado, setListaPagFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.PagIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
  
      const respuesta = await DataService.Editor.Pag.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaPag(lista);
        DataStore.PagIndex.Set(lista);
        LibShowNotificacion.Success("Páginas", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }

    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaPag.filter(pag => {
      return pagFiltroParam.fn(pag, filtroPag.current, filtroPag.valor)
    });
    setListaPagFiltrado(lista);
    
  }, [listaPag, filtroPag]);
  
  
  const onCmdRowEnd = async (operacion, pag) => {
    
    const {id_pag} = pag;
    
    if (operacion === "update") {
      setIdSelected(id_pag);
      history.push(UrlPage.PAG_EDIT + `/${id_pag}`);
    }
    
  };
  
  
  const listaElem = listaPagFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_pag ? " rowSelected" : "");
    
    
    return (
      <Row key={index} className={rowCSS}>
  
        <CeldaID id={k.id_pag} size={2}>
          <CmdPagRow pagina={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        
        <Col xs={24} sm={12} md={12} className="divFlex">
  
          <div  className="flexGrow1">
            <Link to={UrlPage.PAG_EDIT + `/${k.id_pag}`}>
              <span className="labMob">Título:</span> {k.titulo}
            </Link>
            <div className="colorGray">
              <span className="labMob">UrlRel:</span> {k.url_rel}
            </div>
          </div>
          
  
          <a href={k.urlAbs} target="_blank" rel="noopener noreferrer">
            <IeOutlined style={{fontSize:"30px"}} />
          </a>
          
        
        </Col>
        
      
        <Col xs={24} sm={6} md={6}>
          <span className="labMob">Descripción:</span> {k.description}
        </Col>
        
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID/>
      
      <Col xs={24} sm={12} md={12}>
        Título / Url Rel
      </Col>
      <Col xs={24} sm={8} md={8}>
        Descripción
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsPag(listaPagFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdPagIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaPagFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Item"
      filtroConfig={pagFiltroParam.config}
      setFiltro={setFiltroPag}
      filtro={filtroPag}
    />
  );
  
}