import React, {useEffect, useState} from 'react';

import {Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import CLoading from "../../../components/page/CLoading";
import CCostoEnvio from "./CCostoEnvio";
import RowTitulo from "../../../components/page/RowTitulo";
import TablaFiltro from "../../../components/tabla/TablaFiltro";
import CostoEnvioFiltroParam from "./CostoEnvioFiltroParam";
import CostoEnvioModalEdit from "./CostoEnvioModalEdit";


const CCostoEnvioIndex = () => {
  
  const [lista, setLista] = useState([]);
  const [listaFiltro, setListaFiltro] = useState([]);
  const [filtro, setFiltro] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [stateMain, setStateMain] = useState({isLoaded: false});
  const [itemOnEdit, setItemOnEdit] = useState(null);
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Admin.CostoEnvio.Index();
      if (respuesta.success) {
        setLista(respuesta.data.lista);
        LibShowNotificacion.Success("Costo de Envio", "Datos Recibidos")
        setStateMain(({isLoaded: true}))
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
        setStateMain(({isLoaded: true, msgError: respuesta.msg}))
      }
    })();
    
  }, []);
  
  useEffect(() => {
    
    const listaNew = lista.filter(k => CostoEnvioFiltroParam.fn(k, filtro.current, filtro.valor));
    
    setListaFiltro(listaNew);
    
  }, [filtro, lista])
  
  if (!stateMain.isLoaded) {
    return <CLoading/>
  }
  
  if (stateMain.msgError) {
    return <p className="error">{stateMain.msgError}</p>
  }
  
  const onClickModalSave = (isSaved, formData) => {
    
    if (!isSaved) {
      setItemOnEdit(null);
      return;
    }
    //hacer el request de nuevos propieades
    
    
    const listaNew = lista.map(k => {
      if (k.edo === itemOnEdit.edo) {
        return {...k, ...formData}
      } else {
        return k;
      }
    });
    
    setLista(listaNew)
    
    //terminar
    setItemOnEdit(null);
    
  }
  
  const onShowEdit = (itemShow) => {
    setItemOnEdit({...itemShow});
  }
  
  const listaElemTr = listaFiltro.map((k, indexK) => {
    return <CCostoEnvio index={indexK + 1} item={k} onShowEdit={onShowEdit}/>
  });
  
  
  return (
    <>
      <RowTitulo tagTitulo="Costos de Envío"/>
      <TablaFiltro
        filtroConfig={CostoEnvioFiltroParam.config}
        setFiltro={setFiltro}
        filtro={filtro}
      />
      <Row>
        <Col xs={24} sm={24} md={24}>
        
        </Col>
        <Col xs={24} sm={24} md={18} className="pL5">
          <table className="tableEstructura">
            <thead>
            <tr>
              <td style={{width: "60px"}}>#</td>
              <td>Estado</td>
              <td>Zona</td>
              <td style={{width: "120px"}}>Costo de Envío</td>
              <td style={{width: "60px"}}/>
            </tr>
            </thead>
            <tbody>
            {listaElemTr}
            </tbody>
          </table>
        </Col>
        <Col xs={24} sm={24} md={6}>
        
        </Col>
        <CostoEnvioModalEdit
          item={itemOnEdit}
          visible={itemOnEdit !== null}
          onClickModalSave={onClickModalSave}
        />
      </Row>
    </>
  )
  
}

export default CCostoEnvioIndex;