const UrlPage = {
  
  LOGIN: '/login',
  CUENTA: '/cuenta',
  SALIR: '/salir',
  
  /*admin*/
  ADMIN_USUARIO_INDEX: '/admin-usuarios-index',
  ADMIN_USUARIO_ADD: '/admin-usuario-add',
  ADMIN_USUARIO_EDIT: '/admin-usuario-edit',
  
  ADMIN_ACTUALIZACION_INDEX: '/admin-actualizacion-index',
  ADMIN_ACTUALIZACION_VER: '/admin-actualizacion-ver',
  
  ADMIN_FORM_INDEX: '/admin-form-index',
  ADMIN_FORM_EDIT: '/admin-form-edit',
  ADMIN_FORM_ADD: '/admin-form-edit',
  
  ADMIN_VAR_CONFIG_INDEX: '/admin-varconfig-index',
  ADMIN_VAR_CONFIG_EDIT: '/admin-varconfig-edit',
  ADMIN_VAR_CONFIG_ADD: '/admin-varconfig-edit',
  
  ADMIN_COSTO_ENVIO: '/admin-costoenvio-index',
  
  HOME: '/',
  
  /*General ************************************* */
  ITEM_INDEX: '/item-index',
  ITEM_EDIT: '/item',
  ITEM_ADD: '/item-add',
  
  ITEMPROM_INDEX: '/itemprom-index',
  
  PAGFORM_INDEX: '/pagform-index',
  PAGFORM_EDIT: '/pagoform',
  
  PROMOCION_INDEX: '/promocion-index',
  PROMOCION_EDIT: '/promocion-edit',
  PROMOCION_ADD: '/promocion-add',
  
  
  
  VENTAS_INDEX: '/venta-index',
  VENTAS_VER: '/venta-ver',
  
  
  ACTUALIZACION_ADD: '/actualizacion-add',
  ACTUALIZAR_SITIO:'/actualizar_sitio',
  
  RECORDATORIO_EVENTO_INDEX: '/recordatorio-index',
  RECORDATORIO_EVENTO_EDIT: '/recordatorio-edit',
  RECORDATORIO_EVENTO_ADD: '/recordatorio-add',
  
  PRUEBA_PG_BANCOMER_INDEX: '/prueba-pg-bancomer-index',
  PRUEBA_PG_BANCOMER_VER: '/prueba-pg-bancomer-ver',
  PRUEBA_PG_BANCOMER_ADD: '/prueba-pg-bacnomer-add',
  
  PRUEBA_PG_BP_INDEX: '/prueba-pg-bp-index',
  PRUEBA_PG_BP_VER: '/prueba-pg-bp-ver',
  PRUEBA_PG_BP_ADD: '/prueba-pg-bp-add',
  
  PRUEBA_PG_BANAMEX_INDEX: '/prueba-pg-banamex-index',
  PRUEBA_PG_BANAMEX_VER: '/prueba-pg-banamex-ver',
  PRUEBA_PG_BANAMEX_ADD: '/prueba-pg-banamex-add',
  
  
  PAG_INDEX: '/pagina-index',
  PAG_EDIT: '/pagina-edit',
  
  /*clientes*/
  CLIENTE_INDEX: '/cliente-index',
  CLIENTE_VER: '/cliente-ver',
  
  /*MC*/
  
  MC_INDEX:'/mc_index'
  
};

export default UrlPage;
