import React from 'react';
import Button from "antd/es/button";
import FactoryImg from "../icons/FactoryImg";

const CButtonBack = ({history, urlTarget , cb}) => {
  
  
  const fn=()=>{
    if(typeof cb==="function"){
      cb();
      history.push(urlTarget);
    }else{
      history.push(urlTarget);
    }
  }
  return (
    <>
      <Button type="default"
              className="mR5"
              title="Regresar"
              onClick={fn}>
        {FactoryImg.Back()}
      </Button>
    </>
  );
};

export default CButtonBack