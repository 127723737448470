import React from "react";
import {Modal} from "antd";
import FormCostoEnvio from "./FormCostoEnvio";


const CostoEnvioModalEdit = ({item, visible, onClickModalSave}) => {
  
  if (item === null) {
    return null;
  }
  
  
  return (
    <Modal
      title={`Costo Envío a ${item.estado}`}
      visible={visible}
      onOk={() => onClickModalSave(true)}
      onCancel={() => onClickModalSave(false)}
      footer={[
        null,
        null
      ]}
    >
      <h3 style={{color: "green"}}>{item.estado}</h3>
      
      <FormCostoEnvio
        itemCosto={item}
        onClickModalSave={onClickModalSave}/>
    
    </Modal>
  );
}

export default CostoEnvioModalEdit;