import React, {useEffect, useState} from 'react';

import HeaderContenidoModel from "./HeaderContenidoModel";
import ImgRender from "../../page/editor/pag/cElement/ImgRender";
import CS3Uploader from "../../page/editor/pag/cElement/CS3Uploader";
import DataService from "../../service/data/DataService";


export default ({fnSave, urlImg, subtitulo = 'Imagen'}) => {
  
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [isEnProceso, setIsEnProceso] = useState(false);
  const [url, setUrl] = useState('');
  
  useEffect(() => {
    setIsModoEdit(false);
    setUrl(urlImg);
  }, [urlImg]);
  
  
  const onAfterSave = (isOK) => {
    
    setIsEnProceso(false);
    if (isOK) {
      setIsModoEdit(false);
    }
    
  };
  
  
  const onClickSave = async () => {
    if (isEnProceso) {
      return
    }
    
    //Comenzar a hacer el update
    fnSave(url, onAfterSave);
  }
  
  const onUrlUploaded = (urlNew) => {
    setUrl(urlNew);
  };
  
  const control = isModoEdit
    ? (
      <div className="wrapperEditImg">
        
        <CS3Uploader
          url={url}
          accept="image/*"
          onUrlUploaded={onUrlUploaded}
          fnRenderUrl={(url) => <img src={url} alt="" title={url}/>}
          getUploadS3Url={DataService.Editor.getUploadS3Url}
          subtitulo="la imagen (jpg/png)"
        />
      
      </div>
    )
    : <div className="wrapperRenderImg"><ImgRender url={url}/></div>
  ;
  
  
  return (
    <div style={{paddingBottom: "20px"}}>
      <HeaderContenidoModel
        isModoEdit={isModoEdit}
        subtitulo={subtitulo}
        setIsModoEdit={setIsModoEdit} onClickSave={onClickSave}/>
      <div>
        {control}
      </div>
    
    </div>
  )
    ;
}