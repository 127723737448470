import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import itempromFiltroParam from "./tabla/ItemPromFiltroParam";
import ATabla from "../../../components/tabla/ATabla";

import CmdItemPromIndex from "./tabla/CmdItemPromIndex";
import FnCopyXlsItemProm from "./tabla/FnCopyXlsItemProm";
import CeldaID from "../../../components/page/CeldaID";
import ItemPromModalDelete from "./ItemPromModalDelete";
import LinkNewPage from "../../../components/tabla/LinkNewPage";
import CmdItemPromRow from "./tabla/CmdItemPromRow";
import LinkImgRaw from "../../../components/tabla/LinkImgRow";
import LibFormat from "../../../lib/LibFormat";


export default () => {
  const history = useHistory();
  
  const [listaItemProm, setListaItemProm] = useState([]);
  
  const [dataModalDelete, setDataModalDelete] = useState(null);
  const [isModalDeleteEnProceso, setIsModalDeleteEnProceso] = useState(false);
  
  const [filtroItemProm, setFiltroItemProm] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaItemPromFiltrado, setListaItemPromFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.ItemPromIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Editor.ItemProm.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaItemProm(lista);
        DataStore.PagIndex.Set(lista);
        LibShowNotificacion.Success("Items", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaItemProm.filter(k => {
      return itempromFiltroParam.fn(k, filtroItemProm.current, filtroItemProm.valor)
    });
    setListaItemPromFiltrado(lista);
    
  }, [listaItemProm, filtroItemProm]);
  
  
  const onCmdRowEnd = async (operacion, itemProm) => {
    
    const {id_item} = itemProm;
    
    if (operacion === 'delete') {
      setDataModalDelete(itemProm);
    } else {
      LibShowNotificacion.Alert("este comando no esta mapeado " + operacion)
    }
    
  };
  
  
  const listaElem = listaItemPromFiltrado.map((k, index) => {
    let rowCSS = "rowTabla rowTablaConBorde " + (idSelected === k.id_item ? " rowSelected" : "");
  
    rowCSS += k.is_enpausa ? " itemenpausa" : "";
    
    return (
      <Row key={index} className={rowCSS}>
    
        <CeldaID id={k.id_item} size={3}>
          <CmdItemPromRow model={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
    
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Marca:</span> {k.marca}
        </Col>
    
    
    
        <Col xs={24} sm={11} md={12} className="divFlex">
          <div className="flexGrow1">
            <Link to={UrlPage.ITEM_EDIT + `/${k.id_item}`}>
              <span className="labMob">Model:</span> <strong>{k.modelo}</strong>
            </Link>
            <div className="colorGray">
              <span className="labMob">Descripcion:</span> {k.descripcion}
            </div>
          </div>
          <LinkNewPage urlAbs={k.urlAbs}/>
          <LinkImgRaw urlAbs={k.urlImg}/>
        </Col>
    
    
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Entrada:</span> {k.entrada}<br/>
          <span className="labMob">Tipo:</span> <span className="textoTipo">{k.tipo}</span>
        </Col>
    
    
        <Col xs={24} sm={3} md={3} className="textoPrecio">
          <span className="labMob">Precio:</span>
          <span className="colorResaltar">
          $ {LibFormat.Money(k.precio, 2)}
          </span>
    
        </Col>
  
  
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID size={3}/>
    
      <Col xs={24} sm={3} md={3}>
        Marca
      </Col>
    
      <Col xs={24} sm={12} md={12}>
        Modelo / Descripción
      </Col>
    
      <Col xs={24} sm={3} md={3}>
        Entrada / Tipo
      </Col>
    
    
      <Col xs={24} sm={3} md={3} className="hasTextRight">
        Precio
      </Col>
  
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsItemProm(listaItemPromFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdItemPromIndex onCmdEnd={onCmdIndexEnd}/>;
  
  const onClickModalDelete = async (isBottonOK) => {
    if (isModalDeleteEnProceso) {
      return;
    }
    
    setIsModalDeleteEnProceso(true);
    
    if (isBottonOK === true) {
      const respuesta = await DataService.Editor.ItemProm.Delete(dataModalDelete.id_item);
      if (!respuesta.success) {
        setIsModalDeleteEnProceso(false);
        LibShowNotificacion.Alert(respuesta.msg);
        return;
      }
      
      LibShowNotificacion.Success("Item Promocionado Eliminado", dataModalDelete.titulo);
      const lista = listaItemProm.filter(k => {
        return k.id_item !== dataModalDelete.id_item
      });
      setListaItemProm(lista);
    }
    
    setIsModalDeleteEnProceso(false);
    setDataModalDelete(null);
    
  };
  
  const modalDelete = (
    <ItemPromModalDelete item={dataModalDelete}
                         visible={dataModalDelete !== null}
                         onClickModalDelete={onClickModalDelete}
    />
  );
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaItemPromFiltrado.length}
      modalDelete={modalDelete}
      cmdIndex={getCmdIndex()}
      tituloModel="Items promocionados en recomendación"
      filtroConfig={itempromFiltroParam.config}
      setFiltro={setFiltroItemProm}
      filtro={filtroItemProm}
    />
  );
  
}