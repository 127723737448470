import React, {useEffect, useState} from 'react';
import {Button, Card, Checkbox,  Form, Input, InputNumber} from "antd";
import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FlashService from "../../../app/FlashService";


const FormItem = Form.Item;
const {TextArea} = Input;


export default ({item, onItemSaved}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      descripcion: item.descripcion,
      precio: item.precio,
      is_enpausa: item.is_enpausa,
      pos_home: item.pos_home
    });
    
  }, [item, form]);
  
  const onSubmitForm = async (formData) => {
    
    setIsEnProceso(true);
    
    
    let respuesta = await DataService.Editor.Item.Update(item.id_item, formData);
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    let msgSave = "Item Actualizado";
    
    FlashService.Registrar.Alert(item.id_item, msgSave);
  
    onItemSaved(item);
  };
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelGrande}
      onFinish={onSubmitForm}
      style={{border:"1px solid #d3d3d3", borderRadius:"3px"}}
    >
      <Card >
        
        
        <FormItem
          label="Descripción"
          name="descripcion"
          rules={[{required: false}]} hasFeedback>
          <TextArea/>
        </FormItem>
        
        <FormItem
          label="Precio MXN"
          name="precio"
          rules={[{required: true}]} hasFeedback>
          <InputNumber min={10} max={60000} step={.1}/>
        </FormItem>
        
        <hr/>
        
        <FormItem
          label="Poner en Pausa"
          name="is_enpausa"
          valuePropName="checked"
          rules={[{required: false}]} hasFeedback>
          <Checkbox/>
        </FormItem>
  
        <FormItem
          label="Posicion Home"
          name="pos_home"
          rules={[{required: true}]} hasFeedback>
          <InputNumber min={0} max={30} step={1}/>
        </FormItem>


      </Card>
      <div style={{marginTop: "20px", textAlign: "center"}}>
        
        <Button type="primary"
                htmlType="submit"
                style={{width: "180px"}}
                loading={isEsProceso} block>
          <CloudUploadOutlined/>
          <span>Guardar</span>
        </Button>
        
        <div className="msgError" >
          {msgError}
        </div>
      
      </div>
    </Form>
  
  );
}