import React from 'react';
import {Button, Col, Row} from "antd";
import {EditFilled} from "@ant-design/icons";


export default ({pagForm, onSetModoEdit}) => {
  
  
  return (
    
    <Row>
      <Col xs={24} sm={24} md={24}>
        <div className="wrapperTableDataForm">
          <table className="tableDataForm fix">
            <tbody>
            <tr>
              <th>Nombre</th>
              <td>
                <h3 className="campomain">{pagForm.nombre}</h3>
              
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{pagForm.email}</td>
            </tr>
            <tr>
              <th>Comentario</th>
              <td>{pagForm.comentario}</td>
            </tr>
            </tbody>
          </table>
          <div className="hasTextRight mtopMin">
            <Button onClick={()=>onSetModoEdit()}>
              <EditFilled/>
              Editar
            </Button>
          </div>

        </div>
      </Col>
    </Row>
  );
};