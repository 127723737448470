import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import promFiltroParam from "./tablaProm/PromFiltroParam";
import ATabla from "../../../components/tabla/ATabla";


import CmdPromIndex from "./tablaProm/CmdPromIndex";
import FnCopyXlsProm from "./tablaProm/FnCopyXlsProm";
import CmdPromRow from "./tablaProm/CmdPromRow";
import * as LibFecha from "../../../lib/LibFecha";
import CeldaID from "../../../components/page/CeldaID";
import PromModalDelete from "./PromModalDelete";
import LinkNewPage from "../../../components/tabla/LinkNewPage";


export default () => {
  const history = useHistory();
  
  const [listaProm, setListaProm] = useState([]);
  
  const [dataModalDelete, setDataModalDelete] = useState(null);
  const [isModalDeleteEnProceso, setIsModalDeleteEnProceso] = useState(false);
  
  const [filtroProm, setFiltroProm] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaPromFiltrado, setListaPromFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.PromIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Editor.Prom.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaProm(lista);
        DataStore.PagIndex.Set(lista);
        LibShowNotificacion.Success("Promociones", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaProm.filter(pag => {
      return promFiltroParam.fn(pag, filtroProm.current, filtroProm.valor)
    });
    setListaPromFiltrado(lista);
    
  }, [listaProm, filtroProm]);
  
  
  const onCmdRowEnd = async (operacion, prom) => {
    
    const {id_promocion} = prom;
    
    if (operacion === "edit") {
      setIdSelected(id_promocion);
      history.push(UrlPage.PROMOCION_EDIT + `/${id_promocion}`);
    } else if (operacion === 'delete') {
      setDataModalDelete(prom);
    } else {
      LibShowNotificacion.Alert("este comando no esta mapeado " + operacion)
    }
    
  };
  
  
  const listaElem = listaPromFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_promocion ? " rowSelected" : "");
    
    const imgMain = k.url_img
      ? <img alt="" src={k.url_img} className="imgRowTabla"/>
      : null
    
    return (
      <Row key={index} className={rowCSS}>
        
        <CeldaID id={k.id_promocion}>
          <CmdPromRow prom={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        
        <Col xs={24} sm={12} md={12} className="divFlex">
          <Link className="flexGrow1" to={UrlPage.PROMOCION_EDIT + `/${k.id_promocion}`}>
            <span className="labMob">Título:</span> {k.titulo}
            <div className="colorGray">
              <span className="labMob">UrlRel:</span> {k.url_rel}
            </div>
          </Link>
          {imgMain}
          <LinkNewPage urlAbs={k.urlAbs}/>
        </Col>
        
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Estatus:</span> {k.is_activo ? "Activo" :
          <span style={{color: "red"}}>En Pausa</span>}
        </Col>
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">F Inicio:</span> {LibFecha.getDMYFromYMD(k.fecha_ini)}
        </Col>
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">F Fin:</span> {LibFecha.getDMYFromYMD(k.fecha_final)}
        </Col>
      
      
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID/>
      
      <Col xs={24} sm={12} md={12}>
        Título
      </Col>
      <Col xs={24} sm={3} md={3}>
        Estatus
      </Col>
      <Col xs={24} sm={3} md={3}>
        Fecha Inicio
      </Col>
      <Col xs={24} sm={3} md={3}>
        Fecha Final
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "add") {
      DataStore.PromIndex.SetIdSelected(null);
      history.push(UrlPage.PROMOCION_ADD)
      
    } else if (operacion === "copiar_xls") {
      FnCopyXlsProm(listaPromFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdPromIndex onCmdEnd={onCmdIndexEnd}/>;
  
  const onClickModalDelete = async (isBottonOK) => {
    if (isModalDeleteEnProceso) {
      return;
    }
    
    setIsModalDeleteEnProceso(true);
    
    if (isBottonOK === true) {
      const respuesta = await DataService.Editor.Prom.Delete(dataModalDelete.id_promocion);
      if (!respuesta.success) {
        setIsModalDeleteEnProceso(false);
        LibShowNotificacion.Alert(respuesta.msg);
        return;
      }
      
      LibShowNotificacion.Success("Promoción Eliminada", dataModalDelete.titulo);
      const lista = listaProm.filter(k => {
        return k.id_promocion !== dataModalDelete.id_promocion
      });
      setListaProm(lista);
    }
    
    setIsModalDeleteEnProceso(false);
    setDataModalDelete(null);
    
  };
  
  const modalDelete = (
    <PromModalDelete prom={dataModalDelete}
                     visible={dataModalDelete !== null}
                     onClickModalDelete={onClickModalDelete}
    />
  );
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaPromFiltrado.length}
      modalDelete={modalDelete}
      cmdIndex={getCmdIndex()}
      tituloModel="Promoción"
      filtroConfig={promFiltroParam.config}
      setFiltro={setFiltroProm}
      filtro={filtroProm}
    />
  );
  
}