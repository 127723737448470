import React from 'react';
import TablaFiltro from "./TablaFiltro";

import './table.css';

export default ({
                  header, numItemsFiltro, listaElem, modalDelete, cmdIndex,
                  tituloModel, filtroConfig, setFiltro, filtro
                }) => {
  
  
  return (
    <>
      <div className="toolbarTabla">
        {cmdIndex}
        <h3>
          {tituloModel} ({numItemsFiltro})
        </h3>
        <TablaFiltro
          filtroConfig={filtroConfig}
          setFiltro={setFiltro}
          filtro={filtro}
        />
      </div>
      
      {header}
      {listaElem}
      {modalDelete}
    </>
  );
  
}