import LibCopyXls from "../../../../lib/LibCopyXls";


export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id_item', "id_act", "tipo", "marca", "modelo", "entrada", "precio", "descripcion", "key_img", "created_at", "updated_at", "urlImg"];
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    
    const listaValor = [(indexk + 1)];
    
    Object.keys(k).forEach(key => {
      listaValor.push(k[key]);
    });
    
    return listaValor;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}