import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Badge, Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import mcFiltroParam from "./tablaMc/McFiltroParam";
import CmdMcRow from "./tablaMc/CmdMcRow";
import ATabla from "../../../components/tabla/ATabla";
import CmdActIndex from "./tablaMc/CmdMcIndex";
import FnCopyXlsAct from "./tablaMc/FnCopyXlsMc";
import CeldaID from "../../../components/page/CeldaID";
import McItem from "./tablaMc/McItem";
import CmdMcIndex from "./tablaMc/CmdMcIndex";



export default () => {
  const history = useHistory();
  
  const [listaMc, setlistaMc] = useState([]);
  
  const [filtroMc, setfiltroMc] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaMcFiltrado, setlistaMcFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.McIndex.GetIdSelected() || null);
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Mc.Index();
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setlistaMc(lista);
        DataStore.ActIndex.Set(lista);
        LibShowNotificacion.Success("Mensajes", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaMc.filter(item => {
      return mcFiltroParam.fn(item, filtroMc.current, filtroMc.valor)
    });
    setlistaMcFiltrado(lista);
    
  }, [listaMc, filtroMc]);
  
  
  const onCmdRowEnd = async (operacion, mc) => {
    
    // const {id_mc} = mc;
    
    // if (operacion === "ver") {
    //   setIdSelected(id_act);
    //   history.push(UrlPage.ADMIN_ACTUALIZACION_VER + '/' + id_act);
    // }
    
  };
  
  const listaElem = listaMcFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_mc? " rowSelected" : "");
    
    
    return (
      <Row key={index} className={rowCSS}>
  
        <CeldaID id={k.id_mc}>
          <CmdMcRow mc={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Formulario:</span> {k.form_name}
        </Col>
        <Col xs={24} sm={6} md={6}>
          <span className="labMob">Nombre:</span>
          {k.data.nombre} <br/>
          {k.data.email} <br/>
          {k.data.tel}
        </Col>
        <Col xs={24} sm={2} md={1}>
          <span className="labMob">Estado:</span> {k.data.edo.toUpperCase()}
        </Col>
  
  
        <Col xs={24} sm={2} md={2}>
          <span className="labMob">Fecha:</span> {k.freg}
        </Col>
  
        <Col xs={24} sm={8} md={7}>
          <McItem data={k.data} />
        </Col>


      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <Col xs={24} sm={4} md={2}>
        ID
      </Col>
      <Col xs={24} sm={3} md={3} style={{textAlign:"left"}}>
        Formulario
      </Col>
      <Col xs={24} sm={6} md={6}>
        Nombre / Email
      </Col>
      <Col xs={24} sm={2} md={1}>
        Edo
      </Col>
      <Col xs={24} sm={2} md={2}>
        Fecha
      </Col>
      <Col xs={24} sm={8} md={7}>
        MC
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsAct(listaMcFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdMcIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaMcFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Mensajes"
      filtroConfig={mcFiltroParam.config}
      setFiltro={setfiltroMc}
      filtro={filtroMc}
    />
  );
  
}