import LibCopyXls from "../../../../lib/LibCopyXls";


export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id_pag_form', "nombre", "email","comentario"];
  
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
   
    
    const listaValor = [(indexk + 1)];
    
    Object.keys(k).forEach(key => {
      listaValor.push(k[key]);
    });
    
    return listaValor;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}