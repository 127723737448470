import React, {useEffect} from 'react';
import AuthService from "../../service/auth/AuthService";


const PageSalir = () => {
  
  useEffect(() => {
    AuthService.setLogOut();
  }, []);
  
  return <div/>
  
};

export default PageSalir