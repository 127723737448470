import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import { Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import FormFiltroParam from "./tablaForm/FormFiltroParam";
import CmdFormRow from "./tablaForm/CmdFormRow";
import ATabla from "../../../components/tabla/ATabla";


import CmdFormIndex from "./tablaForm/CmdFormIndex";
import FnCopyXlsForm from "./tablaForm/FnCopyXlsForm";
import CeldaID from "../../../components/page/CeldaID";


export default () => {
  const history = useHistory();
  
  const [listaForm, setListaForm] = useState([]);
  
  const [filtroForm, setFiltroForm] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaFormFiltrado, setListaFormFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.PagFormIndex.GetIdSelected() || null);
  
  
  useEffect(() => {
    (async () => {
  
      const respuesta = await DataService.Admin.PagForm.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaForm(lista);
        DataStore.PagFormIndex.Set(lista);
        LibShowNotificacion.Success("Formularios", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }

    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaForm.filter(form => {
      return FormFiltroParam.fn(form, filtroForm.current, filtroForm.valor)
    });
    setListaFormFiltrado(lista);
    
  }, [listaForm, filtroForm]);
  
  
  const onCmdRowEnd = async (operacion, pagForm) => {
    
    const { id_pag_form} = pagForm;
    
    if (operacion === "update") {
      setIdSelected(id_pag_form);
      history.push(UrlPage.ADMIN_FORM_EDIT + `/${id_pag_form}`);
    }
    
  };
  
  
  const listaElem = listaFormFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_pag_form ? " rowSelected" : "");
    
    
    return (
      <Row key={index} className={rowCSS}>
  
        <CeldaID id={k.id_pag_form}>
          <CmdFormRow item={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        
        
        <Col xs={24} sm={4} md={4}>
          {k.nombre}
        </Col>
  
        <Col xs={24} sm={4} md={7}>
          {k.email}
        </Col>
  
  
        <Col xs={24} sm={6} md={10}>
          {k.comentario}
        </Col>




      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID/>
      <Col xs={24} sm={4} md={4}>
        Nombre
      </Col>
      <Col xs={24} sm={4} md={7}>
        Email
      </Col>
      <Col xs={24} sm={13} md={10}>
        Comentario
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    
    if (operacion === "add") {
      DataStore.UsuarioIndex.SetIdSelected( null);
      history.push(UrlPage.ADMIN_FORM_ADD)
    
    } else if (operacion === "copiar_xls") {
      FnCopyXlsForm(listaFormFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
    
  };
  const getCmdIndex = () => <CmdFormIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaFormFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Formularios"
      filtroConfig={FormFiltroParam.config}
      setFiltro={setFiltroForm}
      filtro={filtroForm}
    />
  );
  
}