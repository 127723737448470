import React, {useEffect, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';

import HeaderContenidoModel from "./HeaderContenidoModel";

const parse = require('html-react-parser');

export default ({fnSave, body, subtitulo='Contenido'}) => {
  
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [isEnProceso, setIsEnProceso] = useState(false);
  const [html, setHtml] = useState('');
  
  useEffect(() => {
    setIsModoEdit(false);
    setHtml(body);
  }, [body]);
  
  const handleEditorChange = (e) => {
    setHtml(e.target.getContent());
  }
  
  const control = isModoEdit
    ? (<div style={{maxHeight:"460px"}}><Editor
      apiKey="jivpxq83jimcghxso8cb5qi7r0fvqseqeeohhgz3n2j8a95w"
      initialValue={html || ''}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image',
          'charmap print preview anchor help',
          'searchreplace visualblocks code',
          'insertdatetime media table paste wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
      }}
      onChange={handleEditorChange}
    
    /></div>)
    : <div style={{maxHeight:"460px", overflowY:"scroll", paddingRight:"10px"}}>{parse(html)}</div>
  ;
  
  const onAfterSave = (isOK) => {
    
    setIsEnProceso(false);
    if(isOK){
      setIsModoEdit(false);
    }
    
  };
  
  
  const onClickSave = async () => {
    if (isEnProceso) {
      return
    }
    
    //Comenzar a hacer el update
    fnSave(html, onAfterSave);
  }
  
  return (
    <div style={{paddingBottom:"20px;"  }}>
      <HeaderContenidoModel isModoEdit={isModoEdit}
                            subtitulo={subtitulo}
                            setIsModoEdit={setIsModoEdit} onClickSave={onClickSave}/>
      <div className="fondoWebContent">
        <div className="wrapperWebContent">
          {control}
        </div>
      </div>
    </div>
  );
};