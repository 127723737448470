import React from "react";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import FileFilled from "@ant-design/icons/lib/icons/FileFilled";


export default ({act, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "ver", icon: <FileFilled/>, label: "Ver", style: {color: "green"}},
  ];
  
  const tituloModal = (
    <>
      <span>Actualización </span>
      {/*<strong style={{color: "green"}}>{item.freg}</strong>*/}
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={act}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}