const dicKey = {
  TOKEN_IDENTIDAD: 'tokenidentidad',
  PUBLIC_DATA: 'public',
  TSVALID: 'tsvalid',
};

const prefix = 'tt4_';

const getItem = (key) => {
  key = `${prefix}${key}`
  const v = window.localStorage.getItem(key);
  if (v === undefined || v === null) {
    return null;
  }
  return JSON.parse(v);
};

const saveItem = (key, valor) => {
  key = `${prefix}${key}`
  window.localStorage.setItem(key, JSON.stringify(valor))
};

const deleteItem = (key) => {
  window.localStorage.removeItem(`${prefix}${key}`);
};

const LibLocalStorage = {
  getItem, saveItem, deleteItem,
  
  saveTokenIdentidad: (valor) => saveItem(dicKey.TOKEN_IDENTIDAD, valor),
  getTokenIdentidad: () => getItem(dicKey.TOKEN_IDENTIDAD),
  deleteTokenIdentidad: () => deleteItem(dicKey.TOKEN_IDENTIDAD),
  
  savePublicData: (valor) => saveItem(dicKey.PUBLIC_DATA, valor),
  getPublicData: () => getItem(dicKey.PUBLIC_DATA),
  deletePublicData: () => deleteItem(dicKey.PUBLIC_DATA),
  
  saveTsValid: (valor) => saveItem(dicKey.TSVALID, valor),
  getTsValid: () => getItem(dicKey.TSVALID),
  deleteTsValid: () => deleteItem(dicKey.TSVALID),
  
}

export default LibLocalStorage;