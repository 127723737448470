import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import { Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import ventaFiltroParam from "./tablaVenta/VentaFiltroParam";
import CmdVentaRow from "./tablaVenta/CmdVentaRow";
import ATabla from "../../../components/tabla/ATabla";
import CmdVentaIndex from "./tablaVenta/CmdVentaIndex";
import FnCopyXlsVenta from "./tablaVenta/FnCopyXlsVenta";
import CeldaID from "../../../components/page/CeldaID";
import LibFormat from "../../../lib/LibFormat";
import DicEstatusVenta from "../../../model/DicEstatusVenta";



export default () => {
  const history = useHistory();
  
  const [listaVenta, setListaVenta] = useState([]);
  
  const [filtroVenta, setFiltroVenta] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaVentaFiltrado, setListaVentaFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.VentaIndex.GetIdSelected() || null);
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Editor.Venta.Index();
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaVenta(lista);
        DataStore.VentaIndex.Set(lista);
        LibShowNotificacion.Success("Ventas", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaVenta.filter(item => {
      return ventaFiltroParam.fn(item, filtroVenta.current, filtroVenta.valor)
    });
    setListaVentaFiltrado(lista);
    
  }, [listaVenta, filtroVenta]);
  
  
  const onCmdRowEnd = async (operacion, venta) => {
    
    const {id_venta} = venta;
    
    if (operacion === "ver") {
      setIdSelected(id_venta);
      history.push(UrlPage.VENTAS_VER + '/' + id_venta);
    }
    
  };
  
  const listaElem = listaVentaFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_venta ? " rowSelected" : "");
    
    
    const guia = k.guia_envio
      ? <span className="label labelInfo" title={`Guía ${k.paqueteria}`}>{k.guia_envio}</span>
      : <span className="label labelAlert" title={`Guía ${k.paqueteria}`}>Guía Pendiente</span>
    ;
    
    const tagID = (
      <span onClick={() => history.push(`${UrlPage.VENTAS_VER}/${k.id_venta}`)}
            title="Ver Venta"
            className="cursorPointer">
      {k.id_venta}
    </span>);
    
    const dataGuia = k.id_venta_estatus === DicEstatusVenta.Cancelado
      ?( <div className="divFlex" >
        <span className="labMob">Estado de Envio / Guia:</span>
        <span>{k.estadoEnvio}</span>
        <span>{k.guia ? ('Guía ' + k.guia) : 'Guía Pendiente'}</span>
      </div>)
      : ( <div className="divFlex">
        <span className="labMob">Estado de Envio / Guia:</span>
        <span className="fontBig mR5">{k.estadoEnvio}</span>
        <span className="mR5">{guia}</span>
      </div>);
    
    return (
      <Row key={index} className={rowCSS}>
        
        <CeldaID id={tagID} children2={<div><span className="labelMin">Serie {k.serie}</span></div>}>
          <CmdVentaRow venta={k} onCmdEnd={onCmdRowEnd}/>
        
        </CeldaID>
        
        <Col xs={24} sm={6} md={3}>
          
          <span className="labMob">Comprador:</span>
          {k.nombre}<br/>
          <span className="labelInfo labelMin">ID {k.id_comprador}</span>
        </Col>
        <Col xs={24} sm={6} md={5} title={ `ID Estatus ${k.id_venta_estatus}`}>
          <span className="labMob">Estatus: </span>
          <span className={`labelEstatusVenta${k.id_venta_estatus}`}>{k.estatus}</span>
        </Col>
        
        <Col xs={24} sm={4} md={2} className="hasTextRight">
          <span className="labMob">Total: </span>
          <strong>$ {LibFormat.Money(k.gran_total)}</strong>
        </Col>
        <Col xs={24} sm={6} md={3}>
          <span className="labMob">Fecha:</span> {k.fechaDMY}
        </Col>
        
        <Col xs={24} sm={12} md={9} className="hasTextCenter">
          {dataGuia}
          <div>
            <span className="labMob">Comentario:</span> {k.comentario_adm}
          </div>
        </Col>
      
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <Col xs={24} sm={4} md={2}>
        ID
      </Col>
      <Col xs={24} sm={6} md={3}>
        Comprador
      </Col>
      <Col xs={24} sm={6} md={5}>
        Estatus
      </Col>
      <Col xs={24} sm={4} md={2} className="hasTextRight">
        Total
      </Col>
      <Col sm={3} md={3}>
        Fecha
      </Col>
      <Col xs={24} sm={12} md={9}>
        Envio / Comentario
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsVenta(listaVentaFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdVentaIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaVentaFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Ventas"
      filtroConfig={ventaFiltroParam.config}
      setFiltro={setFiltroVenta}
      filtro={filtroVenta}
    />
  );
  
}