import React from "react";
import Button from "antd/es/button";
import {EditFilled, UploadOutlined} from "@ant-design/icons";

/***
 *
 * @param onClickSave
 * @param setIsModoEdit
 * @param isModoEdit
 * @param subtitulo
 * @returns {*}
 */
export default ({onClickSave, setIsModoEdit, isModoEdit, subtitulo}) => {
  
  const onClickEdit = () => {
    console.log('fffffffff');
    setIsModoEdit(true)
  }
  
  
  let cmd = null;
  if (isModoEdit) {
    
    cmd = (<>
      <Button type="primary" className="botonsave"
              onClick={() => onClickSave()}>
        <UploadOutlined/>
        Guardar
      </Button>
      <Button onClick={() => setIsModoEdit(false)}>
        Cancelar
      </Button>
    </>);
    
  } else {
    cmd = (
      <Button type="primary" className="botonsave" onClick={() => onClickEdit()}>
        <EditFilled/>
        Editar
      </Button>
    );
    
  }
  
  
  return (
    <div className="panTB1">
      <span style={{flexGrow: "1", fontWeight: "bold"}}>
            <span style={{marginLeft: "15px"}}>{subtitulo}</span>
      </span>
      {cmd}
    </div>
  );
  
}
;