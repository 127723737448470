import React, {useEffect, useState} from 'react';
import {Button, Input, Form, Card} from 'antd';
import LibFormAnt from "../../lib/LibFormAnt";
import FormSolicitarRecPass from "./FormSolicitarRecPass";
import {RedEnvelopeFilled, SendOutlined} from "@ant-design/icons";
import DataService from "../../service/data/DataService";

const FormItem = Form.Item;


const FormLogin = ({onLoginSuccess}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isHideFormLogin, setIsHideFormLogin] = useState(false);
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.setFieldsValue({
      email: "",
      pass: ""
    });
    
    
  }, [form]);
  
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    
    setIsEnProceso(true);
    setMsgError("");
    
    //es un create
    const respuesta = await DataService.login(formData.email, formData.pass);
    
    if (!respuesta.success) {
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    console.log('yyyyy');
    onLoginSuccess(respuesta.data);
    
  };
  
  const cssFormLogin = isHideFormLogin ? "none" : "block";
  const cssFormRec = isHideFormLogin ? "block" : "none";
  const onShowLogin = () => {
    setIsHideFormLogin(false);
  }
  
  return (
    <div>
      <div style={{display: cssFormLogin}}>
        
        <Form
          form={form}
          validateMessages={LibFormAnt.ConfigMsgValidacion}
          {...LibFormAnt.LayOut8_16}
          onFinish={onSubmitForm}
        >
          <Card bordered={true}>
            
            <h2 className="has-text-centered mB20">Ingresar Sitio Administrativo</h2>
            
            <FormItem
              label="Email"
              name="email"
              rules={[{required: true}]} hasFeedback>
              <Input/>
            </FormItem>
            
            <FormItem
              label="Password"
              name="pass"
              rules={[{required: true}]} hasFeedback>
              <Input type="password"/>
            </FormItem>
          
          
          </Card>
          
          <div className="divFlexC" style={{justifyContent: "center"}}>
            <Button
              onClick={() => setIsHideFormLogin(true)}
              className="btnMain  mt-20">
              <SendOutlined/>
              Recuperar Contraseña
            </Button>
            <div style={{width: "30px"}}/>
            
            <Button type="primary"
                    htmlType="submit"
                    loading={isEsProceso}>
              
              <span>Ingresar</span>
            </Button>
          </div>
          
          <div className="msgError">
            {msgError}
          </div>
        
        </Form>
      </div>
      
      
      <div style={{display: cssFormRec}}>
        <FormSolicitarRecPass setIsHideFormRec={onShowLogin}/>
      </div>
    
    </div>
  );
};

export default FormLogin