import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Button, Col, Modal, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import FormVentaGuia from "./update/FormVentaGuia";
import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";
import LibFormat from "../../../lib/LibFormat";
import FormVentaEntrega from "./update/FormVentaEntrega";
import DicEstatusVenta from "../../../model/DicEstatusVenta";
import CloseSquareOutlined from "@ant-design/icons/lib/icons/CloseSquareOutlined";
import ShoppingCartOutlined from "@ant-design/icons/lib/icons/ShoppingCartOutlined";
import CButtonBack from "../../../components/page/CButtonBack";
import CDetalleVenta from "./detalle/CDetalleVenta";
import FormVentaCom from "./update/FormVentaCom";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FormVentaCancelar from "./update/FormVentaCancelar";

const ModalCancelar = ({onVentaCancelada, venta, isVisible, setVisible}) => {
  
  if (!isVisible) {
    return null;
  }
  
  const onVentaSaved = () => {
    setVisible(false);
    onVentaCancelada()
  }
  
  return (
    <Modal
      title="Cancelar Venta"
      visible={true}
      onCancel={() => setVisible(false)}
      footer={[]}
    >
      <FormVentaCancelar venta={venta} onVentaSaved={onVentaSaved}/>
    
    </Modal>
  );
}

export default () => {
  
  const history = useHistory();
  const {idVenta} = useParams();
  
  const [venta, setVenta] = useState(null);
  const [isVisibleModalCancel, setIsVisibleModalCancel] = useState(false);
  
  
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    
    (async () => {
      
      const respuesta = await DataService.Editor.Venta.Ver(idVenta, true);
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Editar " + idVenta, respuesta.msg);
        return;
      }
      
      const venta = respuesta.data.venta;
      venta.ventaEstatus = DicEstatusVenta.getLabelFromID(venta.id_venta_estatus);
      setVenta(venta);
      setIsLoaded(true);
      
    })();
    
  }, [idVenta]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  const onFechaEntregaSaved = (dataUpdate) => {
    setVenta({...venta, ...dataUpdate})
  };
  
  
  let elemFormEntrega = null;
  
  if (venta.fecha_entrega === null) {
    if (venta.guia_envio) {
      elemFormEntrega = <FormVentaEntrega venta={venta} onVentaSaved={onFechaEntregaSaved}/>
    }
  } else {
    elemFormEntrega = (
      <div className="hasTextCenter">
        Entregado el día <br/><strong>{venta.fecha_entregaDMY}</strong>
      </div>
    )
    
    
  }
  
  const isShowFormGuia = venta.id_venta_estatus === DicEstatusVenta.PagadoYEsperandoEntrega;
  const elemFormGuia = isShowFormGuia
    ? <FormVentaGuia venta={venta}/>
    : (<div className="hasTextCenter mB20">Guía <strong>{venta.guia_envio}</strong> / {venta.paqueteria} </div>);
  
  
  const cmdCancelar = DataStore.GetIsAdmin()
    ?
    <Button><CloseSquareOutlined title="Cancelar" style={{color: "red"}} onClick={() => setIsVisibleModalCancel(true)}/></Button>
    : <Button><CloseSquareOutlined title={"Cancelar No Disponible"}
                                   onClick={() => LibShowNotificacion.Info("Se requiere ser administrador para cancelar una venta")}/></Button>;
  
  const onVentaCancelada = () => {
    DataStore.VentaIndex.SetIdSelected(venta.id_venta);
    history.push(`${UrlPage.VENTAS_INDEX}`)
  }
  
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{display: "flex", width: "100%"}}>
        <h2 style={{marginBottom: "10px", flexGrow: 1}}>
          <CButtonBack
            history={history} urlTarget={UrlPage.VENTAS_INDEX}
            cb={() => DataStore.VentaIndex.Set(venta.id_venta)}/>
          Venta Tienda Telcel
          <ShoppingCartOutlined className="mL5 mR5"/>
          <span className="label labelPrimary">
            {venta.id_venta}
          </span>
          <span className="label labelSuccess mL5">
            $ {LibFormat.Money(venta.gran_total)}
          </span>
        </h2>
        <div>{cmdCancelar}</div>
      </Col>
    </Row>
    
    <div className="hasTextCenter" style={{margin: "20px"}}>
        <span className={`labelEstatusVenta${venta.id_venta_estatus}`}>
            {venta.ventaEstatus}
          </span>
    </div>
    <Row>
      <Col xs={24} sm={24} md={12}>
        {elemFormGuia}
        {elemFormEntrega}
      
      </Col>
      
      <Col xs={24} sm={24} md={12}>
        <FormVentaCom venta={venta}/>
      </Col>
    </Row>
    <hr/>
    
    <CDetalleVenta venta={venta}/>
    
    <ModalCancelar venta={venta}
                   onVentaCancelada={onVentaCancelada}
                   isVisible={isVisibleModalCancel}
                   setVisible={setIsVisibleModalCancel}
    />
  </>);
  
}