import React, {useEffect, useState} from 'react';

import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import ElementModalDelete from "./ElementModalDelete";
import CLoading from "../../../components/page/CLoading";
import CAddContenido from "./cElement/CAddContenido";
import CSlider3 from "./cElement/CSlider3";
import CHtml from "./cElement/CHtml";
import CImg from "./cElement/CImg";
import CDownload from "./cElement/CDownload";
import CIframe from "./cElement/CIframe";
import CHtmlRaw from "./cElement/CHtmlRaw";
import CBloque3 from "./cElement/CBloque3";

export default ({listaContenido, onListaContenidoUpdated, id_pag}) => {
  
  const [isEnProceso, setIsEnProceso] = useState(false);
  const [indexDelete, setIndexDelete] = useState(null);
  
  useEffect(() => {
    setIsEnProceso(false);
    setIndexDelete(null);
  }, [listaContenido, id_pag]);
  
  
  const onItemContenidoUpdated = async (newBody, indexItem, cbOnFinishUpdate) => {
    const lista = [...listaContenido];
    
    const item = listaContenido[indexItem];
    
    item.isModoEdit = false;
    item.body = newBody;
    
    /* Hace rel request del update*/
    const respuesta = await DataService.Editor.PagContenido.UpdateBody(item.id_pag_contenido, newBody);
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      cbOnFinishUpdate(false);
      return;
    }
    
    onListaContenidoUpdated(lista);
    cbOnFinishUpdate(true);
  };
  
  
  const onShowDelElementModal = (indexToDelete) => {
    setIndexDelete(indexToDelete);
  };
  
  let indexK;//inicializado como
  
  const lista = listaContenido.map(k => {
    if (indexK === undefined) {
      indexK = 0;
    } else {
      indexK++;
    }
    
    
    const tipo = k.id_pag_contenido_tipo;
    
    
    if (tipo === "html") {
      return (<CHtml itemContenido={k}
                    key={indexK}
                    indexItem={indexK}
                    onShowDelElementModal={onShowDelElementModal}
                    onItemUpdated={onItemContenidoUpdated}/>);
  
    } else if (tipo === "html_raw") {
      return (<CHtmlRaw itemContenido={k}
                    key={indexK}
                    indexItem={indexK}
                    onShowDelElementModal={onShowDelElementModal}
                    onItemUpdated={onItemContenidoUpdated}/>);
                    
                    
    } else if (tipo === "img") {
      return (<CImg itemContenido={k}
                    key={indexK}
                    indexItem={indexK}
                    onShowDelElementModal={onShowDelElementModal}
                    onItemUpdated={onItemContenidoUpdated}/>
      );
      
    } else if (tipo === "slider3") {
      return (<CSlider3 itemContenido={k}
                        key={indexK}
                        indexItem={indexK}
                        subtituloHeader={`Slider de 3 Imagenes - ${k.comentario}`}
                        onShowDelElementModal={onShowDelElementModal}
                        onItemUpdated={onItemContenidoUpdated}/>
      );
  
    } else if (tipo === "bloqueS3") {
      return (<CBloque3 itemContenido={k}
                        key={indexK}
                        subtituloHeader={`Bloque Estático Pequeño de 3 Imagenes - ${k.comentario}`}
                        indexItem={indexK}
                        onShowDelElementModal={onShowDelElementModal}
                        onItemUpdated={onItemContenidoUpdated}/>
      );
      
    } else if (tipo === "iframe") {
      return (<CIframe itemContenido={k}
                       key={indexK}
                       indexItem={indexK}
                       onShowDelElementModal={onShowDelElementModal}
                       onItemUpdated={onItemContenidoUpdated}/>
      );
      
    } else if (tipo === "download") {
      return (<CDownload itemContenido={k}
                         key={indexK}
                         indexItem={indexK}
                         onShowDelElementModal={onShowDelElementModal}
                         onItemUpdated={onItemContenidoUpdated}/>
      );
    } else {
      return <div key={indexK}>Tipo no reconocido {tipo}</div>
    }
  });
  
  
  const onContenidoAdded = (itemNew) => {
    onListaContenidoUpdated([...listaContenido, itemNew])
  };
  
  const onDeleteElement = async (isConfirmDelete) => {
    
    if (!isConfirmDelete) {
      setIndexDelete(null);
      return
    }
    
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    
    /* Hacer el request**/
    const element = listaContenido[indexDelete];
    const respuesta = await DataService.Editor.PagContenido.Delete(element.id_pag_contenido);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert(respuesta.msg);
      setIsEnProceso(false);
      setIndexDelete(null);
      return;
    }
    
    LibShowNotificacion.Success("Contenido Eliminado");
    setIsEnProceso(false);
    setIndexDelete(null);
    
    const listaNewContenido = listaContenido.filter(k => k.id_pag_contenido !== element.id_pag_contenido);
    onListaContenidoUpdated(listaNewContenido);
    
    
  }
  
  const modalDelete = indexDelete === null
    ? null
    : <ElementModalDelete
      onClickModalDelete={onDeleteElement}
      titulo={`Elemento ${indexDelete + 1} (${listaContenido[indexDelete].id_pag_contenido_tipo})`}
    />
  
  const msgIsEnProceso = isEnProceso
    ? <CLoading mensaje="Eliminando"/>
    : null;
  
  return (
    <>
      {lista}
      <CAddContenido onContenidoAdded={onContenidoAdded} idPag={id_pag}/>
      {modalDelete}
      {msgIsEnProceso}
    </>
  );
};