import React, {useState} from 'react';
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import CLoading from "../../../components/page/CLoading";
import LibFormat from "../../../lib/LibFormat";
import {Button} from "antd";

export default ({solicitud, onCancelar, onActualizacionTerminada}) => {
  
  const [isExeEnProceso, setIsExeEnProceso] = useState(false);
  const [msgError, setMsgError] = useState(null);
  
  const onExeClick = async () => {
    if (isExeEnProceso) {
      return;
    }
    
    setIsExeEnProceso(true);
    
    const respuesta = await DataService.Editor.Act.ExeSolicitud(solicitud.keyjson);
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error", respuesta.msg);
      setIsExeEnProceso(false);
      setMsgError(respuesta.msg);
      return;
    }
    const id_act = respuesta.data.id_act;
    
    setIsExeEnProceso(false);
    onActualizacionTerminada(id_act);
  };
  
  
  const panError = msgError
    ? <p className="panMsgError">{msgError}</p>
    : null;
  
  const msgEnProceso = isExeEnProceso
    ? <CLoading mensaje="Ejecutando Actualización"/>
    : null;
  
  
  const tdSuccess = solicitud.isValid
    ?
    <td style={{backgroundColor: "lightgreen", color: "#fff", textAlign: "center", fontSize: "25px", padding: "5px"}}
        colSpan="2">Archivo
      Válido</td>
    : <td style={{backgroundColor: "red", color: "#fff", textAlign: "center", fontSize: "25px", padding: "5px"}}
          colSpan="2">Archivo
      No Válido</td>
  
  
  const listaTr = solicitud.listaItems.map(r => {
    
    const cssTr = r.isError ? "itemerror" : "";
    
    return (<tr className={cssTr} key={r.index}>
        <td>{r.index}</td>
        <td>{r.IDENTIFICADOR}</td>
        <td>{r.MARCA}</td>
        <td>{r.MODELO}</td>
        <td>{r.DESCRIPCION}</td>
        <td className="textoPrecio">$ {LibFormat.Money(r.PRECIO)}</td>
        <td>{r.TIPO}</td>
        <td>{r.ENTRADA}</td>
      </tr>
    )
  });
  
  const listaTrError = solicitud.listaItems
    .filter(k => k.isError)
    .map(r => {
      return (<tr className="itemerror" key={r.index}>
          <td>{r.index}</td>
          <td className="hasTextBold">{r.IDENTIFICADOR}</td>
          <td>
            <div>
               Marca: {r.MARCA} Modelo {r.MODELO} Descripción {r.DESCRIPCION} Tipo {r.TIPO}
              Entrada {r.ENTRADA} Precio $ {LibFormat.Money(r.PRECIO)}
            </div>
            <div style={{color:"red"}}>
              {r.msgError}
            </div>
          
          </td>
        
        </tr>
      )
    });
  
  const cmdBtn = solicitud.isValid
    ? <Button type="primary" loading={isExeEnProceso} style={{width: "200px"}}
              onClick={() => onExeClick()}>Actualizar</Button>
    : null;
  
    
    const seccionErrorTr=listaTrError.length ===0
      ? null
      : (<div><h3> {listaTrError.length} Errores</h3> <table className="tableDataForm"><tbody>{listaTrError}</tbody></table></div>)
      
      
  
  return (
    <div>
      {panError}
      {msgEnProceso}
  
      {seccionErrorTr}
      
      <table className="tableDataForm fix">
        <thead/>
        <tbody>
        <tr>
          {tdSuccess}
        </tr>
        <tr>
          <th>KEY_XLS</th>
          <td>{solicitud.keyxls}</td>
        </tr>
        <tr>
          <th>KEY_JSON</th>
          <td>{solicitud.keyjson}</td>
        </tr>
        <tr>
          <th># Items</th>
          <td>{solicitud.numItems}</td>
        </tr>
        </tbody>
      </table>
      
      <h4 style={{marginTop: "20px", textAlign: "center"}}>Items</h4>
      
      <div style={{padding: "10px 5px"}}>
        <table className="tablaItemsSolicitud">
          <thead>
          <tr>
            <th style={{width: "40px"}}>#</th>
            <th style={{width: "120px"}}>ID</th>
            <th style={{width: "140px"}}>Marca</th>
            <th style={{width: "200px"}}>Modelo</th>
            <th>Descripción</th>
            <th style={{width: "100px", textAlign: "right"}}>Precio</th>
            <th>Tipo</th>
            <th>Entrada</th>
          </tr>
          </thead>
          <tbody>
          {listaTr}
          </tbody>
        </table>
        
        <div style={{marginTop: "30px", textAlign: "center"}}>
          {cmdBtn}
          <Button onClick={onCancelar} style={{marginLeft: "20px", width: "200px"}}>Cancelar</Button>
        </div>
      </div>
    
    </div>
  );
  
};

