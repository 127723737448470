import React, {useEffect, useState} from 'react';
import {Button, Input, Form, Card, Alert} from 'antd';
import LibFormAnt from "../../lib/LibFormAnt";

import {SendOutlined} from "@ant-design/icons";
import DataService from "../../service/data/DataService";


const FormItem = Form.Item;

const FormSolicitarRecPass = ({setIsHideFormRec}) => {
  
  const [stateForm, setStateForm] = useState({});
  const [form] = Form.useForm();
  
  const updateStateForm = (dicCampos) => {
    const estadoNew = {...stateForm, ...dicCampos};
    setStateForm(estadoNew);
  }
  
  useEffect(() => {
    form.setFieldsValue({
      email: ""
    });
    
  }, [form])
  
  
  const onSubmitForm = async (formData) => {
  
    console.log('x')
    if (stateForm.isEnProceso) {
      return;
    }
  
    updateStateForm({isEnProceso: true, msgError: ''})
  
    const respuesta = await DataService.recpass.solicitar(formData.email);
  
    if (respuesta.success) {
      updateStateForm({msgError: null, isEnProceso: false, isSubmited: true, isSuccess: true})
    } else {
      updateStateForm({msgError: respuesta.msg, isEnProceso: false, isSubmited: true, isSuccess: false})
    }
  
  
    //No se requiere ningun paso
  }
  
  if (stateForm.isSubmited && stateForm.isSuccess) {
    return <Alert
      message="Enviamos un correo con las instruccion para cambiar tu password. Tienes 10 minutos para completar el proceso."
      type="success"/>
  }
  
  
  return (
    <div>
      
      
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOut8_16}
        onFinish={onSubmitForm}
      >
        <Card bordered={true}>
          
          <h2 className="has-text-centered mB20">Cambiar contraseña</h2>
          
          <p>Te enviaremos un link con instrucciones para tener una nueva contraseña.</p>
          <FormItem
            label="Email"
            name="email"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
        </Card>
        
        <div className="divFlexC" style={{justifyContent: "center"}}>
          <Button
            onClick={() => setIsHideFormRec(true)}
            className="btnMain  mt-20">
            Ya tengo mi contraseña
          </Button>
          <div style={{width: "30px"}}/>
          
          <Button type="primary"
                  htmlType="submit"
                  loading={stateForm.isEsProceso}>
            <SendOutlined/>
            <span>Solicitar contraseña</span>
          </Button>
        </div>
        
        <div className="msgError">
          {stateForm.msgError}
        </div>
      
      </Form>
    
    
    </div>
  );
};


export default FormSolicitarRecPass