import React, {useEffect, useState} from 'react';
import './estructura_formulario.css';
import {DeleteFilled, DownloadOutlined, PlusCircleFilled} from "@ant-design/icons";
import {Button} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

export default ({pagForm, onEstructuraUpdated}) => {
  
  const [model, setModel] = useState([]);
  
  const [isEnProceso, setIsEnProceso] = useState(false);
  
  
  useEffect(() => {
    setModel(JSON.parse(pagForm.estructura || '[]'));
  }, [pagForm])
  
  const onChangeProp = (indexItem, prop, newValue) => {
    
    let modelNew = [...model];
    modelNew[indexItem][prop] = newValue;
    
    if (prop === "Tipo") {
      modelNew[indexItem]["TipoMetas"] = "";
    }
    
    
    // if (prop === "pos") {
    //   modelNew = modelNew.sort((a, b) => a.pos - b.pos)
    // }
    setModel(modelNew);
  };
  const listaTipo = ['text', 'email', 'number', 'textarea', 'check', 'cbx', 'file'];
  const listaTipoOption = listaTipo.map(k => {
    return <option key={k} value={k}>{k}</option>;
  });
  
  
  const onCmdDeleteItemForm = (item) => {
    let lista = [...model];
    
    lista = lista.filter(k => {
      return !(k.pos === item.pos && k.Titulo === item.Titulo);
    });
    
    setModel(lista);
  };
  
  const listaTR = model.map((item, indexItem) => {
    
    const metaPropTipoCBX = item.Tipo === 'cbx'
      ? (<><span>Separar por ; las opciones</span>
        <input title="Opciones Separadas" type="text" className="form-control" value={item.TipoMetas}
               onChange={(event) => onChangeProp(indexItem, "TipoMetas", event.target.value)}/></>)
      : null;
    
    return <tr key={indexItem} className="itemStructura">
      <td>
        <input title="Posicón" type="text" className="form-control"
               onChange={(event) => onChangeProp(indexItem, "pos", event.target.value)}
               value={item.pos}
        />
      </td>
      <td><input title="Titulo/Texto como aparecerá en el formulario"
                 type="text"
                 className="form-control"
                 onChange={(event) => onChangeProp(indexItem, "Titulo", event.target.value)}
                 value={item.Titulo}/></td>
      
      <td><input title="Id del Campo"
                 type="text"
                 className="form-control"
                 onChange={(event) => onChangeProp(indexItem, "ID", event.target.value)}
                 value={item.ID}/>
      </td>
      
      
      <td>
        <select title="Tipo de Campo"
                className="form-control  w100pc"
                value={item.Tipo}
                onChange={(event) => onChangeProp(indexItem, "Tipo", event.target.value)}
        >{listaTipoOption}</select>
        
        {metaPropTipoCBX}
      </td>
      
      <td>
        
        <input title="Es Campo Requerido"
               type="checkbox"
               className="form-control"
               checked={item.Req ? "checked" : ""}
               value={item.Req ? "checked" : ""}
               onChange={(event) => {
                 onChangeProp(indexItem, "Req", !event.target.value)
               }}
        />
      </td>
      
      <td>
        <Button onClick={() => onCmdDeleteItemForm(item)}>
          <DeleteFilled/>
        </Button>
      </td>
    
    </tr>
    
    
  });
  
  const onClickSave = async () => {
    
    if (isEnProceso) {
      return;
    }
    
    const modelSorted = model.sort((a, b) => {
      return a.pos < b.pos;
    })
    setModel(modelSorted);
    
    setIsEnProceso(false);
    
    const estructura = JSON.stringify(modelSorted);
    
    const dicUpdate = {
      estructura
    };
    
    const respuesta = await DataService.Admin.PagForm.Update(pagForm.id_pag_form, dicUpdate);
    if (!respuesta.success) {
      LibShowNotificacion.Alert(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    LibShowNotificacion.Success("Formulario Actualizado");
    setIsEnProceso(false);
    
    onEstructuraUpdated(model);
    
    
  };
  
  const addPropiedad = () => {
    let posFinal = 1
    if (model.length > 0) {
      posFinal = model[model.length - 1].pos
    }
    
    const newModel = [...model,]
  };
  
  return (
    
    <div style={{paddingBottom: "20px"}}>
      <table className="tableDataForm fix">
        <tbody>
        <tr>
          <th>
            Estructura
          </th>
          <td/>
        </tr>
        </tbody>
      </table>
      
      <div>
        
        <table className="table tableEstructura">
          <thead>
          <tr>
            <th className="w80" title="Posición - Orden Ascendente">Pos</th>
            <th className="wTitulo">Titulo</th>
            <th className="w180">Campo ID</th>
            <th className="wTipo">Tipo</th>
            <th className="w120 hasTextCenter">Req</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {listaTR}
          </tbody>
        </table>
        
        <div className="hasTextCenter" style={{marginTop: "20px"}}>
          <Button style={{float: "right"}}
                  onClick={addPropiedad}
                  title="Agregar Propieadad">
            <PlusCircleFilled/>
          </Button>
          
          <Button type="primary"
                  loading={isEnProceso}
                  style={{marginRight: "10px"}} onClick={() => {
            onClickSave()
          }}>
            <DownloadOutlined/>
            Actualizar Estructura
          </Button>
          
        </div>
      
      </div>
    </div>
  );
  
}