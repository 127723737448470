/***
 *
 * @param apiReqJson
 * @param urlBase
 * @returns {{Usuario: {Add: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Delete: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Ver: (function(*): *|*|{msg: *, success: boolean}|undefined), SendTempPass: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Index: (function(): *|*|{msg: *, success: boolean}|undefined), Update: (function(*, *=): *|null|*|{msg: *, success: boolean}|undefined), Disable: (function(*, *=): *|null|*|{msg: *, success: boolean}|undefined)}, Act: {Ver: (function(*): *|*|{msg: *, success: boolean}|undefined), Index: (function(): *|*|{msg: *, success: boolean}|undefined)}, VConfig: {Add: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Delete: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Ver: (function(*): *|*|{msg: *, success: boolean}|undefined), Index: (function(): *|*|{msg: *, success: boolean}|undefined), Update: (function(*, *=): *|null|*|{msg: *, success: boolean}|undefined)}, Venta: {Cancelar: (function(*, *): *|null|*|{msg: *, success: boolean}|undefined)}, CostoEnvio: {Index: (function(): *|*|{msg: *, success: boolean}|undefined), Update: (function(*, *, *): *|null|*|{msg: *, success: boolean}|undefined)}, PagForm: {Add: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Delete: (function(*): *|null|*|{msg: *, success: boolean}|undefined), Ver: (function(*): *|*|{msg: *, success: boolean}|undefined), Index: (function(): *|*|{msg: *, success: boolean}|undefined), Update: (function(*, *=): *|null|*|{msg: *, success: boolean}|undefined)}}}
 */
const factoryApiAdmin = (apiReqJson, urlBase) => {
  
  
  return {
    
    Usuario: {
      Index: async () => {
        const url = `${urlBase}/apiadm/usuario/index`;
        return await apiReqJson.requestGET(url);
      },
      Ver: async (id) => {
        const url = `${urlBase}/apiadm/usuario/${id}`;
        return await apiReqJson.requestGET(url);
      },
      Add: async (data) => {
        const url = `${urlBase}/apiadm/usuario/crud`;
        const dataObject = {data, ope: "create"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Update: async (id_usuario, dicUpdate) => {
        const url = `${urlBase}/apiadm/usuario/crud`;
        dicUpdate.id_usuario = id_usuario;
        const dataObject = {data: dicUpdate, ope: "update"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      UpdatePermiso: async (idUsuario, listaIdPermisoTipo) => {
        const url = `${urlBase}/apiadm/usuario-updatepermiso/${idUsuario}`;
        const data = {
          listaIdPermisoTipo
        }
        return await apiReqJson.requestPOST(url, data);
      },
      Delete: async (Username) => {
        const url = `${urlBase}/apiadm/usuario/crud`;
        const dataObject = {ope: "delete", data: {Username}};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      SendTempPass: async (Username) => {
        const url = `${urlBase}/apiadm/usuario/cmd`;
        const dataObject = {ope: "SEND_TEMP_PASS", data: {Username}};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Disable: async (Username, isDisabled = true) => {
        const url = `${urlBase}/apiadm/usuario/cmd`;
        const dataObject = {ope: "DISABLE", data: {Username, isDisabled}};
        return await apiReqJson.requestPOST(url, dataObject);
      },
    },
    Venta: {
      Cancelar: async (idVenta, comentario) => {
        const url = `${urlBase}/apiadm/venta/cancelacion/${idVenta}`;
        const dataObject = {comentario}
        return await apiReqJson.requestPOST(url, dataObject);
      }
    },
    CostoEnvio: {
      Index: async () => {
        const url = `${urlBase}/apiadm/costo-envio-index`;
        return await apiReqJson.requestGET(url);
      },
      Update: async (edo, costo_envio, isNoActivo) => {
        const url = `${urlBase}/apiadm/costo-envio-update/${edo}`;
        const dataPost = {
          data: {costo_envio, isNoActivo}
        };
        return await apiReqJson.requestPOST(url, dataPost);
      }
    },
    Act: {
      Index: async () => {
        const url = `${urlBase}/apiadm/act/index`;
        return await apiReqJson.requestGET(url);
      },
      Ver: async (id) => {
        const url = `${urlBase}/apiadm/act/${id}`;
        return await apiReqJson.requestGET(url);
      },
    },
    
    PagForm: {
      Index: async () => {
        const url = `${urlBase}/apiadm/pagform/index`;
        return await apiReqJson.requestGET(url);
      },
      Ver: async (id) => {
        const url = `${urlBase}/apiadm/pagform/${id}`;
        return await apiReqJson.requestGET(url);
      },
      
      Add: async (data) => {
        const url = `${urlBase}/apiadm/pagform/crud`;
        const dataObject = {data, ope: "create"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Update: async (id_pag_form, dicUpdate) => {
        const url = `${urlBase}/apiadm/pagform/crud`;
        dicUpdate.id_pag_form = id_pag_form;
        const dataObject = {data: dicUpdate, ope: "update"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Delete: async (id_pag_form) => {
        const url = `${urlBase}/apiadm/pagform/crud`;
        const dataObject = {ope: "delete", data: {id_pag_form}};
        return await apiReqJson.requestPOST(url, dataObject);
      }
    },
    VConfig: {
      Index: async () => {
        const url = `${urlBase}/apiadm/vconfig/index`;
        return await apiReqJson.requestGET(url);
      },
      Ver: async (varname) => {
        const url = `${urlBase}/apiadm/vconfig/${varname}`;
        return await apiReqJson.requestGET(url);
      },
      
      Add: async (data) => {
        const url = `${urlBase}/apiadm/vconfig/crud`;
        const dataObject = {data, ope: "create"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Update: async (varname, dicUpdate) => {
        const url = `${urlBase}/apiadm/vconfig/crud`;
        dicUpdate.var_name = varname;
        const dataObject = {data: dicUpdate, ope: "update"};
        return await apiReqJson.requestPOST(url, dataObject);
      },
      Delete: async (varname) => {
        const url = `${urlBase}/apiadm/vconfig/crud`;
        const dataObject = {ope: "delete", data: {varname}};
        return await apiReqJson.requestPOST(url, dataObject);
      }
    },
  }
};

export default factoryApiAdmin;