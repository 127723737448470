import React, {useState} from 'react';
import { Button} from "antd";
import {EditTwoTone, WarningOutlined} from "@ant-design/icons";

const CCostoEnvio = ({index, item,  onShowEdit}) => {
  
  const { estado, zona, costo_envio, isNoActivo} = item;
 
 
  return (
    <tr>
      <td>{index}</td>
      <td>{estado}  {isNoActivo?<span><WarningOutlined  style={{color:"red"}}/> No Activo</span> : null}</td>
      
      <td>
        {zona}
      </td>
      <td className="hasTextRight">
        $ {costo_envio}
      </td>
      <td>
        <Button onClick={()=>onShowEdit(item)}  className="mL5">
          <EditTwoTone />
        </Button>
      </td>
    </tr>
  );
};

export default CCostoEnvio