import React from 'react';
import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import FormUsuario from "./FormUsuario";
import DataStore from "../../../app/DataStore";
import UrlPage from "../../UrlPage";
import {useHistory} from "react-router-dom";


export default () => {
  const history = useHistory();
  
  
  const onUsuarioSaved = () => {
    DataStore.UsuarioIndex.SetIdSelected(null);
    history.push(UrlPage.ADMIN_USUARIO_INDEX);
  };
  
  
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{display: "flex", width: "100%"}}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          Crear Usuario
        </h2>
      </Col>
    </Row>
    
    <Row>
      <Col xs={24} sm={24} md={12}>
        <FormUsuario
          usuario={{}}
          onUsuarioSaved={onUsuarioSaved}/>
      </Col>
      
   
    </Row>
  </>);
  
}