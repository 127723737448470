import React, {useEffect, useState} from 'react';
import CLoading from "../../../../components/page/CLoading";
import './contenidoHtml.css'
import HeaderElementoContenido from "./HeaderElementoContenido";

import CSinContenido from "./CSinContenido";

import FormIFrame from "./FormIFrame";
import DesktopOutlined from "@ant-design/icons/lib/icons/DesktopOutlined";



export default ({itemContenido, indexItem, onItemUpdated, onShowDelElementModal}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [body, setBody] = useState(null);
  const [isEnProceso, setIsEnProceso] = useState(false);
  
  
  useEffect(() => {
    
    setBody(itemContenido.body || '');
    setIsModoEdit(false);
    setIsLoaded(true);
    
  }, [itemContenido]);
  
  
  if (!isLoaded) {
    return <CLoading mensaje="Loading Contenido"/>
  }
  
  
  /* MODO EDIT *********************************************** */
  if (isModoEdit) {
    
    const onClickSave = () => {
      
      if (isEnProceso) {
        return
      }
      
      setIsEnProceso(true);
      
      const cbOnFinishUpdate = (isSuccess) => {
        setIsEnProceso(false);
        if (isSuccess === true) {
          setIsModoEdit(false)
        }
      };
      
      onItemUpdated(body, indexItem, cbOnFinishUpdate);
    }
    
    
    const onUrlUploaded = (url) => {
      setBody(url);
      setIsEnProceso(false);
      setIsModoEdit(true);
    };
    
    const msgEnProceso = isEnProceso
      ? <CLoading mensaje="Guardando Elemento"/>
      : null;
    
    
    return (
      <div className="wrapperEditorHtml">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          onClickSave={onClickSave}
          subtitulo = "Archivo de Descarga"
          onClickDelete={() => onShowDelElementModal(indexItem)}
          setIsModoEdit={setIsModoEdit}
          isEdit={true}
          id={itemContenido.id_pag_contenido||''}
          codigo={itemContenido.codigo||''}
        />
        
        <div className="wrapperEditImg">
          <p>Inidicar URL del IFrame</p>
          
          <FormIFrame
            pag_contenido={itemContenido}
            onSetModoRead ={()=>setIsModoEdit(false)}
            onSaved={ onUrlUploaded}
          />
          
        </div>
        
        {msgEnProceso}
      </div>
    );
    
  }
  
  
  /* MODO render  ********************************************* */
  
  
  if (body) {
    
    
    return (
      <div className="wrapperItemContenido">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          setIsModoEdit={setIsModoEdit}
          subtitulo = "IFrame para contenido Externo"
          id={itemContenido.id_pag_contenido||''}
          isEdit={false}
        />
        
        <div className="wrapperRenderImg mtopMax">
          <DesktopOutlined style={{fontSize:"5em"}} />
          <div className="hasTextCenter">
            <a href={body} target="_blank" rel="noopener noreferrer">{body}</a>
          </div>
          
        </div>
      </div>
    );
  }
  
  return (
    <CSinContenido id_pag_contenido={itemContenido.id_pag_contenido}
                   setIsModoEdit={setIsModoEdit}
                   indexItem={indexItem}
                   subtitulo="Sin Url del IFrame"
    />
  );
  
  
}