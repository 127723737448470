export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    },
    {
      campo: "tipo",
      tipo: "cbx",
      label: "Buscar Tipo de Item",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {key: "TELEFONO", label: "Teléfono"},
        {key: "ACCESORIO", label: "Accesorio"},
        {key: "PROMOCIONAL", label: "Promocional"},
      ]
    },
    {
      campo: "entrada",
      tipo: "cbx",
      label: "Buscar Entrada",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {key: "BERYHUERT", label: "Beryhuert"},
        {key: "TELCEL", label: "Telcel"}
      ]
    }
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -") {
      return true;
    }
    const valorNom = filtroValor.trim().toLowerCase();
    let isIncluir = true;
    
    
    //aplicar filtro -----------------------------------
    if (isIncluir && valorNom !== "" && filtroValor !== "") {
  
      if (filtroNombre === "Buscar Tipo de Item" && item.tipo !== filtroValor) {
        isIncluir = false;
      }
  
      if (filtroNombre === "Buscar Entrada" && item.entrada !== filtroValor) {
        isIncluir = false;
      }
      
    }
    
    
    
    //------------------------------------------------------
    if (isIncluir && filtroNombre === "Buscar Texto") {
      if (valorNom !== "") {
        isIncluir = `${item.marca}|${item.modelo}|${item.descripcion}`.toLowerCase().includes(valorNom);
      }
    }
    
    return isIncluir
  }
}
;

