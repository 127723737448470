import React, {useEffect, useState} from 'react';
import CS3Uploader from "./CS3Uploader";
import DataService from "../../../../service/data/DataService";
import { Input} from "antd";



export default ({itemS3, onItemS3Updated, itemLabel}) => {
  
  
  const onUrlSlideUpdated = (urlNew, index) => {
    onItemS3Updated({...itemS3, image: urlNew});
  };
  
  
  const onChangeItem = (campo, valorNew) => {
    
    const model = {...itemS3};
    model[campo] = valorNew;
    console.log(model);
    onItemS3Updated(model);
  }
  
  
  return (
    <div className="wrapperEditImg">
      <h4>{itemLabel} {itemS3.id}</h4>
      
      <CS3Uploader
        url={itemS3.image}
        accept="image/*"
        onUrlUploaded={onUrlSlideUpdated}
        fnRenderUrl={(url) => <img src={url} alt="" title={url}/>}
        getUploadS3Url={DataService.Editor.PagContenido.getUploadS3Url}
        subtitulo="Imagen (jpg/png)"
      />
      
      <h4>Titulo</h4>
      <Input defaultValue={itemS3.title} onChange={(event) => onChangeItem('title', event.target.value)}/>
      
      <h4>SubTítulo</h4>
      <Input defaultValue={itemS3.subtitle} onChange={(event) => onChangeItem('subtitle', event.target.value)}/>
      
      <h4>Link</h4>
      <Input defaultValue={itemS3.url} onChange={(event) => onChangeItem('url', event.target.value)}/>
    
</div>
);
};

