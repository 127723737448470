import React from "react";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({recordatorio, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "update", icon: <EditFilled/>, label: "Editar", style: {color: "green"}},
    {isDivider: true},
    {key: "delete", icon: <DeleteOutlined/>, label: "Eliminar", style: {color: "red"}}
  ];
  
  const tituloModal = (
    <>
      <span>Recordatorio</span>
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={recordatorio}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}