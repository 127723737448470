import React, {useState} from "react";
import {Menu} from "antd";
import {HomeOutlined, LogoutOutlined, ToolOutlined} from "@ant-design/icons";
import UrlPages from "../../page/UrlPage";
import buildmeta from '../../buildmeta';
import AppConfig from "../../AppConfig";

const {SubMenu} = Menu;


const PageSideBar = (props) => {
  const {isAdmin, onMenuClicked} = props;
  
  const [keyMenuSel, setKeyMenuSel] = useState("/");
  
  let subMenuPruebaPago = (
    <SubMenu key="2" icon={<ToolOutlined/>} title="Prueba Pago">
      <Menu.Item key={UrlPages.PRUEBA_PG_BANCOMER_INDEX}>
        Bancomer
      </Menu.Item>
      <Menu.Item key={UrlPages.PRUEBA_PG_BP_INDEX}>
        Billpocket
      </Menu.Item>
      <Menu.Item key={UrlPages.PRUEBA_PG_BANAMEX_INDEX}>
        Banamex
      </Menu.Item>
    </SubMenu>
  );
  
  let subMenuAdmin = null;
  
  
  if (isAdmin || true) {
    subMenuAdmin = (
      <SubMenu key="sub2" icon={<ToolOutlined/>} title="Administrador">
        <Menu.Item key={UrlPages.ADMIN_USUARIO_INDEX}
                   title="Administrar Usuarios">
          Usuarios
        </Menu.Item>
        
        <Menu.Item key={UrlPages.ADMIN_VAR_CONFIG_INDEX}
                   title="Variables de Configuración">
          Variables
        </Menu.Item>
  
        <Menu.Item key={UrlPages.ADMIN_COSTO_ENVIO}
                   title="Cotos de Envío y Estados Disponibles">
          Costos de Envio
        </Menu.Item>
        
        <Menu.Item key={UrlPages.ADMIN_ACTUALIZACION_INDEX}
                   title="Historial de Actualizaciones">
          Actualizaciones
        </Menu.Item>
      </SubMenu>
    );
    
  }
  
  
  const handleClick = (event) => {
    setKeyMenuSel(event.key);
    onMenuClicked(event.key);
  };
  
  
  return (
    
    
    <div id="mnuSideMain">
      
      <Menu
        mode="inline"
        onClick={handleClick}
        selectedKeys={[keyMenuSel]}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{height: '100%', borderRight: 0}}
      >
        <SubMenu key="sub1" icon={<HomeOutlined/>} title="Inicio">
          <Menu.Item key={UrlPages.HOME}>
            Inicio
          </Menu.Item>
          <Menu.Item key={UrlPages.ITEM_INDEX}
                     title="Ver items y actualizar imagenes">
            Items
          </Menu.Item>
          <Menu.Item key={UrlPages.ITEMPROM_INDEX}
                     title="Ver items promocionados en recomendación">
            Items Recomendados
          </Menu.Item>
          <Menu.Item key={UrlPages.VENTAS_INDEX}>
            Ventas
          </Menu.Item>
          <Menu.Item key={UrlPages.PROMOCION_INDEX}>
            Promociones
          </Menu.Item>
          <Menu.Item key={UrlPages.MC_INDEX}>
            Mensajes de Clientes
          </Menu.Item>
          <Menu.Item key={UrlPages.RECORDATORIO_EVENTO_INDEX}>
            Recordatorios
          </Menu.Item>
          <Menu.Item key={UrlPages.PAG_INDEX}>
            Paginas
          </Menu.Item>
        
        </SubMenu>
        {subMenuPruebaPago}
        {subMenuAdmin}
        
        <Menu.Item key={UrlPages.SALIR}>
          <LogoutOutlined/>  Salir
        </Menu.Item>
        
        <div style={{paddingTop: "10px", textAlign: "center", "color": "#c3c3c3"}}
             title={buildmeta.fecha}
        >
          Version: {buildmeta.version} Build: {buildmeta.num} <br/>
        </div>
        <div style={{marginTop:"30px", textAlign:"center"}}>
          { AppConfig.isApiLocal?<span style={{ backgroundColor:"red" ,color:"yellow", padding:"5px"}}>Modo Local</span>:'Modo Produccion'}
        </div>
      
      </Menu>
    
    </div>
  );
};

export default PageSideBar;