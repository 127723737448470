import React, {useEffect, useState} from 'react';
import {Button,  Col, Form, Input, Row} from "antd";
import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FlashService from "../../../app/FlashService";



const FormItem = Form.Item;
const {TextArea} = Input;


export default ({pag, onPagSaved, onSetModoRead}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      titulo: pag.titulo,
      keywords: pag.keywords,
      description: pag.description,
      metas_head: pag.metas_head,
      metas_end: pag.metas_end
    });
    
  }, [pag, form]);
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    
    setIsEnProceso(true);
    
    
    let respuesta = await DataService.Editor.Pag.Update(pag.id_pag, formData);
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    FlashService.Registrar.Alert(pag.titulo, "Actuializada");
    
    onPagSaved(pag);
  };
  
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelCorto}
      onFinish={onSubmitForm}
    >
      <Row>
        <Col xs={24} sm={24} md={24}>
          
          
          <FormItem
            label="Título"
            name="titulo"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
          
          <FormItem
            label="Keywords"
            name="keywords"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
          
          
          <FormItem
            label="Descripción"
            name="description"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
  
          <FormItem
            label="Metas Head"
            name="metas_head"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
  
          <FormItem
            label="Metas End"
            name="metas_end"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
          
      
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div style={{marginTop: "20px", textAlign: "center"}}>
            
            <Button
              style={{width: "180px", marginRight: "20px"}}
              onClick={() => onSetModoRead()}>
              <span>Cancelar</span>
            </Button>
            
            <Button type="primary"
                    htmlType="submit"
                    style={{width: "180px"}}
                    loading={isEsProceso} block>
              <CloudUploadOutlined/>
              <span>Guardar</span>
            </Button>
            
            <div className="msgError" style={{minHeight: "150px"}}>
              {msgError}
            </div>
          
          </div>
        </Col>
      </Row>
    
    </Form>
  
  );
}