import React, {useState} from "react";
import {Button, Modal} from "antd";
import EllipsisOutlined from "@ant-design/icons/lib/icons/EllipsisOutlined";


export default ({listaOpciones, model, onCmdEnd, btnTipo, titulo}) => {
  
  const [isShow, setisShow] = useState(false);
  
  const factoryModal = (_isShow, hideModal) => {
    
    if (!_isShow) {
      return null;
    }
    
    const wrapperOnCmdEnd = (key) => {
      onCmdEnd(key, model);
      hideModal();
    };
    
    
    const elemCmd = listaOpciones.map((k, indexk) => {
      if (k.isDivider) {
        return <hr className="hrmenumob" key={indexk}/>
      }
      
      return (
        <div key={indexk}>
          <Button
            block
            onClick={() => wrapperOnCmdEnd(k.key)}
            className="itemCmdMenuMob"
            style={k.style}
          >
            {k.icon}
            {k.label}
          </Button>
        </div>
      );
    });
    
    return (
      <Modal
        title={titulo}
        visible={true}
        onCancel={() => hideModal()}
        footer={[]}
      >
        {elemCmd}
      
      </Modal>
    );
    
  };
  
  
  const onClick = () => {
    setisShow(true);
  };
  
  const modal = factoryModal(isShow, () => setisShow(false));
  
  
  return (
    <>
      <Button type={btnTipo}
              onClick={onClick}
      >
        <EllipsisOutlined/>
      </Button>
      {modal}
    </>
  );
  
  
}