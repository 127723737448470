import React from "react";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import ACmdRow from "../../../../components/tabla/ACmdRow";


export default ({item, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "update", icon: <EditFilled/>, label: "Editar", style: {color: "green"}},
  ];
  
  const tituloModal = (
    <>
      <span>Actualización </span>
      {/*<strong style={{color: "green"}}>{item.freg}</strong>*/}
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={item}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}