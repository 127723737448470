import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, Row} from "antd";
import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FlashService from "../../../app/FlashService";
import moment from 'moment';

const FormItem = Form.Item;
const {TextArea} = Input;


export default ({promocion, onPromSaved, onSetModoRead}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      titulo: promocion.titulo,
      keywords: promocion.keywords,
      description: promocion.description,
      metas_head: promocion.metas_head,
      metas_end: promocion.metas_end,
      fecha_ini: moment(promocion.fecha_ini),
      fecha_final: moment(promocion.fecha_final),
      is_activo: promocion.is_activo,
    });
    
  }, [promocion, form]);
  
  const onSubmitForm = async (formData) => {
    const isPromocionNew = promocion.id_promocion === undefined;
    
    
    let promocionSaved = {...promocion};
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    let respuesta = null;
    
    if (isPromocionNew) {
      respuesta = await DataService.Editor.Prom.Create(formData);
    } else {
      respuesta = await DataService.Editor.Prom.Update(promocion.id_promocion, formData);
    }
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    if (isPromocionNew) {
      promocionSaved = respuesta.data.promocion;
    }
    
    FlashService.Registrar.Alert(promocion.titulo, "Actualizada");
    
    onPromSaved(promocionSaved);
  };
  
  const cmdCancel = onSetModoRead
    ? (
      <Button
        style={{width: "180px", marginRight: "20px"}}
        onClick={() => onSetModoRead()}>
        <span>Cancelar</span>
      </Button>
    )
    : null;
  
  const controlIsActivo = promocion.id_promocion
    ? (<FormItem
      label="Promoción Activa"
      name="is_activo"
      valuePropName="checked"
      rules={[{required: false}]} hasFeedback>
      <Checkbox/>
    </FormItem>)
    : null;
  
  const controlMetasEnd = promocion.id_promocion
    ? (<FormItem
      label="Metas End"
      name="metas_end"
      rules={[{required: false}]} hasFeedback>
      <TextArea/>
    </FormItem>)
    : null
  ;
  
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelCorto}
      onFinish={onSubmitForm}
    >
      <Row>
        
        <Col xs={24} sm={24} md={24}>
          
          <FormItem
            label="Título"
            name="titulo"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
          
          <FormItem
            label="Fecha Inicial"
            name="fecha_ini"
            rules={[{required: true}]} hasFeedback>
            <DatePicker format='DD/MM/YYYY'/>
          </FormItem>
          
          
          <FormItem
            label="Fecha Final"
            name="fecha_final"
            rules={[{required: true}]} hasFeedback>
            <DatePicker format='DD/MM/YYYY'/>
          </FormItem>
          
          {controlIsActivo}
          
          
          {/*<FormItem*/}
          {/*  label="Keywords"*/}
          {/*  name="keywords"*/}
          {/*  rules={[{required: false}]} hasFeedback>*/}
          {/*  <TextArea/>*/}
          {/*</FormItem>*/}
          
          
          {/*<FormItem*/}
          {/*  label="Descripción"*/}
          {/*  name="description"*/}
          {/*  rules={[{required: false}]} hasFeedback>*/}
          {/*  <TextArea/>*/}
          {/*</FormItem>*/}
          
          {/*<FormItem*/}
          {/*  label="Metas Head"*/}
          {/*  name="metas_head"*/}
          {/*  rules={[{required: false}]} hasFeedback>*/}
          {/*  <TextArea/>*/}
          {/*</FormItem>*/}
          
          {controlMetasEnd}
        
        
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div style={{marginTop: "20px", textAlign: "center"}}>
            
            {cmdCancel}
            
            <Button type="primary"
                    htmlType="submit"
                    style={{width: "180px"}}
                    loading={isEsProceso} block>
              <CloudUploadOutlined/>
              <span>Guardar</span>
            </Button>
            
            <div className="msgError" style={{minHeight: "150px"}}>
              {msgError}
            </div>
          
          </div>
        </Col>
      </Row>
    
    </Form>
  
  );
}