import React, {useEffect, useState} from 'react';

import {Button, Input, Form, Card, Checkbox} from 'antd';
import "../../../components/form/form.css";

import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";

const FormItem = Form.Item;


const FormCostoEnvio = ({ itemCosto,onClickModalSave}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  
  useEffect(() => {
    form.setFieldsValue({
      costo_envio: itemCosto.costo_envio,
      isNoActivo: itemCosto.isNoActivo
    });
    
  }, [itemCosto, form]);
  
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    
    setIsEnProceso(true);
    setMsgError("");
  
    const respuesta = await DataService.Admin.CostoEnvio.Update(itemCosto.edo, formData.costo_envio, formData.isNoActivo);
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    onClickModalSave(true,formData);
    
  };
  

  
  return (
    <div>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelGrande}
        onFinish={onSubmitForm}
      >
        <Card bordered={true}>
          
          <FormItem
            label="Costo de Envio"
            name="costo_envio"
            rules={[{required: true}]} hasFeedback>
            <Input type="number"/>
          </FormItem>
          
          <FormItem
            label="No Activo"
            name="isNoActivo"
            valuePropName="checked"
            rules={[{required: false}]} hasFeedback>
            <Checkbox />
          </FormItem>
        
        </Card>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar Campos</span>
          </Button>
          
          <Button
            onClick={() => onClickModalSave(false)}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      </Form>
    </div>
  );
};

export default FormCostoEnvio