const numeroTo2Char = numero => {
  /*Convertir un numero a un texto de 2 characteres,utilizado para campos fecha*/
  return (numero < 10 ? "0" : "") + numero.toString();
};

const LibFecha = {
  getDMYFromYMD(fYMD) {
    let lista = fYMD.toString().split("-");

    let y = parseInt(lista[0]);
    let mes = parseInt(lista[1]);
    let dia = parseInt(lista[2]);

    dia = numeroTo2Char(dia);
    mes = numeroTo2Char(mes);

    return `${dia}/${mes}/${y}`;
  }
};

module.exports = LibFecha;
