import React, {useEffect, useState} from 'react';
import CLoading from "../../../../components/page/CLoading";
import './contenidoHtml.css'
import HeaderElementoContenido from "./HeaderElementoContenido";
import ImgRender from "./ImgRender";
import CSinContenido from "./CSinContenido";
import CSlider3item from "./CSlider3item";


export default ({
                  itemContenido, indexItem, onItemUpdated, onShowDelElementModal,
                  itemLabel = 'Slide',
                  subtituloHeader = 'Slider de 3 Imágenes'
                }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [body, setBody] = useState(null);
  const [isEnProceso, setIsEnProceso] = useState(false);
  const [listaItem, setListaItem] = useState(null);
  
  
  useEffect(() => {
    
    const contenidoDefault = [
      {"id": 1, "title": "", "subtitle": "", "image": "", "url": ""},
      {"id": 2, "title": "", "subtitle": "", "image": "", "url": ""},
      {"id": 3, "title": "", "subtitle": "", "image": "", "url": ""},
    ]
    
    const b = itemContenido.body || JSON.stringify(contenidoDefault);
    
    setBody(b);
    setListaItem(JSON.parse(b));
    setIsModoEdit(false);
    setIsLoaded(true);
    
  }, [itemContenido]);
  
  
  if (!isLoaded) {
    return <CLoading mensaje="Loading Contenido"/>
  }
  
  
  /* MODO EDIT *********************************************** */
  if (isModoEdit) {
    
    const onClickSave = () => {
      
      if (isEnProceso) {
        return
      }
      
      setIsEnProceso(true);
      
      const cbOnFinishUpdate = (isSuccess) => {
        setIsEnProceso(false);
        if (isSuccess === true) {
          setIsModoEdit(false)
        }
      };
      
      onItemUpdated(JSON.stringify(listaItem), indexItem, cbOnFinishUpdate);
    }
    
    
    const msgEnProceso = isEnProceso
      ? <CLoading mensaje="Guardando Elemento"/>
      : null;
    
    
    const onItemS3Updated = (itemS3) => {
      
      const listaNew = listaItem.map(k => {
        if (k.id === itemS3.id) {
          k = itemS3;
        }
        return k;
      });
      
      setListaItem(listaNew);
      
    };
    
    
    const listaEdit = listaItem.map((itemS3, indexItem) => {
      return <CSlider3item key={indexItem} itemS3={itemS3}
                           itemLAbel={itemLabel}
                           onItemS3Updated={onItemS3Updated}/>
    });
    
    return (
      <div className="wrapperEditorHtml">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          onClickSave={onClickSave}
          onClickDelete={() => onShowDelElementModal(indexItem)}
          setIsModoEdit={setIsModoEdit}
          subtitulo={subtituloHeader}
          isEdit={true}
          id={itemContenido.id_pag_contenido || ''}
          codigo={itemContenido.codigo || ''}
        />
        
        <div className="divFlex">
          {listaEdit}
        </div>
        
        {msgEnProceso}
      
      </div>
    );
    
  }
  
  
  /* MODO render  ********************************************* */
  
  
  if (listaItem) {
    
    /*
    * https://static.tiendatelcel.com.mx/pag/2020/T7RLY_banner_preventa_galaxy_note20.jpg"
1: "https://static.tiendatelcel.com.mx/pag/2020/DZ1V3_s2.jpg"
2: "https://static.tiendatelcel.com.mx/pag/2020/44HA0_s1.jpg"
    * */
    
    
    const listaImg = listaItem.map((item, indexItem) => {
      return (
        <div className="renderslide3" key={indexItem}>
          <div className="wrapperRenderImg">
            <h4>{itemLabel} {item.id}</h4>
            <ImgRender url={item.image}/>
            <h4>{item.title}</h4>
            <p>{item.subtitle}</p>
            <p>url: <a href={item.url} target="_blank">{item.url || 'Ninguna'}</a></p>
          </div>
        </div>
      
      );
    });
    
    return (
      <div className="wrapperItemContenido">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          setIsModoEdit={setIsModoEdit}
          subtitulo={subtituloHeader}
          isEdit={false}
          id={itemContenido.id_pag_contenido || ''}
          codigo={itemContenido.codigo || ''}
          comentario={itemContenido.comentario}
        />
        
        <div className="divFlex">
          {listaImg}
        </div>
      </div>
    );
  }
  
  return (
    <CSinContenido id_pag_contenido={itemContenido.id_pag_contenido}
                   setIsModoEdit={setIsModoEdit}
                   indexItem={indexItem}
                   subtitulo="Sin Imagen"
    />
  );
  
}