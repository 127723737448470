import React from "react";
import {Modal} from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({ recordatorio, visible, onClickModalDelete}) => {
  
  if(!recordatorio){
    return null;
  }
  
  
  const titulo = (
    <>
      <DeleteOutlined style={{color: "red"}}/>
      <span style={{color: "red"}}>Eliminar Recordatorio</span>
    </>
  );
  
  const nombre = recordatorio.nombre_evento ;
  
  return (
    <Modal
      title={titulo}
      visible={visible}
      onOk={() => onClickModalDelete(true)}
      onCancel={() => onClickModalDelete(false)}
    >
      <h3 style={{color: "green"}}> ID {recordatorio.id_recordatorio} : {nombre}</h3>
      
    </Modal>
  );
}