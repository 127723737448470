import React from 'react';
import CSlider3 from "./CSlider3";

export default ({itemContenido, indexItem, onItemUpdated, onShowDelElementModal,subtituloHeader}) => {
  return (
    <CSlider3
      itemContenido={itemContenido}
      indexItem={indexItem}
      onItemUpdated={onItemUpdated}
      onShowDelElementModal={onShowDelElementModal}
      subtituloHeader={subtituloHeader}
      itemLabel="Bloque"
    />
  );
};

