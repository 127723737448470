const DicEstatusVenta = {
  Pendiente: 1,
  PagadoYEsperandoEntrega: 2,
  PagadoYEntregado: 3,
  Cancelado: 4,
  getLabelFromID: (id) => {
    if (id === 1) {
      return "Pendiente"
    } else if (id === 2) {
      return "Pagado y Esperando Entrega"
    } else if (id === 3) {
      return "Pagado y Entregado"
    } else if (id === 4) {
      return "Cancelado"
    } else {
      return id.toString();
    }
  }
}

export default DicEstatusVenta;