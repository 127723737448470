export default {
  getPropiedad: function () {
    
    let objeto = arguments[0];
    
    
    for (let i = 1; i < arguments.length; i++) {
      let prop = arguments[i];
      
      if (objeto[prop] !== undefined) {
        objeto = objeto[prop]
      } else {
        objeto = null;
        return objeto;
      }
    }
    
    return objeto;
  }
}