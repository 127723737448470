export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    },
    {
      campo: "isNoActivo",
      tipo: "cbx",
      label: "Buscar Por Activo/No Activo",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {key: 0, label: "Estado Activo"},
        {key: 1, label: "Estado No Activo"},
      ]
    }
  
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -") {
      return true;
    }
   
    let isIncluir = true;
    
    //aplicar filtro -----------------------------------
    // if (isIncluir && filtroNombre === "Buscar Tipo de Usuario" && filtroValor !== "") {
    //
    //   if (valorNom !== "" && item.tipo !== filtroValor) {
    //     isIncluir = false;
    //   }
    // }
    
    if (isIncluir && filtroNombre === "Buscar Texto") {
      const valorTexto = filtroValor.trim().toLowerCase();
      if (valorTexto !== "") {
        isIncluir = `${item.estado}|${item.edo}`.toLowerCase().includes(valorTexto);
      }
    }
    
    if (isIncluir && filtroNombre === "Buscar Por Activo/No Activo") {
      if (filtroValor !== "") {
        isIncluir = !!item.isNoActivo === !!filtroValor;
      }
    }
    
    return isIncluir
  }
}
;

