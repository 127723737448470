import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";

import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";

import EditFilled from "@ant-design/icons/lib/icons/EditFilled";

import {BackwardFilled} from "@ant-design/icons";
import DataStore from "../../../app/DataStore";
import FormRec from "./FormRec";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import EditorPropImg from "../../../components/form/EditorPropImg";

import CSlider3 from "../pag/cElement/CSlider3";


export default (props) => {
  
  const history = useHistory();
  
  const {idRecordatorio} = useParams();
  
  const [recordatorio, setRecordatorio] = useState(null);
  const [slider, setSlider] = useState(null);
  
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
      
      const respuesta = await DataService.Editor.Recordatorio.Ver(idRecordatorio);
      
      
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error Recordatorio ID " + idRecordatorio, respuesta.msg);
        return;
      }
      const r = respuesta.data.recordatorio;
      
      setRecordatorio(r);
      setSlider({body: (r.slider?r.slider:"")});
      
      setIsLoaded(true);
      
    })();
    
  }, [idRecordatorio]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onRecSaved = (rec) => {
    DataStore.RecordatorioIndex.SetIdSelected(rec.id_recordatorio);
    LibShowNotificacion.Success("Recordatorio Actualizado");
  };
  
  
  const tagTitulo = <span><EditFilled/> Recordatorio <span className="resaltar">{idRecordatorio}</span></span>;
  
  
  const fnSaveProp = async (prop, valorNew, cbError) => {
    
    const dataUpdate = {};
    dataUpdate[prop] = valorNew
    
    
    const respuesta = await DataService.Editor.Recordatorio.Update(idRecordatorio, dataUpdate);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      cbError();
      return;
    }
    
    LibShowNotificacion.Success("Actualización de Recordatorio", recordatorio.nombre_evento);
    const newModel = {...recordatorio};
    newModel[prop] = valorNew;
    setRecordatorio(newModel);
    
  }
  
  
  const fnSaveUrlImgMain = async (valorNew, cbError) => {
    return await fnSaveProp('urlImg', valorNew, cbError)
  };
  
  const fnSaveUrlImgMainMovil = async (valorNew, cbError) => {
    return await fnSaveProp('urlImgMovil', valorNew, cbError)
  };
  const onCancel = () => {
    DataStore.RecordatorioIndex.SetIsCancel()
    history.push(UrlPage.RECORDATORIO_EVENTO_INDEX);
  }
  
  const onSliderUpdated = async (newBodyJson, indexItemNoSeUsa, cbOnFinishUpdate) => {
    
    //actualziar el item
    const dataUpdate = {
      slider: newBodyJson
    };
    
    const respuesta = await DataService.Editor.Recordatorio.Update(idRecordatorio, dataUpdate);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      cbOnFinishUpdate(false);
      return;
    }
    
    setSlider({body : newBodyJson});
    
    cbOnFinishUpdate(true);
  };
  
  
  return (<>
    <Row>
      <Col xs={24}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          {tagTitulo}
          
          <Link to={UrlPage.RECORDATORIO_EVENTO_INDEX} className="linkBack">
            <BackwardFilled/>
            Regresar
          </Link>
        </h2>
      
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={12} style={{paddingRight: "20px"}}>
        
        <FormRec recordatorio={recordatorio}
                 onSetModoRead={onCancel}
                 onRecSaved={onRecSaved}
        />
      </Col>
      <Col xs={24} md={12}>
        <p>Opcionalmente se puede utilizar una imagen, se deben indicar dos imagenes una
          para escriotrio y
          otra para smartfone
        </p>
        <div style={{paddingBottom: "20px", marginTop: "40px"}}>
          <EditorPropImg
            subtitulo="Banner del Evento 1200x90 px (se mostrará a partir de la fecha de inicio)"
            fnSave={fnSaveUrlImgMain}
            urlImg={recordatorio.urlImg || ''}/>
        </div>
        
        <div style={{paddingBottom: "20px"}}>
          <EditorPropImg
            subtitulo="Banner del Evento 300x90 px  (se mostrará a partir de la fecha de inicio)"
            fnSave={fnSaveUrlImgMainMovil}
            urlImg={recordatorio.urlImgMovil || ''}/>
        </div>
        
        <div style={{paddingBottom: "20px"}}>
          <CSlider3
            itemContenido={slider}
            key={0}
            indexItem={0}
            subtituloHeader={`Actualización de Slider Home Page`}
            onItemUpdated={onSliderUpdated}/>
          
          <p>
            Al momento de llegar la fecha objetivo se cambiará el slider de inicio
            con el slider indicado
          </p>
        </div>
      
      </Col>
    </Row>
  
  
  </>);
  
}