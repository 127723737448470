import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import {Col, Row, Table} from "antd";
import "../../../components/tabla/table.css";


import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";


/*
{
"idUsuario","userCreateDate",
"userLastModifiedDate","enabled",
"estatus","phone_number","sucursales",
"email":"david@productividadti.com.mx","tipo":"adm"
}]
Col 2
Col 3333333

setRutaActual( {Path:UrlPage.ADMIN_USUARIO_EDIT, id:idUsuario});

* */


export default () => {
  
  // const history = useHistory();
  const {idAct} = useParams();
  
  const [actualizacion, setActualizacion] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
  
      const respuesta = await DataService.Admin.Act.Ver(idAct, true);
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Ver " + idAct, respuesta.msg);
        return;
      }
  
      const act = respuesta.data.act;
      act.listaItem = respuesta.data.listaItem.map(k => {
        k.key = k.id_act;
        return k;
      });
  
      setActualizacion(act);
      setIsLoaded(true);
  
    })();
  
  }, [idAct]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  const columns = [
    {key: 'id_item_historia', title: 'Id Item', dataIndex: 'id_item_historia'},
    {key: 'id_item', title: 'Id', dataIndex: 'id_item'},
    {key: 'tipo', title: 'Tipo', dataIndex: 'tipo'},
    
    {key: 'marca', title: 'Marca', dataIndex: 'marca'},
    {key: 'modelo', title: 'Modelo', dataIndex: 'modelo'},
    {key: 'entrada', title: 'Entrada', dataIndex: 'entrada'},
    {key: 'descripcion', title: 'Descripción', dataIndex: 'descripcion'},
    {key: 'precio', title: 'Precio', dataIndex: 'precio'},
    {key: 'updated_at', title: 'Updated', dataIndex: 'updated_at'},
  ];
  
  const tabla = <Table dataSource={actualizacion.listaItem} columns={columns}/>;
  
  const tagTitulo = "Actualización";
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{display: "flex", width: "100%"}}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          {tagTitulo} ID <span className="tagResaltar">{actualizacion.id_act}</span>
        </h2>
      </Col>
    </Row>
    
    
    <Row>
      <Col xs={24} sm={24} md={12}>
        <div className="wrapperTableDataForm">
          <table className="tableDataForm">
            <tbody>
            <tr>
              <th>Fecha</th>
              <td>{actualizacion.freg}</td>
            </tr>
            <tr>
              <th>Estatus</th>
              <td>{actualizacion.estatus}</td>
            </tr>
            <tr>
              <th>Usuario/IP</th>
              <td>
                {actualizacion.usuario}<br/>
                {actualizacion.ip}
              </td>
            </tr>

            </tbody>
          </table>
        </div>

      </Col>
      <Col xs={24} sm={24} md={12}>
        <div className="wrapperTableDataForm">
          <table className="tableDataForm">
            <tbody>
            <tr>
              <th>Token</th>
              <td>{actualizacion.token}</td>
            </tr>
            <tr>
              <th>Comentario</th>
              <td>{actualizacion.comentario}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </Col>
    </Row>
    
    <Row>
      <Col xs={24} sm={24} md={24}>
        <h3 style={{marginTop: "10px"}}>Items ({actualizacion.listaItem.length})</h3>
        {tabla}
      </Col>
    </Row>
  </>);
  
}