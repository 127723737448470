import React from 'react';


import ImgEntregas from './img_entregas.svg';
import ImgFoto from './img_fotos.svg';
import ImgSubirPRecios from './img_subir_lista_precios.svg';
import ImgVentas from './img_ventas.svg';
import ImgFactura from './img_ventas_factura.svg';
import ImgActHome from './img_actualizar_home.svg';
import ImgXLS from './img_file_xls.svg';
import ImgLike from './img_like.svg';

export default {
  Entregas: ImgEntregas,
  FotosItems: ImgFoto,
  Actualizar: ImgSubirPRecios,
  Ventas: ImgVentas,
  Factura: ImgFactura,
  ActualizarSitio: ImgActHome,
  XLS: ImgXLS,
  LIKE: ImgLike
  
}