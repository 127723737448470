import LibCopyXls from "../../../../lib/LibCopyXls";
import LibObject from "../../../../lib/LibObject";

export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id', 'form_name', 'nombre', 'email', 'tel', 'comentario', 'freg'];
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    const id = LibObject.getPropiedad(k, 'id_mc');
    const form_name = LibObject.getPropiedad(k, 'form_name');
    
    const nombre = LibObject.getPropiedad(k, 'data.nombre');
    const email = LibObject.getPropiedad(k, 'data.email');
    const tel = LibObject.getPropiedad(k, 'data.tel');
    const comentario = LibObject.getPropiedad(k, 'data.comentario');
    const freg = LibObject.getPropiedad(k, 'freg');
    
    
    return [
      (indexk + 1), id, form_name, nombre,
      email, tel, comentario, freg
    ];
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}