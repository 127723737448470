import React, {useEffect, useState} from 'react';
import {Button, Input, Form, Card} from 'antd';
import LibFormAnt from "../../lib/LibFormAnt";
import {CloudUploadOutlined} from "@ant-design/icons";
import LibShowNotificacion from "../../lib/LibShowNotificacion";
import DataService from "../../service/data/DataService";


const FormItem = Form.Item;


const FormSetPass = ({tokenRec, onSuccess}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.setFieldsValue({
      passConfirm: "",
      pass: ""
    });
    
    
  }, [form]);
  
  
  const onSubmitForm = async (formData) => {
    if (isEsProceso) {
      return;
    }
    
    //validar que coincidan los password
    if (formData.pass !== formData.passConfirm) {
      const msg = "El password y la confirmación no coinciden";
      LibShowNotificacion.Alert(msg);
      setMsgError(msg);
      return;
    }
    
    setIsEnProceso(true);
    setMsgError("");
    
    //es un create
    const respuesta = await DataService.recpass.exe(tokenRec, formData.pass);
    
    if (!respuesta.success) {
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
  
    onSuccess();
    
  };
  
  
  return (
    <div>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOut8_16}
        onFinish={onSubmitForm}
      >
        <Card bordered={true}>
          <FormItem
            label="Password"
            name="pass"
            rules={[{required: true}]} hasFeedback>
            <Input type="password"/>
          </FormItem>
          
          <FormItem
            label="Confirmación Password"
            name="passConfirm"
            rules={[{required: true}]} hasFeedback>
            <Input type="password"/>
          </FormItem>
        </Card>
  
        <div className="divFlexC" style={{justifyContent: "center"}}>
    
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            Guardar nuevo password
          </Button>
  
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      </Form>
    </div>
  );
};

export default FormSetPass