import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PageHeader from "../components/page/PageHeader";
import PageSideBar from "../components/page/PageSideBar";
import DataStore from "../app/DataStore";


export default (props) => {
  
  const history = useHistory();
  
  const isDesktop = DataStore.IsDesktop.Get();
  const [isVisibleSideBar, setIsVisibleSideBar] = useState(isDesktop);
  const [cssSideBar, setCssSideBar] = useState("");
  const cssContenidoMain = isDesktop ? "" : "isCMainMobile";
  
  
  //------------------------------------------------------------
  
  useEffect(() => {
    if (isVisibleSideBar) {
      setTimeout(() => {
        if (isDesktop) {
          setCssSideBar("");
        } else {
          setCssSideBar("isNotDesktopAndShowMenu");
        }
  
      }, 300);
  
    } else {
      setTimeout(() => {
        setCssSideBar("hideSB");
      }, 600);
    }
  }, [isVisibleSideBar, isDesktop]);
  
  const identidad = DataStore.GetIdentidad();
  const isAdmin = DataStore.GetIsAdmin();
  const roles = DataStore.getListaRol();
  
  
  const onMenuClicked = (keyMenu) => {
    if (!isDesktop) {
      setIsVisibleSideBar(false);
    }
    history.push(keyMenu);
  };
  
  
  return (
    <div id="app" className={cssSideBar}>
      
      <PageHeader setIsVisibleSideBar={setIsVisibleSideBar}
                  isVisibleSideBar={isVisibleSideBar}
                  identidad={identidad}
                  roles={roles}
                  isDesktop={isDesktop}
      />
      
      <PageSideBar isVisible={isVisibleSideBar}
                   isAdmin={isAdmin}
                   isDesktop={isDesktop}
                   onMenuClicked={onMenuClicked}
      />
      
      <div id="contenidoMain" className={cssContenidoMain}>
        <div style={{paddingLeft: "5px", paddingTop: "5px"}}>
          {props.children}
        </div>
      
      </div>
    
    </div>
  );
}