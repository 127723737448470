const LibFormAnt = {
  ConfigMsgValidacion: {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} es requerido',
    types: {
      email: 'No es un email valido',
      // eslint-disable-next-line no-template-curly-in-string
      number: '${label} no es un número válido',
    },
    number: {
      // eslint-disable-next-line no-template-curly-in-string
      range: '${label} debe estar entre ${min} y  ${max}',
    },
  },
  LayOutLabelCorto: {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
  },
  LayOutLabelGrande: {
    labelCol: {span: 9},
    wrapperCol: {span: 15},
  },
  LayOut8_16: {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  }
  
};

export default LibFormAnt;