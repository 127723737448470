import React from "react";
import {Modal} from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({titulo,  onClickModalDelete}) => {
  
  const title= (
    <>
      <DeleteOutlined style={{color: "red"}}/>
      <span style={{color: "red"}}>Eliminar Elemento</span>
    </>
  );
  
  
  
  return (
    <Modal
      title={title}
      visible={true}
      onOk={() => onClickModalDelete(true)}
      onCancel={() => onClickModalDelete(false)}
    >
      <h3>Confirmar Eliminar {titulo}</h3>
    
    </Modal>
  );
}