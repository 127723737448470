import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";

import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";

import {BackwardFilled} from "@ant-design/icons";
import FormVConfig from "./FormVConfig";
import VistaVConfig from "./VistaVConfig";
import UnorderedListOutlined from "@ant-design/icons/lib/icons/UnorderedListOutlined";


export default () => {
  
  const history = useHistory();
  const var_name = useParams().var_name || null;
  const [isModoEdit, setIsModoEdit] = useState(false)
  const [vconfig, setVConfig] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
      
      if (var_name) {
        const respuesta = await DataService.Admin.VConfig.Ver(var_name);
        
        if (!respuesta.success) {
          FlashService.Registrar.Alert("Error al Ver " + var_name, respuesta.msg);
          return;
        }
        
        setVConfig(respuesta.data.vconfig);
        
        
      } else {
        setVConfig({var_name: '', tipo: '', valor: '', comentario_html: '', seccion: ''})
      }
      setIsLoaded(true);
      
      
    })();
    
  }, [var_name]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onCamposSaved = (newVConfig) => {
    
    setVConfig({...newVConfig});
    
    const var_name = vconfig.var_name;
    
    const titulo = `Actualización de Variable ${var_name} `;
    FlashService.Registrar.Info(titulo, vconfig.titulo);
    const urlIndex = UrlPage.ADMIN_VAR_CONFIG_INDEX
    history.push(urlIndex);
  };
  
  
  const tituloPagina = (
    <div className="tituloPagUpdate">
      <UnorderedListOutlined/>
      <span>Variable de Configuración</span>
      <Link to={UrlPage.ADMIN_VAR_CONFIG_INDEX} className="linkBack">
        <BackwardFilled/>
        Regresar
      </Link>
    </div>
  );
  
  const formModel = isModoEdit
    ? (<FormVConfig varConfig={vconfig}
                    onFormularioSaved={onCamposSaved}
                    onSetModoVista={() => setIsModoEdit(false)}/>)
    : null;
  
  const vistaModel = isModoEdit
    ? null
    : <VistaVConfig vconfig={vconfig} onSetModoEdit={() => setIsModoEdit(true)}/>;
  
  
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{width: "100%"}}>
        {tituloPagina}
      </Col>
    </Row>
    
    {formModel}
    {vistaModel}
  
  
  </>);
  
}