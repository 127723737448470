import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Card, Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";
import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";

import FormItem from "./FormItem";
import FormListaImgItem from "./formlistaImg/FormListaImgItem";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import {BackwardFilled} from "@ant-design/icons";


export default () => {
  
  const history = useHistory();
  const {idItem} = useParams();
  
  const [item, setItem] = useState(null);
  const [listaUrlImg, setListaUrlImg] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
      
      const respuesta = await DataService.Editor.Item.Ver(idItem);
      
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Ver " + idItem, respuesta.msg);
        return;
      }
      
      setItem(respuesta.data.item);
      setListaUrlImg(respuesta.data.listaImg);
      setIsLoaded(true);
      
      
    })();
    
  }, [idItem]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onItemSaved = (itemActual) => {
    const titulo = `Actualización de item ${itemActual.id_item} `;
    FlashService.Registrar.Info(titulo, itemActual.modelo);
    const urlIndex = UrlPage.ITEM_INDEX
    history.push(urlIndex);
  };
  
  
  const onListaImgUpdated = (listaImgNew) => {
    const isImagenPrincipalIncluida = listaImgNew.includes(item.urlImg);
    
    if (!isImagenPrincipalIncluida) {
      setItem({...item, urlImg: null});
    }
    
    setListaUrlImg(listaImgNew);
    
  };
  
  const onChangeUrlPrincipal = (url) => {
    setItem({...item, urlImg: url});
  };
  
  const tagTitulo = <span><EditFilled/> Actualizar Item</span>;
 
 
  return (<>
    <Row>
      <Col xs={24} sm={24} md={24}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          {tagTitulo} <span className="tagResaltar">{item.id_item}</span>
          
          <Link to={UrlPage.ITEM_INDEX} className="linkBack">
            <BackwardFilled/>
            Regresar
          </Link>
        </h2>
      </Col>
    </Row>
    
    
    <Row>
      <Col xs={24} sm={24} md={12}>
        <div className="wrapperTableDataForm">
          <table className="tableDataForm fix">
            <tbody>
            <tr>
              <th>ID</th>
              <td>{item.id_item}</td>
            </tr>
            <tr>
              <th>Actualización</th>
              <td>{item.id_act}</td>
            </tr>
            <tr>
              <th>Tipo</th>
              <td>
                {item.tipo}
              </td>
            </tr>
            <tr>
              <th>Marca</th>
              <td>
                {item.marca}
              </td>
            </tr>
            <tr>
              <th>Modelo</th>
              <td>
                {item.modelo}
              </td>
            </tr>
            
            </tbody>
          </table>
        </div>
        
        <FormItem item={item} onItemSaved={onItemSaved}/>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <div className="pL5 pR5">
          <FormListaImgItem
            listaUrlImg={listaUrlImg}
            urlImgPrincipal={item.urlImg}
            onListaImgUpdated={onListaImgUpdated}
            onChangeUrlPrincipal={onChangeUrlPrincipal}
            idItem={item.id_item}/>
        </div>
      
      </Col>
    </Row>
  
  
  </>);
  
}