import LibCopyXls from "../../../../lib/LibCopyXls";
import LibObject from "../../../../lib/LibObject";

export default (listaIndexFiltrado) => {
  
  
  const listaHeader = [
    '#',
    "id_venta",
    
    "serie",
    "id_cart",
    
    "num_items",
    "gran_total",
    "costo_envio",
    "estatus",
    "id_comprador",
    "nombre_cliente",
    "email",
    "estadoEnvio",
    "fecha de Venta",
   
    "plataforma",
    "guia_envio",
    "comentario_adm",
    "fecha_entrega",
    "fum",
  ];
  
  const listaCampos = [
    "id_venta",
    "serie",
    "id_cart",
    
    "num_items",
    "gran_total",
    "costo_envio",
    "estatus",
    "id_comprador",
    "nombre",
    "email",
    "estadoEnvio",
    "fechaDMY",
    
    "plataforma",
    "guia_envio",
    "comentario_adm",
    "fecha_entrega",
    "fum",
  ];
  
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    
    let m=[(indexk + 1)]
    listaCampos.forEach(c=>{
       m.push(k[c]);
    })
    
    return m;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}