import React from "react";
import IeCircleFilled from "@ant-design/icons/lib/icons/IeCircleFilled";

export default ({urlAbs}) => {
  
  return (
    <a href={urlAbs} target="_blank"
       title={urlAbs}
       rel="noopener noreferrer" className="iconIE">
      <IeCircleFilled/>
    </a>
  );
}