import React, {useEffect, useState} from 'react';
import Dropzone from "react-dropzone";
import CLoading from "../../../../components/page/CLoading";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";

export default ({url, onUrlUploaded, getUploadS3Url, fnRenderUrl, accept, subtitulo}) => {
  
  const [isEnProceso, setIsEnProceso] = useState(false);
  
  useEffect(() => {
    setIsEnProceso(false);
  }, [])
  
  
  const onDrop = async (accepted, rejected) => {
    
    if (accepted.length === 0) {
      LibShowNotificacion.Alert("Error", "Tipo de Archivo Incorrecto");
    }
    
    const event = {
      target: {
        files: accepted
      }
    };
    
    await onChangeFile(event)
    
  };
  
  const onChangeFile = async (event) => {
    
    
    if (isEnProceso) {
      return;
    }
    
    
    
    
    const inputObject = event.target;
    
    if (inputObject.files.length === 0) {
      return
    }
  
    setIsEnProceso(true);
    
    let fileObject = inputObject.files[0];
    let fileTipo = fileObject.type;
    
    const respuesta = await getUploadS3Url(fileTipo, fileObject.name);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Subir Archivo", respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    const {urlPut, urlImg} = respuesta.data;
    
    
    const respuestaS3 = await DataService.putToS3(urlPut, fileObject);
    
    
    if (typeof respuestaS3 === "object") {
      LibShowNotificacion.Alert("Error AWS", JSON.stringify(respuestaS3.msg));
      setIsEnProceso(false);
      return;
    }
    
    
    //eliminar la seleccion de archivo
    inputObject.value = null;
    
    setIsEnProceso(false);
    
    //Actauliar la lista
    onUrlUploaded(urlImg);
  };
  
  const msgEnProceso = isEnProceso
    ? <CLoading mensaje="Subiendo Archivo"/>
    : null;
  
  
  return (
    <>
      {fnRenderUrl(url)}
      
      
      <Dropzone
        accept={accept}
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
          border: " 2px dashed"
        }}
        multiple={false}
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps({className: "dropzone"})}>
            <input {...getInputProps()}
                   onChange={event => onChangeFile(event)}
            />
            <p>Arrastra {subtitulo} que deseas usar</p>
          </div>
        )}
      
      </Dropzone>
      
      {msgEnProceso}
    </>
  );
}