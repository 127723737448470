import React from 'react';

const manMoney = require('./svg/man-money@140x140.svg');
const form = require('./svg/form@140x140.svg');
const docs = require('./svg/docs@140x140.svg');
const back = require('./svg/back@140x140.svg');

const check = require('./svg/check@140x140.svg');
const checkVacio = require('./svg/check_vacio@140x140.svg');

const getImg = (s, w = 24) => {
  return <img src={s} alt="" style={{width: `${w}px`}} className="iconsvg"/>
  
}
const FactoryImg = {
  ManMoney: () => getImg(manMoney),
  Form: () => getImg(form),
  Docs: () => getImg(docs),
  Back: () => getImg(back),
  Check: () => getImg(check),
  CheckVacio: () => getImg(checkVacio),
  
}

export default FactoryImg;