import React from "react";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Layout} from "antd";
import "./pageHeader.css";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

const {Header} = Layout;

const PageHeader = ({setIsVisibleSideBar, isVisibleSideBar, identidad  ,roles}) => {
  
  
  const cmdMenu = isVisibleSideBar ?
    <MenuFoldOutlined onClick={() => setIsVisibleSideBar(false)}/> :
    <MenuUnfoldOutlined onClick={() => setIsVisibleSideBar(true)}/>
  ;
  return (
    <Header id="navbar-main" className="header"
            style={{display: "flex", alignItems: "center", padding: "0", backgroundColor: "#00529B", height: "50px"}}>
      <div id="iconControlMenu">
        {cmdMenu}
      </div>
      <div style={{marginLeft: "10px"}}>
        <img alt="Tienda Telcel" src="/logo-menu.svg" title="Tienda Tencel" style={{marginTop: "2px", height: "16px"}}/>
      </div>
      <div style={{flexGrow: "1"}}>
  
      </div>
      <div id="wrapperNombre">
        <UserOutlined/> {identidad.nick}  <br/> {roles}
      </div>
    </Header>
  );
};

export default PageHeader;