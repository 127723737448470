import React, {useState, useEffect} from 'react';


import {Row, Col, Alert, Card} from 'antd';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';

import FormSetPass from "./FormSetPass";
import DataService from "../../service/data/DataService";
import CLoading from "../../components/page/CLoading";
import UrlPage from "../UrlPage";

const PageSetPass = () => {
  
  const {tokenRecuperacion} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [dataCliente, setDataCliente] = useState({});
  
  useEffect(() => {
    (async () => {
      
      if (tokenRecuperacion === undefined) {
        return;
      }
      
      const respuesta = await DataService.recpass.validar(tokenRecuperacion)
      
      if (respuesta.success) {
        setDataCliente(respuesta.data);
      } else {
        setMsgError(respuesta.msg);
      }
      
      setIsLoaded(true);
    })();
  }, [tokenRecuperacion])
  
  if (msgError) {
    return <Alert type="error" message={msgError}/>
  }
  
  if (!isLoaded) {
    return <CLoading/>;
  }
  
  const onFormSetPassSuccess = () => {
    //solo lo envaimos al login para que confirme que puede ingrar
    window.location = UrlPage.LOGIN
  };
  
  
  return (
    <>
      <Helmet>
        <title>Tienda Telcel  - Cambiar password : Researching</title>
      </Helmet>
      <div className="pMaxT">
        <Row justify="center">
          <Col xs={24} sm={14} md={6} lg={8} xl={8}>
            <div className="has-text-centered">
              <img src="https://tiendatelcel.com.mx/assets/img/logo/logo.png" style={{width: "150px", marginTop: "10px", marginBottom: "20px"}}
                   alt="Researching"/>
              <h2 className="has-text-centered mB20">
                Cambiar Password Sitio Administrativo
              </h2>
              <p>Hola <strong>{dataCliente.nombre}</strong>, a continuación indica tu nuevo password. </p>
            </div>
  
            <FormSetPass
              onSuccess={onFormSetPassSuccess}
              tokenRec={tokenRecuperacion}
              email={dataCliente.email}
            />
          </Col>
        </Row>
      
      </div>
    </>
  );
};

export default PageSetPass