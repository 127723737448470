import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";
import DataStore from "../../../app/DataStore";
import RowTitulo from "../../../components/page/RowTitulo";
import FormItemNew from "./FormItemNew";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";


export default () => {
  
  const history = useHistory();
  const [item, setItem] = useState({});
  const [dataAddItem, setDataAddItem]=useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    
    (async () => {
      const respuesta = await DataService.Editor.Item.DataAddItem()
      if (!respuesta.success) {
        LibShowNotificacion.Alert(respuesta.msg);
      }
      setDataAddItem(respuesta.data);
      setIsLoaded(true);
      
    })();
    setItem({});
  }, []);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onItemNewSaved = (itemNew) => {
    const id = itemNew.id_item;
    DataStore.ItemIndex.SetIdSelected(id);
    FlashService.Registrar.Info("Item Creado", itemNew.id_item);
    const urlUpdate = UrlPage.ITEM_EDIT + `/${id}`;
    history.push(urlUpdate);
  };
  
  const tagTitulo = <span className="resaltar">Nuevo Item</span>
  
  return (<>
    
    <RowTitulo tagTitulo={tagTitulo} urlBack={UrlPage.ITEM_INDEX}/>
    
    <div style={{paddingRight: "5px"}}>
      
      <FormItemNew
        item={item}
        dataAddItem={dataAddItem}
        onItemSaved={onItemNewSaved}
      />
      
    </div>
  
    
  </>);
  
}