import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';


import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";

import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import DataStore from "../../../app/DataStore";
import VistaProm from "./VistaPromocion";
import FormPromocion from "./FormPromocion";
import EditorPropHtml from "../../../components/form/EditorPropHtml";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import EditorPropImg from "../../../components/form/EditorPropImg";
import RowTitulo from "../../../components/page/RowTitulo";
import RowTituloSeccionContenido from "../../../components/page/RowTituloSeccionContenido";


export default () => {
  
  const history = useHistory();
  const {idPromocion} = useParams();
  
  const [promocion, setPromocion] = useState(null);
  
  const [isLoaded, setIsLoaded] = useState(false);
  
  const [isPromModoRead, setIsPromModoRead] = useState(true);
  
  
  useEffect(() => {
    
    (async () => {
      const respuesta = await DataService.Editor.Prom.Ver(idPromocion);
      
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Ver " + idPromocion, respuesta.msg);
        return;
      }
      
      setPromocion(respuesta.data.promocion);
      setIsLoaded(true);
      
    })();
    
  }, [idPromocion]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onPromSaved = (promNew) => {
    DataStore.PromIndex.SetIdSelected(promNew.id_promocion);
    FlashService.Registrar.Info("Promoción Actualizada", promNew.titulo);
    setIsPromModoRead(true);
  };
  
  
  const tagTitulo = idPromocion
    ? <span><EditFilled/> Promoción <span className="resaltar">{idPromocion}</span></span>
    : <span className="resaltar">Nueva Promoción</span>
  ;
  
  
  const paramPromocion = isPromModoRead
    ? <VistaProm promocion={promocion} onSetModoEdit={() => setIsPromModoRead(false)}/>
    : <FormPromocion promocion={promocion} onPromSaved={onPromSaved} onSetModoRead={() => setIsPromModoRead(true)}/>;
  
  
  const fnSaveBody = async (newBody, cbError) => {
    const dataUpdate = {
      body: newBody
    }
    const respuesta = await DataService.Editor.Prom.Update(idPromocion, dataUpdate);
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      cbError();
      return;
    }
    
    LibShowNotificacion.Success("Actualización de Promoción", promocion.titulo);
    setPromocion({...promocion, body: newBody});
    
  }
  
  const fnSaveUrlImg = async (newURL, cbError) => {
    
    const dataUpdate = {
      url_img: newURL
    }
    
    const respuesta = await DataService.Editor.Prom.Update(idPromocion, dataUpdate);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      cbError();
      return;
    }
    
    LibShowNotificacion.Success("Actualización de Promoción", promocion.titulo);
    setPromocion({...promocion, url_img: newURL});
    
  }
  
  
  return (<>
    <RowTitulo urlBack={UrlPage.PROMOCION_INDEX} tagTitulo={tagTitulo}/>
    
    <div style={{paddingRight: "5px"}}>
      {paramPromocion}
    </div>
    
    
    <RowTituloSeccionContenido/>
    
    <EditorPropHtml fnSave={fnSaveBody} body={promocion.body}/>
    <EditorPropImg fnSave={fnSaveUrlImg} urlImg={promocion.url_img}/>
  
  
  </>);
  
}