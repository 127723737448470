import ApiReqJson from "./ApiReqJson";
import RawReqJson from "./RawReqJson";

import ConfigApp from "../../../AppConfig";
import factoryDataApi from "./seccion/factoryDataApi";

const urlBase = ConfigApp.isApiLocal ? ConfigApp.urlApiLocal : ConfigApp.urlApi;


const DataApi = factoryDataApi(ApiReqJson, RawReqJson, urlBase)

export default DataApi;
