import React from 'react';
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";

const CDetalleVentaFactura = ({venta}) => {
  
  const f = venta.detalleVenta.datosFacturacion;
  
  
  if (venta.id_estatus_factura === 0) {
    return (
      <>
        <h2> <FileTextOutlined className="mR5" />Factura</h2>
        No se solicito realizar factura al cliente
      </>
    );
  }
  
  const nIntf = (f && f.num_int) ? `Num Int: <strong>${f.num_int}</strong> <br/>` : null;
  
  return (
    <>
      <h2 className="tituloReporte"> <FileTextOutlined className="mR5" /> Factura</h2>
      Razón Social:<br/> <strong>{f.razon_social}</strong> <br/>
      RFC: <br/><strong>{f.rfc}</strong> <br/>
      Calle: <br/><strong>{f.calle}</strong> <br/>
      Num Ext: <strong>{f.num_ext}</strong> <br/>
      ${nIntf}
      Col: <strong>{f.col}</strong> <br/>
      Mpo: <strong>{f.mpo}</strong> <br/>
      CP: <strong>{f.cp}</strong> <br/>
      EDO: <strong>{venta.estado_envio}</strong>
    </>
  );
};

export default CDetalleVentaFactura