import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import DataSVG from "../../../app/icons/DataSVG";
import AppConfig from "../../../AppConfig";
import CS3UploaderPrivate from "../../../components/page/CS3UploaderPrivate";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import CLoading from "../../../components/page/CLoading";
import CActCrearExeSolicitud from "./CActCrearExeSolicitud";
import './cactadd.css';
import UrlPage from "../../UrlPage";
import {useHistory} from "react-router-dom";


const CPaso1 = ({onUploaded}) => {
  const urlPlantilla = AppConfig.urlPlantillaAct;
  
  const listaMimeXls = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ]
  
  return (
    <div className="hasTextCenter">
      
      <p>
        <a href={urlPlantilla} target="_blank">
          Plantilla para actualizar precios.
          <img src={DataSVG.XLS} alt="" style={{width: "50px"}}/></a>
      </p>
      
      <h3 style={{marginTop: "20px"}}>
        Subir el Archivo
      </h3>
      
      <CS3UploaderPrivate
        tipoArchivo='actualizacion'
        onUploaded={onUploaded}
        subtitulo="Archivo Excel de Actualización"
        accept={listaMimeXls}
      />
    
    </div>
  );
}

const CPaso2 = ({keyXLS, onSolicitudRecibida, onCancelar}) => {
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [msgError, setMsgError] = useState(null);
  
  useEffect(() => {
    
    //crear el reporte a partir del XLS
    if (keyXLS) {
      (async () => {
        console.log('xxx');
        const respuesta = await DataService.Editor.Act.CrearSolicitudFromExcel(keyXLS);
        if (!respuesta.success) {
          LibShowNotificacion.Alert("Error", respuesta.msg);
          setMsgError(respuesta.msg);
          setIsLoaded(true);
        }
        
        const solicitud = respuesta.data.solicitud;
        onSolicitudRecibida(solicitud);
      })();
    }
    
    
  }, [keyXLS]);
  
  const msgEspera = isLoaded
    ? null
    : <CLoading mensaje="Creando Solicitud de Actualización"/>;
  
  const panError = msgError
    ? <p className="panMsgError">{msgError}</p>
    : null;
  
  return (
    <div className="hasTextCenter">
      <h3 style={{marginTop: "20px"}}>
        Analizar Archivo Excel - Crear Solicitud
      </h3>
      <h4><small>KEY</small> {keyXLS}</h4>
      {msgEspera}
      {panError}
    </div>
  );
}

const CAviso = ({id_act}) => {
  const history = useHistory();
  
  const onClick = () => {
    history.push(UrlPage.ITEM_INDEX);
  };
  
  useEffect(() => {
    setTimeout(() => {
      onClick();
    }, 3000)
  }, [])
  
  return (
    <div className="hasTextCenter">
      <div style={{margin: "30px 0px"}}>
        <img src={DataSVG.LIKE} style={{width: "50%"}} onClick={onClick} alt=""/>
      </div>
      
      <div style={{marginBottom: "40px"}}>
        <CLoading mensaje="Redireccionando "/>
      </div>
    
    
    </div>
  
  );
};

const pasoInicial=1;

export default (props) => {
  const [paso, setPaso] = useState(pasoInicial);
  const [keyXLS, setkeyXLS] = useState(null);
  const [solicitud, setSolicitud] = useState(null);
  const [id_act, setIdAct] = useState(null);
  
  const onUploadXLS = (key) => {
    setPaso(2);
    setkeyXLS(key);
  }
  
  
  const onSolicitudRecibida = (sol) => {
    setSolicitud(sol);
    setPaso(3);
  }
  
  const onActualizacionTerminada = (id_act) => {
    setIdAct(id_act);
    setPaso(4)
  }
  
  const onCancelar = () => {
    setPaso(1);
    setkeyXLS(null);
    setSolicitud(null)
  }
  
  
  let contenido;
  
  if (paso === 1) {
    // subir l archivo
    contenido = <CPaso1 onUploaded={onUploadXLS}/>
  } else if (paso === 2) {
    // analizar el reporte de solicitud
    contenido = <CPaso2 keyXLS={keyXLS} onCancelar={onCancelar} onSolicitudRecibida={onSolicitudRecibida}/>;
  } else if (paso === 3) {
    // aceptar el reporte y ejecutar la actualizacion
    contenido = <CActCrearExeSolicitud solicitud={solicitud} onCancelar={onCancelar}
                                       onActualizacionTerminada={onActualizacionTerminada}/>;
  } else if (paso === 4) {
    
    contenido = <CAviso id_act={id_act}/>;
  }
  
  
  const subtitulo = (paso < 4)
    ? <span>Paso {paso}/3 Actualización de Precios</span>
    : <span>Actualización Completada</span>;
  
  
  return (<>
    <Row>
      <Col xs={24} sm={24} md={24}>
        <h2 className="hasTextCenter">
          {subtitulo}
        </h2>
        {contenido}
      </Col>
    </Row>
  </>)
  
}