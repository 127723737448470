import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import itemFiltroParam from "./tablaItem/ItemFiltroParam";
import CmdItemRow from "./tablaItem/CmdItemRow";
import ATabla from "../../../components/tabla/ATabla";


import CmdItemIndex from "./tablaItem/CmdItemIndex";
import FnCopyXlsItem from "./tablaItem/FnCopyXlsItem";
import LibFormat from "../../../lib/LibFormat";
import './citemindex.css';
import CeldaID from "../../../components/page/CeldaID";
import LinkNewPage from "../../../components/tabla/LinkNewPage";
import LinkImgRaw from "../../../components/tabla/LinkImgRow";


import CPosHome from "./CPosHome";

export default () => {
  const history = useHistory();
  
  const [listaItems, setListaItems] = useState([]);
  
  const [filtroItem, setFiltroItem] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaItemFiltrado, setListaItemFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.ItemIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Editor.Item.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaItems(lista);
        DataStore.ItemIndex.Set(lista);
        LibShowNotificacion.Success("Items", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaItems.filter(item => {
      return itemFiltroParam.fn(item, filtroItem.current, filtroItem.valor)
    });
    setListaItemFiltrado(lista);
    
  }, [listaItems, filtroItem]);
  
  
  const onItemUpdated = (newItem) => {
    const listaNew = listaItems.map(k => {
      if (k.id_item === newItem.id_item) {
        return {...newItem}
      }
      return k;
    });
    
    setListaItems(listaNew);
  }
  
  const onCmdRowEnd = async (operacion, model) => {
    
    const {id_item} = model;
    
    if (operacion === "update") {
      setIdSelected(id_item);
      DataStore.ItemIndex.SetIdSelected(id_item);
      history.push(UrlPage.ITEM_EDIT + `/${id_item}`);
    }else if (operacion==="additemprom"){
      LibShowNotificacion.Info("Agregando a Items Promocionados");
      const r= await DataService.Editor.ItemProm.Create(id_item);
      if(r.success){
        LibShowNotificacion.Success("Agregado",model.modelo);
      }else{
        LibShowNotificacion.Alert("Error",r.msg);
      }
    }
    
  };
  
  
  const listaElem = listaItemFiltrado.map((k, index) => {
    
    let rowCSS = "rowTabla rowTablaConBorde " + (idSelected === k.id_item ? " rowSelected" : "");
    
    rowCSS += k.is_enpausa ? " itemenpausa" : "";
    
    const tagEnPausa = k.is_enpausa
      ? <div><span className="tagEnPausa" title="El item no aparecera publicado en el sitio">EN PAUSA</span></div>
      : null;
    
    
    return (
      <Row key={index} className={rowCSS}>
        
        <CeldaID id={k.id_item} size={3}>
          <CmdItemRow model={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Marca:</span> {k.marca}
        </Col>
        
        
        
        <Col xs={24} sm={11} md={12} className="divFlex">
          <CPosHome item={k} onItemUpdated={onItemUpdated} />
          <div className="flexGrow1">
            <Link to={UrlPage.ITEM_EDIT + `/${k.id_item}`}>
              <span className="labMob">Model:</span> <strong>{k.modelo}</strong>
            </Link>
            <div className="colorGray">
              <span className="labMob">Descripcion:</span> {k.descripcion}
              {tagEnPausa}
            </div>
          </div>
          <LinkNewPage urlAbs={k.urlAbs}/>
          <LinkImgRaw urlAbs={k.urlImg}/>
        </Col>
        
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Entrada:</span> {k.entrada}<br/>
          <span className="labMob">Tipo:</span> <span className="textoTipo">{k.tipo}</span>
        </Col>
        
        
        <Col xs={24} sm={3} md={3} className="textoPrecio">
          <span className="labMob">Precio:</span>
          <span className="colorResaltar">
          $ {LibFormat.Money(k.precio, 2)}
          </span>
        
        </Col>
      
      
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID size={3}/>
      
      <Col xs={24} sm={3} md={3}>
        Marca
      </Col>
      
      <Col xs={24} sm={12} md={12}>
        Modelo / Descripción
      </Col>
      
      <Col xs={24} sm={3} md={3}>
        Entrada / Tipo
      </Col>
      
      
      <Col xs={24} sm={3} md={3} className="hasTextRight">
        Precio
      </Col>
    
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsItem(listaItemFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    } else if( operacion==="add"){
      DataStore.ItemIndex.SetIdSelected(null);
      history.push(UrlPage.ITEM_ADD)
    }
  };
  
  const getCmdIndex = () => <CmdItemIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaItemFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Item"
      filtroConfig={itemFiltroParam.config}
      setFiltro={setFiltroItem}
      filtro={filtroItem}
    />
  );
  
}