import React, {useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';

import {Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import RecFiltroParam from "./tablaRec/RecFiltroParam";
import ATabla from "../../../components/tabla/ATabla";

import CmdRecIndex from "./tablaRec/CmdRecIndex";
import FnCopyXlsRec from "./tablaRec/FnCopyXlsRec";
import CeldaID from "../../../components/page/CeldaID";
import CmdRecRow from "./tablaRec/CmdRecRow";
import RecordatorioModalDelete from "./tablaRec/RecordatorioModalDelete";
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";

const libfront = require('@iqdavidh/libfront');

export default () => {
  const history = useHistory();
  
  const [listaRec, setListaRec] = useState([]);
  const [filtroRec, setFiltroRec] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaRecFiltrado, setListaRecFiltrado] = useState([]);
  
  const [dataModalDelete, setDataModalDelete] = useState(null);
  const [isDeleteEnProceso, setIsDeleteEnProceso] = useState(false);
  
  const [idSelected, setIdSelected] = useState(DataStore.RecordatorioIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Editor.Recordatorio.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaRec(lista);
        DataStore.RecordatorioIndex.Set(lista);
        LibShowNotificacion.Success("Recordatorios de Eventos", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaRec.filter(rec => {
      return RecFiltroParam.fn(rec, filtroRec.current, filtroRec.valor)
    });
    setListaRecFiltrado(lista);
    
  }, [listaRec, filtroRec]);
  
  
  const onCmdRowEnd = async (operacion, recordatorio) => {
    
    const {id_recordatorio} = recordatorio;
    
    if (operacion === "update") {
      console.log(id_recordatorio);
      setIdSelected(id_recordatorio);
      const urlRedir = UrlPage.RECORDATORIO_EVENTO_EDIT + `/${id_recordatorio}`;
      history.push(urlRedir);
    } else if (operacion === 'delete') {
      setDataModalDelete(recordatorio);
    } else if (operacion === 'add') {
      DataStore.RecordatorioIndex.SetIdSelected(null);
      history.push(UrlPage.RECORDATORIO_EVENTO_ADD);
    } else {
      LibShowNotificacion.Alert("Error operacion", `operacion no reconocida ${operacion}`)
    }
    
  };
  
  
  const listaElem = listaRecFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_recordatorio ? " rowSelected" : "");
    
    const imgMain = k.urlImg
      ? <img alt="" src={k.urlImg} className="imgRowTabla"/>
      : null
    const imgMainMovil = k.urlImgMovil
      ? <img alt="" src={k.urlImgMovil} className="imgRowTabla"/>
      : null
    
    const iconActivo = k.is_activo
      ?<CheckCircleFilled style={{color:"green", marginLeft:"10px"}} title="Recodatorio Activo"/>
      :null;
    
    return (
      <Row key={index} className={rowCSS}>
        
        <CeldaID id={k.id_recordatorio}>
          <CmdRecRow recordatorio={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        
        <Col xs={24} sm={4} md={6}>
          <span className="labMob">Nombre del Evento:</span>
          {k.nombre_evento}
          {iconActivo}
        </Col>
        
        
        <Col xs={24} sm={4} md={2}>
          <span className="labMob">F Inicio:</span>
          {libfront.Fecha.getDMYFromYMD(k.fecha_ini)}
        </Col>
        
        <Col xs={24} sm={4} md={2}>
          <span className="labMob">F Final:</span>
          {libfront.Fecha.getDMYFromYMD(k.fecha_fin)}
        </Col>
        
        
        <Col xs={24} sm={4} md={6}>
          <span className="labMob">Banner:</span>
          {imgMain}
        </Col>
        
        <Col xs={24} sm={4} md={6}>
          <span className="labMob">Banner Movil:</span>
          {imgMainMovil}
        </Col>
      
      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <CeldaID/>
      
      <Col xs={24} sm={4} md={6}>
        Nombre del Evento
      </Col>
       <Col xs={24} sm={4} md={2}>
        Fecha Inicia Banner
      </Col>
      <Col xs={24} sm={4} md={2}>
        Fecha Fin Banner
      </Col>
  
  
      <Col xs={24} sm={4} md={6}>
        Imagen Banner
      </Col>
      <Col xs={24} sm={4} md={6}>
        Imagen Banner Movil
      </Col>
      
    </Row>
  );
  
  const onClickModalDelete = async (isBottonOK) => {
    if (!isBottonOK) {
      setDataModalDelete(null)
      //es cancelacion
      return;
    }
    
    if (isDeleteEnProceso) {
      return;
    }
    
    setIsDeleteEnProceso(true);
    const id = dataModalDelete.id_recordatorio;
    
    const respuesta = await DataService.Editor.Recordatorio.Delete(id);
    if (!respuesta.success) {
      setIsDeleteEnProceso(false);
      LibShowNotificacion.Alert(respuesta.msg);
      return;
    }
    
    LibShowNotificacion.Success("Recordatorio Eliminado", id)
    
    const lista = listaRec.filter(k => k.id_recordatorio !== id);
    setDataModalDelete(null);
    setIsDeleteEnProceso(false);
    setListaRec(lista);
    
  }
  
  const modalDelete = (
    <RecordatorioModalDelete
      recordatorio={dataModalDelete}
      visible={dataModalDelete !== null}
      onClickModalDelete={onClickModalDelete}/>
  );
  
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "add") {
      DataStore.RecordatorioIndex.SetIdSelected(null);
      history.push(UrlPage.RECORDATORIO_EVENTO_ADD)
      
    } else if (operacion === "copiar_xls") {
      FnCopyXlsRec(listaRecFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdRecIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaRecFiltrado.length}
      modalDelete={modalDelete}
      cmdIndex={getCmdIndex()}
      tituloModel="Recodatorios de Evento"
      filtroConfig={RecFiltroParam.config}
      setFiltro={setFiltroRec}
      filtro={filtroRec}
    />
  );
  
}