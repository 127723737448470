import React from "react";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import {SendOutlined} from "@ant-design/icons";


export default ({usuario, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "edit", icon: <EditFilled/>, label: "Editar", style: {color: "green"}},
    {key: "send_pass", icon: <SendOutlined/>, label: "Enviar Password Temporal", style: {color: "blue"}},
    {isDivider: true},
    {key: "delete", icon: <DeleteOutlined/>, label: "Eliminar", style: {color: "red"}},
  ];
  
  const tituloModal = (
    <>
      <span>Usuario </span>
      <strong style={{color: "green"}}>{usuario.nombre}</strong>
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={usuario}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}