import React from "react";

export default ({url , cssImage})=>{
  if(!url){
    return <p>Sin Imagen</p>
  }
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img className={cssImage} src={url} title={url} alt=""/>
    </a>
  );
};