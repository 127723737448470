import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import React from "react";
import {Input, Select} from "antd";

const {Option} = Select;

export default ({filtro, filtroConfig, setFiltro}) => {
  
  function onSetTipoFiltro(value) {
    const filtroNew = {current: value, valor: ""};
    setFiltro(filtroNew);
  }
  
  
  function onSetValorFiltro(value) {
    const filtroNew = {current: filtro.current, valor: value};
    setFiltro(filtroNew);
  }
  
  
  const onFiltroTextoChange = (valor) => {
    const filtroNew = {...filtro};
    filtroNew.valor = valor.toLowerCase();
    
    setFiltro(filtroNew);
  };
  
  const listaTipoFiltro = filtroConfig.map((k, kindex) => {
    return (<Option value={k.label} key={kindex}>{k.label}</Option>);
  });
  
  const cbxFiltro = (
    <Select
      defaultValue={filtro.current}
      style={{width: "190px"}}
      onChange={onSetTipoFiltro}>
      {listaTipoFiltro}
    </Select>
  );
  
  
  let dataFiltroSelected = filtroConfig.find(k => {
    return k.label === filtro.current;
  });
  
  let elementFiltroSelected = null;
  if (dataFiltroSelected.tipo === "texto") {
    elementFiltroSelected = (
      <Input
        title="Filtrar Tabla"
        onChange={(e) => onFiltroTextoChange(e.target.value)}/>
    );
  } else if (dataFiltroSelected.tipo === "cbx") {
    
    const listaSub = dataFiltroSelected.opciones.map((k, kindex) => {
      return (<Option value={k.key} key={kindex}>{k.label}</Option>);
    });
    
    const cssCBX = dataFiltroSelected.cssCBX || "cbxFiltro";
    
    elementFiltroSelected = (
      <div className={cssCBX}>
        <Select
          style={{width:"200px"}}
          defaultValue={filtro.valor}
          onChange={onSetValorFiltro}
        >
          {listaSub}
        </Select>
      </div>
    
    );
  }
  
  
  return (
    <div className="wrapperFiltro">
      <div className="iconFiltro">
        <FilterOutlined/>
      </div>
      
      {cbxFiltro}
      {elementFiltroSelected}
    </div>
  );
  
  
}