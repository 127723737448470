import React from 'react';
import {Row,Col} from "antd";


export default (props) => {
  
 
  return (<>
    <Row>
      <Col xs={24} sm={24} md={8}>
        <h2>Actualizar Elementos de Sitio</h2>
      </Col>
    </Row>
  </>)
  
}