import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Badge, Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import actFiltroParam from "./tablaAct/ActFiltroParam";
import CmdActRow from "./tablaAct/CmdActRow";
import ATabla from "../../../components/tabla/ATabla";
import CmdActIndex from "./tablaAct/CmdActIndex";
import FnCopyXlsAct from "./tablaAct/FnCopyXlsAct";
import CeldaID from "../../../components/page/CeldaID";



export default () => {
  const history = useHistory();
  
  const [listaAct, setListaAct] = useState([]);
  
  const [filtroAct, setFiltroAct] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaActFiltrado, setListaActFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.ActIndex.GetIdSelected() || null);
  
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Admin.Act.Index();
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaAct(lista);
        DataStore.ActIndex.Set(lista);
        LibShowNotificacion.Success("Actualizaciones", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaAct.filter(item => {
      return actFiltroParam.fn(item, filtroAct.current, filtroAct.valor)
    });
    setListaActFiltrado(lista);
    
  }, [listaAct, filtroAct]);
  
  
  const onCmdRowEnd = async (operacion, act) => {
    
    const {id_act} = act;
    
    if (operacion === "ver") {
      setIdSelected(id_act);
      history.push(UrlPage.ADMIN_ACTUALIZACION_VER + '/' + id_act);
    }
    
  };
  
  const listaElem = listaActFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_act ? " rowSelected" : "");
    
    
    return (
      <Row key={index} className={rowCSS}>
  
        <CeldaID id={k.id_act}>
          <CmdActRow act={k} onCmdEnd={onCmdRowEnd}/>
        </CeldaID>
        
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Usuario:</span> {k.usuario}
        </Col>
        <Col xs={24} sm={3} md={3}>
          <span className="labMob">Estatus:</span> {k.estatus}
        </Col>
        <Col xs={24} sm={4} md={3}>
          <span className="labMob">IP:</span> {k.ip}
        </Col>
  
  
        <Col xs={24} sm={4} md={4}>
          <span className="labMob">Fecha:</span> {k.freg}
        </Col>
  
        <Col xs={24} sm={8} md={8}>
          <span className="labMob">Comentario:</span> {k.comentario} <br/>
        </Col>


      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <Col xs={24} sm={3} md={3}>
        ID
      </Col>
      <Col xs={24} sm={3} md={3}>
        Usuario
      </Col>
      <Col xs={24} sm={3} md={3}>
        Estatus
      </Col>
      <Col xs={24} sm={3} md={3}>
        Ip
      </Col>
      <Col xs={24} sm={4} md={4}>
        Fecha
      </Col>
      <Col xs={24} sm={8} md={8}>
        Comentario
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "copiar_xls") {
      FnCopyXlsAct(listaActFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdActIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaActFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Act"
      filtroConfig={actFiltroParam.config}
      setFiltro={setFiltroAct}
      filtro={filtroAct}
    />
  );
  
}