import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button,  Form, Card, DatePicker} from 'antd';
import "../../../../components/form/form.css";

import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";


import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";

import LibFormAnt from "../../../../lib/LibFormAnt";
import * as moment from "moment";
import DicEstatusVenta from "../../../../model/DicEstatusVenta";

const FormItem = Form.Item;



/***
 * Formulario para actualziar la fechade entrega
 * @param venta
 * @param onVentaSaved
 * @returns {*}
 * @constructor
 */
const FormVentaEntrega = ({venta, onVentaSaved}) => {
  
  const history = useHistory();
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      fecha_entrega: venta.fecha_entrega?moment(venta.fecha_entrega) :undefined,
      id_venta_estatus : DicEstatusVenta.PagadoYEntregado
    });
  }, [venta, form]);
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    setMsgError("");
    
    let respuesta = await DataService.Editor.Venta.Update(venta.id_venta, formData);
  
    setIsEnProceso(false);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    if( typeof onVentaSaved==="function"){
      onVentaSaved(respuesta.data);
    }
  
    LibShowNotificacion.Success("Actualizado Guía");
    
  };
  
  const onCancelForm = () => {
    form.setFieldsValue({
      fecha_entrega: moment(venta.fecha_entrega)
    });
  };
  
  const tituloForm = (
<div className="titCard">
  <span>Registrar Entrega</span>
</div>
  );
  
  
  return (
    <div>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelCorto}
        onFinish={onSubmitForm}
      >
        <Card title={tituloForm} bordered={true}>
          
          <FormItem
            label="Fecha de Entrega"
            name="fecha_entrega"
            rules={[{required: true}]} hasFeedback>
            <DatePicker  placeholder="Seleccionar"   format="DD/MM/YYYY" />
          </FormItem>
          
        
        </Card>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar Fecha</span>
          </Button>
          
          <Button
            onClick={() => onCancelForm()}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      </Form>
    </div>
  );
};

export default FormVentaEntrega