export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    }
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -") {
      return true;
    }
    
    const valorNom = filtroValor.trim().toLowerCase();
    let isIncluir = true;
    
    
    
    //------------------------------------------------------
    if (isIncluir && filtroNombre === "Buscar Texto") {
      if (valorNom !== "") {
        isIncluir = `${item.titulo}|${item.url_rel}|${item.description}`.toLowerCase().includes(valorNom);
      }
    }
    
    return isIncluir
  }
}
;

