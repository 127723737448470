import {notification} from 'antd';


const show = (titulo, msg, onClickCB = null, tipo = 'info') => {
  
  notification[tipo]({
    message: titulo,
    description: msg,
    onClick: () => {
      if (typeof onClickCB === "function") {
        onClickCB();
      }
    },
  });
  
};


const LibShowNotificacion = {
  Info: (titulo, msg) => show(titulo, msg, null, 'info'),
  Success: (titulo, msg) => show(titulo, msg, null, 'success'),
  Alert: (titulo, msg) => show(titulo, msg, null, 'error')
};

export default LibShowNotificacion;
