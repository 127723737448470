import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Input, Form, Card} from 'antd';
import "../../../../components/form/form.css";

import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";


import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";

import LibFormAnt from "../../../../lib/LibFormAnt";

const {TextArea}=Input;
const FormItem = Form.Item;

const FormVentaCancelar = ({venta, onVentaSaved}) => {
  
  const history = useHistory();
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.setFieldsValue({
      comentario: '',
    });
  }, [venta, form]);
  
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    setMsgError("");
    
    let respuesta = await DataService.Admin.Venta.Cancelar(venta.id_venta, formData.comentario);
    setIsEnProceso(false);
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Cancelar", respuesta.msg);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    LibShowNotificacion.Success("Actualizado Comentario");
    if (typeof onVentaSaved === "function") {
      onVentaSaved(respuesta.data);
    }
    
    
  };
  
  const onCancelForm = () => {
    form.setFieldsValue({
      comentario_adn: venta.comentario_adm
    });
  };
  
  
  
  return (
    <div>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelCorto}
        onFinish={onSubmitForm}
      >
        <Card bordered={true}>
          
          <FormItem
            label="Comentario"
            name="comentario"
            rules={[{required: true}]} hasFeedback>
            <TextArea rows={3}/>
          </FormItem>
          
        </Card>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar</span>
          </Button>
          
          <Button
            onClick={() => onCancelForm()}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      </Form>
    </div>
  );
};

export default FormVentaCancelar