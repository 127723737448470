import React from 'react';


const McItem = ({data}) => {
  
  
  const lista= Object.keys(data).map( (key, indexK)=>{
    
    if(key==="nombre"||key==="email"||key==="tel"){
      return null;
    }
    
    return (
      <li key={indexK}>
        <strong className="tagLabelItemMc">{key}</strong>
        <span className="pL5">{data[key]}</span>
    </li>);
  });
  
  return (
    <ul>
      {lista}
    </ul>
  );
};

export default McItem