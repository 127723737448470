import React from "react";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({ model, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "delete", icon: <DeleteOutlined/>, label: "Eliminar", style: {color: "red"}},
  ];
  
  const tituloModal = (
    <>
      <span>Items promocionados </span>
      {/*<strong style={{color: "green"}}>{item.freg}</strong>*/}
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={model}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}