import LibCopyXls from "../../../../lib/LibCopyXls";
import LibObject from "../../../../lib/LibObject";

export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id', 'token', 'usuario', 'ip', 'estatus', 'comentario', 'freg'];
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    const id = LibObject.getPropiedad(k, 'id_act');
    const token = LibObject.getPropiedad(k, 'token');
    const usuario = LibObject.getPropiedad(k, 'usuario');
    
    const ip = LibObject.getPropiedad(k, 'ip');
    const estatus = LibObject.getPropiedad(k, 'estatus');
    const comentario = LibObject.getPropiedad(k, 'comentario');
    const freg = LibObject.getPropiedad(k, 'freg');
    
    
    return [
      (indexk + 1), id, token, usuario,
      ip, estatus, comentario, freg
    ];
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}