let isDesktop = false;
let nombreUsuario = "";
let emailUsuario = "";

let listaUsuarioTipo = [];
let listaContenidoTipo = [];

let identidad = {};
let isAdmin = false;
let isEditor = false;

const factoryDataIndex = () => {
  const param = {id: null};
  
  return {
    SetIdSelected: valor => param.id = valor,
    GetIdSelected: () => param.id,
    
    Set: (lista) => {
      param.lista = lista
    },
    Get: () => {
      return param.lista;
    },
    Reset: () => {
      param.lista = [];
    },
    GetIsCancel: () => {
      return param.IsCancel;
    },
    SetIsCancel: () => {
      param.IsCancel = true;
    }
  }
  
}


const paramUsuarioIndex = factoryDataIndex();
const paramActIndex = factoryDataIndex();
const paramItemIndex = factoryDataIndex();
const paramPagIndex = factoryDataIndex();
const paramPagFormIndex = factoryDataIndex();
const paramPromIndex = factoryDataIndex();
const paramVConfig = factoryDataIndex();
const paramRecIndex = factoryDataIndex();
const paramVentaIndex = factoryDataIndex();

let idUsuario;

const DataStore = {
  Inicilizar: (data) => {
    listaUsuarioTipo = data.listaUsuarioTipo;
    listaContenidoTipo = data.listaContenidoTipo;
    
    identidad = data.identidad; // idUsuario, nick, rol [isAdm/isEditor
    
    idUsuario = identidad.idUsuario;
    nombreUsuario = identidad.nombre;
    emailUsuario = identidad.email;
    isAdmin = identidad.isAdmin;
    isEditor = identidad.isEditor;
  },
  GetIsAdmin: () => isAdmin,
  GetIsEditor: () => isEditor,
  GetIdentidad: () => identidad,
  getListaRol: () => {
    const rolAdm = (identidad.rol || {}).isAdm ? 'Admin' : '';
    const rolEditor = (identidad.rol || {}).isEditor ? 'Editor' : '';
    return [rolAdm, rolEditor].filter(k => k !== '').join(', ')
  },
  Usuario: {
    GetNombre: () => {
      return nombreUsuario;
    },
    GetEmail: () => {
      return emailUsuario;
    },
    GetId: () => {
      return idUsuario;
    }
  },
  ListaUsuarioTipo: {
    Get: () => listaUsuarioTipo,
  },
  ListaContenidoTipo: {
    Get: () => listaContenidoTipo
  },
  IsDesktop: {
    Get: () => isDesktop,
    Set: (valor) => {
      isDesktop = valor;
    }
  },
  UsuarioIndex: paramUsuarioIndex,
  ActIndex: paramActIndex,
  ItemIndex: paramItemIndex,
  PagIndex: paramPagIndex,
  PagFormIndex: paramPagFormIndex,
  PromIndex: paramPromIndex,
  VConfigIndex: paramVConfig,
  RecordatorioIndex: paramRecIndex,
  VentaIndex: paramVentaIndex,
  McIndex: factoryDataIndex(),
  ItemPromIndex: factoryDataIndex(),
};

export default DataStore;