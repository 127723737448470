import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Row} from "antd";
import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FlashService from "../../../app/FlashService";
import moment from 'moment';

const FormItem = Form.Item;
const {TextArea} = Input;


export default ({ onRecNewSaved,  onBackToIndex}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [recordatorio] = useState(null);
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      nombre_evento: '',
      fecha_ini: moment(Date.now()),
      fecha_fin: moment(Date.now()),
      comentario: ''
    });
    
  }, [recordatorio, form]);
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    let respuesta = await DataService.Editor.Recordatorio.Create(formData);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    FlashService.Registrar.Success(formData.nombre_evento, "Evento Creado");
    
    onRecNewSaved(respuesta.data.recordatorio);
  };
  
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelGrande}
      onFinish={onSubmitForm}
    >
      <Row>
        
        <Col xs={24} sm={24} md={12}>
          
          <FormItem
            label="Nombre del Evento"
            name="nombre_evento"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
          
          <FormItem
            label="Fecha inicio para mostrar banner"
            name="fecha_ini"
            rules={[{required: true}]} hasFeedback>
            <DatePicker format='DD/MM/YYYY'/>
          </FormItem>
          
          
          <FormItem
            label="Fecha objetivo (cuando se cambia el banner)"
            name="fecha_fin"
            rules={[{required: true}]} hasFeedback>
            <DatePicker format='DD/MM/YYYY'/>
          </FormItem>
          
          
          <FormItem
            label="Comentario"
            name="comentario"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
        
        
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12}>
          <div style={{marginTop: "20px", textAlign: "center"}}>
            
            <Button
              style={{width: "180px", marginRight: "20px"}}
              onClick={() => onBackToIndex()}>
              <span>Cancelar</span>
            </Button>
            
            <Button type="primary"
                    htmlType="submit"
                    style={{width: "180px"}}
                    loading={isEsProceso} block>
              <CloudUploadOutlined/>
              <span>Guardar</span>
            </Button>
            
            <div className="msgError" style={{minHeight: "150px"}}>
              {msgError}
            </div>
          
          </div>
        </Col>
      </Row>
    
    </Form>
  
  );
}