import UrlPage from "../../page/UrlPage";
import LibLocalStorage from "../../lib/LibLocalStorage";


class Singleton {
  constructor() {
    
    this.token = LibLocalStorage.getTokenIdentidad();
    this.tsValid = LibLocalStorage.getTsValid();
    
    if (
      this.token &&
      this.tsValid
    ) {
      
      const tsNow = Date.now();
      if (this.tsValid < tsNow) {
        this.setLogOut();
      }
      
    }
    
  }
  
  
  registrarLogin(public_data, token, isSaveLocalStorage = false) {
    
    if (!token || !public_data) {
      throw new Error('No se puede hacer session sin datos correctos');
    }
    
    this.token = token;
  
  
    if (isSaveLocalStorage && token && public_data) {
      
      LibLocalStorage.saveTokenIdentidad(token);
    
      const ts = Date.now();
      this.tsValid = ts + public_data.lapso;
      LibLocalStorage.saveTsValid(this.tsValid);
    }
  }
  
  getIsAuthenticated() {
    
    if (this.token === null) {
      return false;
    }
  
    const tsCurrent = Date.now();
  
    //validar el tiempo de vida de la sesion
    return this.tsValid >= tsCurrent;
  }
  
  getToken() {
    return this.token;
  }
  
  setToken(v) {
    this.token = v;
  }
  
  setLogOut() {
    this.token = null;
    this.tsValid = 0;
    
    LibLocalStorage.deleteTokenIdentidad();
    LibLocalStorage.deleteTsValid();
    window.location = UrlPage.LOGIN;
  }
  
  
  salirNoAutorizado(permisoRequerido) {
    if (!this.getIsAuthenticated()) {
      window.location = UrlPage.Home;
      return true;
    }
    
    return false
  }
  
}

const AuthService = new Singleton();


export default AuthService;
