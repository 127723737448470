import React from 'react';
import LibEdo from "../../../../lib/LibEdo";
import EnvironmentOutlined from "@ant-design/icons/lib/icons/EnvironmentOutlined";

const CDetalleVentaEnvio = ({venta}) => {
  
  const dom = venta.detalleVenta.domEntrega;
  
  
  const domNInt = (dom && dom.num_int) ? `Num Int: <strong>${dom.num_int}</strong> <br/>` : '';
  
  return (
    <>
      <h2 className="tituloReporte">  <EnvironmentOutlined className="mR5" />Envío</h2>
      Destinatario:  <strong>{dom.destinatario}</strong> <br/>
      Destinatario Tel: <strong>{dom.destinatario_tel}</strong> <br/>
      Calle: <strong>{dom.calle}</strong> <br/>
      Num Ext: <strong>{dom.num_ext}</strong> <br/>
      {domNInt}
      Entre Calles: <strong>{dom.entre_calles}</strong> <br/>
      Col: <strong>{dom.col}</strong> <br/>
      Mpo: <strong>{dom.mpo}</strong> <br/>
      CP: <strong>{dom.cp}</strong> <br/>
      EDO: <strong>{LibEdo[dom.edo] || dom.edo}</strong>
    </>
  );
};

export default CDetalleVentaEnvio