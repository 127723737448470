import React, {useEffect, useState} from 'react';

import {Button, Input, Form,  Col, Row} from 'antd';
import "../../../components/form/form.css";

import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";


const FormItem = Form.Item;


const FormVConfig = ({varConfig, onSetModoVista, onFormularioSaved}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  
  useEffect(() => {
    form.setFieldsValue({
      valor: varConfig.valor
    });
    
  }, [varConfig, form]);
  
  
  const {TextArea} = Input;
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    setMsgError("");
    
    let respuesta =  await DataService.Admin.VConfig.Update(varConfig.var_name, formData);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    onFormularioSaved(respuesta.data);
    
  };
  
  
  
  return (
    <>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelCorto}
        onFinish={onSubmitForm}
      >
        <Row>
          <Col xs={24} sm={24} md={24}>
            <h2 className="hasTextCenter mB20 ">{varConfig.titulo}</h2>
            <FormItem
              label="Valor"
              name="valor"
              rules={[{required: false}]} hasFeedback>
              <TextArea/>
            </FormItem>
            <p>{varConfig.comentario_html}</p>
          </Col>
          
        </Row>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar </span>
          </Button>
          
          <Button
            onClick={() => onSetModoVista()}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      
      </Form>
    </>
  );
};

export default FormVConfig