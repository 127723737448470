import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";
import FlashService from "../../../app/FlashService";
import DataStore from "../../../app/DataStore";
import RowTitulo from "../../../components/page/RowTitulo";
import FormRecAdd from "./FormRecAdd";


export default () => {
  
  const history = useHistory();
  
  const onRecNewSaved = (recNew) => {
    const id = recNew.id_recordatorio;
    DataStore.RecordatorioIndex.SetIdSelected(id);
    FlashService.Registrar.Info("Recordatorio Creado","OK");
    const urlUpdate = UrlPage.RECORDATORIO_EVENTO_EDIT + `/${id}`;
    history.push(urlUpdate);
  };
  
  const tagTitulo = <span>Recordatorio Nuevo</span>;
  
  const onBackToIndex = () => {
    const urlIndex = UrlPage.RECORDATORIO_EVENTO_INDEX;
    history.push(urlIndex);
  }
  
  return (
    <>
      <RowTitulo tagTitulo={tagTitulo} urlBack={UrlPage.RECORDATORIO_EVENTO_INDEX}/>
      <div style={{paddingRight: "5px"}}>
        <FormRecAdd onRecNewSaved={onRecNewSaved} onBackToIndex={onBackToIndex}/>
      </div>
    </>
  );
  
}