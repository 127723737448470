import React from "react";
import {ExceptionOutlined} from "@ant-design/icons";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";


export default ({onCmdEnd}) => {
  
  const listaOpciones = [
    {key: "add", icon: <PlusOutlined/>, label: "Crear Variable de Configuracion", style: {color: "green"}},
    {isDivider: true},
    {key: "copiar_xls", icon: <ExceptionOutlined/>, label: "Copiar a Excel"},
  ];
  
  const tituloModal = (
    <>
      <span>Items</span>
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      onCmdEnd={onCmdEnd}
      btnTipo="default"
      titulo={tituloModal}
    />)
    ;
  
}