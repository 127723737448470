import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Input, Form,  Col, Row} from 'antd';
import "../../../components/form/form.css";

import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";


const FormItem = Form.Item;


const FormFormulario = ({pagForm, onSetModoVista, onFormularioSaved}) => {
  
  const history = useHistory();
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  
  useEffect(() => {
    form.setFieldsValue({
      id_pag_from: pagForm.id_pag_form,
      nombre: pagForm.nombre,
      email: pagForm.email,
      comentario: pagForm.comentario
    });
    
    
  }, [pagForm, form]);
  
  const isNewFormulario = !pagForm.id_pag_form;
  const {TextArea} = Input;
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    
    setIsEnProceso(true);
    setMsgError("");
    
    const isFormNew = pagForm.id_pag_form === undefined;
    
    let respuesta = null;
    
    if (isFormNew) {
      //es un create
      respuesta = await DataService.Admin.PagForm.Add(formData);
    } else {
      //es un update
      respuesta = await DataService.Admin.PagForm.Update(pagForm.id_pag_form, formData);
      
    }
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    onFormularioSaved(respuesta.data);
    
  };
  
  
  
  return (
    <>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelCorto}
        onFinish={onSubmitForm}
      >
        <Row>
          <Col xs={24} sm={24} md={12}>
            
            <FormItem
              label="Nombre"
              name="nombre"
              rules={[{required: true}]} hasFeedback>
              <Input/>
            </FormItem>
            
            <FormItem
              label="Email"
              name="email"
              rules={[{required: true}, {type: "email"}]} hasFeedback>
              <Input/>
            </FormItem>
          
          
          </Col>
          <Col xs={24} sm={24} md={12}  style={{paddingRight:"10px"}}>
            <FormItem
              label="Comentario"
              name="comentario"
              rules={[{required: true}]} hasFeedback>
              <TextArea/>
            </FormItem>
          </Col>
        </Row>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar </span>
          </Button>
          
          <Button
            onClick={() => onSetModoVista()}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      
      </Form>
    </>
  );
};

export default FormFormulario