import React from "react";
import {Modal} from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({item,  visible,onClickModalDelete}) => {
  
  if(item===null){
    return  null;
  }
  
  const titulo = (
    <>
      <DeleteOutlined style={{color: "red"}}/>
      <span style={{color: "red"}}>Eliminar Item Promocionado</span>
    </>
  );
  
  return (
    <Modal
      title= {titulo}
      visible={visible}
      onOk={() => onClickModalDelete(true)}
      onCancel={() => onClickModalDelete(false)}
    >
      <p>ID <strong>{item.id_item}</strong></p>
      <h3 style={{color: "green"}}>{item.tipo} {item.marca} {item.modelo}</h3>
    
    </Modal>
  );
}