import React from "react";
import {Modal} from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";

const titulo = (
  <>
    <DeleteOutlined style={{color: "red"}}/>
    <span style={{color: "red"}}>Eliminar Usuario</span>
  </>
);
export default ({usuario, visible, onClickModalDelete}) => {
  
  const nombre = usuario === null ? "" : (usuario.nombre || '');
  
  return (
    <Modal
      title={titulo}
      visible={visible}
      onOk={() => onClickModalDelete(true)}
      onCancel={() => onClickModalDelete(false)}
    >
      <h3 style={{color: "green"}}>{nombre}</h3>
    
    </Modal>
  );
}