import React from "react";
import {ExceptionOutlined, PlusOutlined} from "@ant-design/icons";
import ACmdRow from "../../../../components/tabla/ACmdRow";


export default ({onCmdEnd}) => {
  
  const listaOpciones = [
    {key: "copiar_xls", icon: <ExceptionOutlined/>, label: "Copiar a Excel"},
    {key: "add", icon: <PlusOutlined/>, label: "Agregar Item"},
  ];
  
  const tituloModal = (
    <>
      <span>Items</span>
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      onCmdEnd={onCmdEnd}
      btnTipo="default"
      titulo={tituloModal}
    />)
    ;
  
}