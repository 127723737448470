import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";


import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";

import FormParamPag from "./FormParamPag";

import EditFilled from "@ant-design/icons/lib/icons/EditFilled";

import {BackwardFilled} from "@ant-design/icons";
import DataStore from "../../../app/DataStore";
import VistaParamPag from "./VistaParamPag";
import PanContenidoPag from "./PanContenidoPag";


export default () => {
  
  const history = useHistory();
  const {idPagina} = useParams();
  
  const [pag, setPag] = useState(null);
  const [listaContenido, setListaContenido] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const [isPagParamModoRead, setIsPagParamModoRead] = useState(true);
  
  
  useEffect(() => {
    
    (async () => {
      
      const respuesta = await DataService.Editor.Pag.Ver(idPagina);
      
      if (!respuesta.success) {
        FlashService.Registrar.Alert("Error al Ver " + idPagina, respuesta.msg);
        return;
      }
      
      setPag(respuesta.data.pag);
      setListaContenido(respuesta.data.listaContenido);
      setIsLoaded(true);
      
      
    })();
    
  }, [idPagina]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onPagSaved = (pagActual) => {
    DataStore.PagIndex.SetIdSelected( pagActual.id_pag);
    FlashService.Registrar.Info("Página Actualizada", pagActual.titulo);
    const urlIndex = UrlPage.PAG_INDEX
    history.push(urlIndex);
  };
  
  
  const onListaContenidoUpdated = (listaContenidoNew) => {
    setListaContenido(listaContenidoNew);
  };
  
  
  const tagTitulo = <span><EditFilled/> Actualizar Página</span>;
  
  
  const paramPag = isPagParamModoRead
    ? <VistaParamPag pag={pag} onSetModoEdit={() => setIsPagParamModoRead(false)}/>
    : <FormParamPag pag={pag} onPagSaved={onPagSaved} onSetModoRead={() => setIsPagParamModoRead(true)}/>;
  
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{width: "100%"}}>
        <h2 style={{marginBottom: "30px", flexGrow: 1}}>
          {tagTitulo} <span className="tagResaltar">{pag.id_pag}</span>
          
          <Link to={UrlPage.PAG_INDEX} className="linkBack">
            <BackwardFilled/>
            Regresar
          </Link>
        </h2>
      
      </Col>
    </Row>
    
    <div style={{paddingRight: "5px"}}>
      {paramPag}
    </div>
    
    <div  style={{paddingBottom:"20px"}}>
      <table className="tableDataForm fix">
        <tbody>
        <tr>
          <th>
            Contenido
          </th>
          <td/>
        </tr>
        </tbody>
      </table>
  
      <PanContenidoPag listaContenido={listaContenido}
                       id_pag={idPagina}
                       onListaContenidoUpdated={onListaContenidoUpdated}
      />

    </div>
  
  
  </>);
  
}