import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import { Col, Row} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import VConfigFiltroParam from "./tablaVConfig/VConfigFiltroParam";
import CmdVConfigRow from "./tablaVConfig/CmdVConfigRow";
import ATabla from "../../../components/tabla/ATabla";


import CmdVConfigIndex from "./tablaVConfig/CmdVConfigIndex";
import FnCopyXlsVConfig from "./tablaVConfig/FnCopyXlsVConfig";



export default () => {
  const history = useHistory();
  
  const [listaVConfig, setListaVConfig] = useState([]);
  
  const [filtroVConfig, setFiltroVConfig] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaVConfigFiltrado, setListaVConfigFiltrado] = useState([]);
  
  const [idSelected, setIdSelected] = useState(DataStore.VConfigIndex.GetIdSelected());
  
  
  useEffect(() => {
    (async () => {
  
      //TODO  solicitar al api las variables de configuracion
      const respuesta = await DataService.Admin.VConfig.Index();
      
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaVConfig(lista);
        DataStore.PagFormIndex.Set(lista);
        LibShowNotificacion.Success("Variables", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }

    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaVConfig.filter(form => {
      return VConfigFiltroParam.fn(form, filtroVConfig.current, filtroVConfig.valor)
    });
    setListaVConfigFiltrado(lista);
    
  }, [listaVConfig, filtroVConfig]);
  
  
  const onCmdRowEnd = async (operacion, varconfig) => {
    
    const { var_name} = varconfig;
    
    if (operacion === "update") {
      setIdSelected(var_name);
      history.push(UrlPage.ADMIN_VAR_CONFIG_EDIT + `/${var_name}`);
    }
    
  };
  
  
  const listaElem = listaVConfigFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.varname ? " rowSelected" : "");
    
    return (
      <Row key={index} className={rowCSS}>
        
        <Col xs={24} sm={3} md={1} title={k.var_name}>
          <div className="">
            <div className={"wrapperCmdEndRowID"}>
              <CmdVConfigRow item={k} onCmdEnd={onCmdRowEnd}/>
            </div>
          </div>
        </Col>
  
        
        <Col xs={24} sm={9} md={9}>
          <strong>{k.titulo}</strong>
        </Col>
  
        <Col xs={24} sm={3} md={3}>
          {k.tipo}
        </Col>
  
  
        <Col xs={24} sm={3} md={3}>
          {k.seccion}
        </Col>
  
        <Col xs={24} sm={8} md={8}>
          {k.comentario_html}
        </Col>

      </Row>
    );
  });
  
  
  const header = (
    <Row className="rowHeader">
      <Col xs={24} sm={1} md={1}>
      </Col>
      <Col xs={24} sm={9} md={9}>
        Título
      </Col>
      <Col xs={24} sm={3} md={3}>
        Tipo
      </Col>
      <Col xs={24} sm={3} md={3}>
        Sección
      </Col>
      <Col xs={24} sm={8} md={8}>
        Comentario
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    
    if (operacion === "add") {
      DataStore.VConfigIndex.SetIdSelected(null);
      history.push(UrlPage.ADMIN_VAR_CONFIG_ADD)
    
    } else if (operacion === "copiar_xls") {
      FnCopyXlsVConfig(listaVConfigFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
    
  };
  const getCmdIndex = () => <CmdVConfigIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaVConfigFiltrado.length}
      modalDelete={null}
      cmdIndex={getCmdIndex()}
      tituloModel="Variables de Configuración"
      filtroConfig={VConfigFiltroParam.config}
      setFiltro={setFiltroVConfig}
      filtro={filtroVConfig}
    />
  );
  
}