import React from "react";
import Button from "antd/es/button";
import {EditFilled, UploadOutlined} from "@ant-design/icons";
import DeleteFilled from "@ant-design/icons/lib/icons/DeleteFilled";

/***
 *
 * @param indexItem
 * @param codigo
 * @param onClickSave
 * @param setIsModoEdit
 * @param isEdit
 * @param onClickDelete
 * @param subtitulo
 * @returns {*}
 */
export default ({indexItem, id,codigo, onClickSave, setIsModoEdit, isEdit, onClickDelete, subtitulo}) => {
  
  let cmd = null;
  if (isEdit) {
    
    cmd = (<>
      <Button type="primary" className="botonsave"
              onClick={() => onClickSave()}
      >
        <UploadOutlined/>
        Guardar
      </Button>
      <Button onClick={() => setIsModoEdit(false)}>
        Cancelar
      </Button>
    </>);
    
  } else {
    cmd = (
      <Button type="primary" className="botonsave"
              onClick={() => setIsModoEdit(true)}
      >
        <EditFilled/>
        Editar
      </Button>
    );
    
  }
  
  let cmdDelete = (indexItem > 0 && isEdit === true)
    ? <Button type="dashed" className="mR5" danger title="Eliminar Elemento"
              onClick={onClickDelete}><DeleteFilled/></Button>
    : null;
  
  
  return (
    <div className="panTB1">
      {cmdDelete}
      <span style={{flexGrow: "1", fontWeight: "bold"}}>
            <span style={{marginLeft:"3px", marginRight:"15px"}}>{subtitulo}</span>
            <span title="Código" style={{color:"#aaa"}}>[ {codigo} - ID: {id}]</span>
      </span>
      {cmd}
    </div>
  
  
  );
  
};