const LibFormat = {
  
  Money: (amount, decimalCount = 0) => {
    const decimal = ".";
    const thousands = ",";
    
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
      const negativeSign = amount < 0 ? "-" : "";
      
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
      
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      
    } catch (e) {
      return amount;
    }
  },
  CantidadEnTexto : (x, isMoneda = true) => {
    
    if (x === 0) {
      return 'cero';
    }
    
    if (x === 1) {
      return 'uno';
    }
    
    const dicTextoUnidades = ['cero', 'un', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const dicTexto11_19 = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciseis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const dicTextoDecenas = ['no aplica', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const dicTextoCentenas = ['no aplica', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];
    
    const getTextoCentenas = (centenas, decenas, unidades) => {
      if (centenas === 0) {
        return '';
      }
      
      if (centenas === 1) {
        
        if (decenas === 0 && unidades === 0) {
          return 'cien';
        } else {
          return 'ciento';
        }
      }
      
      return dicTextoCentenas[centenas];
    }
    const getTextoDecenas = (decenas) => {
      return dicTextoDecenas[decenas];
    }
    const getTextoUnidades = (unidades) => {
      return dicTextoUnidades[unidades];
    }
    const getTextoDecyUni = (decenas, unidades) => {
      
      //caso <10
      if (decenas === 0) {
        return getTextoUnidades(unidades);
      }
      // caso puras decenas
      if (unidades === 0) {
        return getTextoDecenas(decenas);
      }
      
      //caso 11-19
      if (decenas === 1) {
        return dicTexto11_19[unidades];
      }
      
      //caso 21-29
      if (decenas === 2) {
        return 'veinti' + getTextoUnidades(unidades);
      }
      
      //caso general
      
      return getTextoDecenas(decenas) + ' y ' + getTextoUnidades(unidades);
      
    }
    const obtenerTexto = (x) => {
      if (x === 0) {
        return '';
      }
      
      let code = x.toString().padStart(3, '0');
      let qCentenas = parseInt(code[0]);
      let qDecenas = parseInt(code[1]);
      let qUnidades = parseInt(code[2]);
      
      let sCent = getTextoCentenas(qCentenas, qDecenas, qUnidades);
      let sDecyUnid = getTextoDecyUni(qDecenas, qUnidades);
      return `${sCent} ${sDecyUnid}`.trim();
    };
    
    let qUnidad = Math.floor(x % 1000)
    let qMillar = Math.floor(x - qUnidad) / 1000;
    let qPesos = qMillar * 1000 + qUnidad;
    let qCentavos = Math.floor((x - qPesos) * 1000) / 10;
    
    let textoM = obtenerTexto(qMillar);
    let textoU = obtenerTexto(qUnidad);
    
    if (textoU === '') {
      textoU = 'cero';
    }
    
    if (qMillar > 0) {
      if (textoM === 'uno') {
        textoM = 'mil'
      } else {
        textoM = textoM + ' mil';
      }
    }
    
    if (!isMoneda) {
      const t = `${textoM} ${textoU}`.trim();
      return t;
    }
    
    const tPesos = `${textoM} ${textoU}`.trim() + ' Pesos';
    const tCentavos = `${qCentavos}/100`;
    
    return `${tPesos} ${tCentavos} M.N.`;
  } ,
  Phone: (numero) => {
    
    const numeroTexto = numero.toString();
    if (numeroTexto.length < 10) {
      return numero.toString();
    }
    
    if (numeroTexto.length === 13) {
      return LibFormat.Phone(numeroTexto.substring(3));
    }
    
    const texto = numeroTexto.split('');
    
    if (texto[0] === "5") {
      return `${texto[0]}${texto[1]}-${texto[2]}${texto[3]}${texto[4]}${texto[5]}-${texto[6]}${texto[7]}${texto[8]}${texto[9]}`;
    } else {
      return `${texto[0]}${texto[1]}${texto[2]}-${texto[3]}${texto[4]}${texto[5]}-${texto[6]}${texto[7]}${texto[8]}${texto[9]}`;
    }
    
  }
  
};

export default LibFormat;
