import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Col, Row} from "antd";
import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";

import DataService from "../../../service/data/DataService";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";

import {BackwardFilled} from "@ant-design/icons";
import FormFormulario from "./FormFormulario";
import InfoFormulario from "./VistaFormulario";
import UnorderedListOutlined from "@ant-design/icons/lib/icons/UnorderedListOutlined";
import EstructuraFormulario from "./EstructuraFormulario";


export default () => {
  
  const history = useHistory();
  const idPagForm = useParams().idPagForm || null;
  const [isModoEdit, setIsModoEdit] = useState(false)
  const [pagForm, setPagForm] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  useEffect(() => {
    
    (async () => {
      
      if (idPagForm) {
        const respuesta = await DataService.Admin.PagForm.Ver(idPagForm);
        
        if (!respuesta.success) {
          FlashService.Registrar.Alert("Error al Ver " + idPagForm, respuesta.msg);
          return;
        }
        
        setPagForm(respuesta.data.pagForm);
        
        
      } else {
        setPagForm({nombre: '', email: '', estructura: null, comentario: ''})
      }
      setIsLoaded(true);
      
      
    })();
    
  }, [idPagForm]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onCamposSaved = (newForm) => {
    
    setPagForm({...newForm, estructura: pagForm.estructura});
    
    const id = pagForm.id_pag_form;
    
    const titulo = `Actualización de Formulario ${id} `;
    FlashService.Registrar.Info(titulo, pagForm.nombre);
    const urlIndex = UrlPage.ADMIN_FORM_INDEX
    history.push(urlIndex);
  };
  
  
  const tituloPagina = (
    <div className="tituloPagUpdate">
      <UnorderedListOutlined/>
      <span>Formulario</span>
      <Link to={UrlPage.ADMIN_FORM_INDEX} className="linkBack">
        <BackwardFilled/>
        Regresar
      </Link>
    </div>
  );
  
  const formFormulario = isModoEdit
    ? <FormFormulario pagForm={pagForm} onFormularioSaved={onCamposSaved} onSetModoVista={() => setIsModoEdit(false)}/>
    : null;
  
  const infoForm = isModoEdit
    ? null
    : <InfoFormulario pagForm={pagForm} onSetModoEdit={() => setIsModoEdit(true)}/>;
  
  
  const onEstructuraUpdated = (estructuraNew) => {
    const estructura = JSON.stringify(estructuraNew);
    
    const newForm = {...pagForm, estructura}
    
    setPagForm(newForm);
    
    
    
    
  };
  
  return (<>
    <Row style={{width: "100%"}}>
      <Col style={{width: "100%"}}>
        {tituloPagina}
      </Col>
    </Row>
    
    {formFormulario}
    {infoForm}
    
    <EstructuraFormulario
      pagForm={pagForm}
      onEstructuraUpdated={onEstructuraUpdated}/>
  
  
  </>);
  
}