import React, {useEffect, useState} from 'react';
import {Button,  Checkbox, Col, DatePicker, Form, Input, Row} from "antd";
import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import FlashService from "../../../app/FlashService";
import moment from 'moment';

const FormItem = Form.Item;
const {TextArea} = Input;


export default ({recordatorio, onRecSaved, onSetModoRead}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      nombre_evento: recordatorio.nombre_evento,
      fecha_ini: moment(recordatorio.fecha_ini),
      fecha_fin: moment(recordatorio.fecha_fin),
      key_img: recordatorio.key_img,
      key_img_movil: recordatorio.key_img_movil,
      is_activo: recordatorio.is_activo,
      slider: recordatorio.slider,
      bodyHtml: recordatorio.bodyHtml,
      comentario: recordatorio.comentario,
    });
    
  }, [recordatorio, form]);
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    let respuesta = await DataService.Editor.Recordatorio.Update(recordatorio.id_recordatorio, formData);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    FlashService.Registrar.Alert(recordatorio.nombre_evento, "Actualizada");
    
    onRecSaved(recordatorio);
    setIsEnProceso(false);
  };
  
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelCorto}
      onFinish={onSubmitForm}
    >
      <Row>
        
        <Col xs={24} sm={24} md={24}>
          
          <FormItem
            label="Recordatorio Activo"
            name="is_activo"
            valuePropName="checked"
            help="Solo los recordatorios activos seran tomados en cuenta para mostrarse."
            rules={[{required: false}]} hasFeedback>
            
            <Checkbox/>
          
          </FormItem>
          
          <FormItem
            label="Nombre"
            name="nombre_evento"
            help="Nombre del evento a promocionar"
            rules={[{required: true}]} hasFeedback>
            
            <Input/>
          
          </FormItem>
          
          <FormItem
            label="Fecha inicio"
            name="fecha_ini"
            help="Fecha en que comienza a mostrarse el banner"
            rules={[{required: true}]} hasFeedback>
            
            <DatePicker format='DD/MM/YYYY'/>
          
          </FormItem>
          
          
          <FormItem
            label="Fecha objetivo"
            name="fecha_fin"
            rules={[{required: true}]} hasFeedback
            help="La fecha en que se termina de mostrar el banner (Sería la fecha del evento)"
          >
            
            <DatePicker format='DD/MM/YYYY'/>
          
          </FormItem>
          
          
          <FormItem
            label="Mensaje"
            name="bodyHtml"
            help="Texto del Banner (en caso de no usar imagenes)"
            rules={[{required: false}]} hasFeedback>
            
            <TextArea/>
          
          </FormItem>
          
          <FormItem
            label="Comentario"
            name="comentario"
            rules={[{required: false}]} hasFeedback>
            <TextArea/>
          </FormItem>
        
        
        </Col>
      
      
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div style={{marginTop: "20px", textAlign: "center"}}>
            
            <Button
              style={{width: "180px", marginRight: "20px"}}
              onClick={() => onSetModoRead()}>
              <span>Cancelar</span>
            </Button>
            
            <Button type="primary"
                    htmlType="submit"
                    style={{width: "180px"}}
                    loading={isEsProceso} block>
              <CloudUploadOutlined/>
              <span>Guardar</span>
            </Button>
            
            <div className="msgError" style={{minHeight: "150px"}}>
              {msgError}
            </div>
          
          </div>
        </Col>
      </Row>
    
    </Form>
  
  );
}