import React from "react";
import {Col, Row} from "antd";


export default (props) => {
  
  const size = props.size || 2;
  
  if (!props.children) {
    return <Col xs={24} sm={2 * size} md={size}>ID</Col>;
  }
  
  
  return (
    <Col xs={24} sm={2 * size} md={size}>
      <div className="divMainRow">
        {props.children}
        <div className="wrapperID">
          <span className="labMob labMobT1">ID:</span>
          <span title="Id" className="labelID">{props.id}</span>
        </div>
      </div>
      {props.children2}
    </Col>
  );
};