import React, {useEffect, useState} from 'react';
import CLoading from "../../../../components/page/CLoading";
import HeaderElementoContenido from "./HeaderElementoContenido";
import CSinContenido from "./CSinContenido";
import './contenidoHtml.css'



export default ({itemContenido, indexItem, onItemUpdated,onShowDelElementModal}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [contenidoEditor, setContenidoEditor] = useState(null);
  
  
  useEffect(() => {
    setIsModoEdit(false);
    setIsLoaded(true);
    setContenidoEditor(itemContenido.body);
  }, [itemContenido]);
  
  
  if (!isLoaded) {
    return <CLoading mensaje="Loading Contenido"/>
  }
  
  /* Modo Edit  *********************************** **/
  
  if (isModoEdit) {
    
    
    const handleEditorChange = (e) => {
      setContenidoEditor(e.target.value);
    }
    
    const onClickSave = () => {
      
      const cbOnFinishUpdate = (isSuccess) => {
        if (isSuccess === true) {
          setIsModoEdit(false)
        }
      };
      onItemUpdated(contenidoEditor, indexItem, cbOnFinishUpdate);
    }
    
    return (
      <div className="wrapperEditorHtml">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          onClickSave={onClickSave}
          subtitulo = "Texto HTML Raw"
          onClickDelete={()=>onShowDelElementModal(indexItem)}
          setIsModoEdit={setIsModoEdit}
          isEdit={true}
          id={itemContenido.id_pag_contenido||''}
          codigo={itemContenido.codigo||''}
        />
  
        <div style={{width:"100%", textAlign:"justify", display:"inline-block"}}>
          <textarea
            value={contenidoEditor || ''}
            onChange={handleEditorChange}
            style={{height:"400px", width:"100%"}}
          />
        </div>
        
        
      </div>
    );
  }
  
  /* Modo Lectura ********************************* **/
  
  let fnRenderBase = (body) => {
    
    if (body) {
      return (
        <div className="wrapperItemContenido">
  
          <HeaderElementoContenido
            indexItem={indexItem}
            setIsModoEdit={setIsModoEdit}
            subtitulo = "Texto HTML Raw"
            isEdit={false}
            id={itemContenido.id_pag_contenido||''}
            codigo={itemContenido.codigo||''}
          />
          
          <div style={{textAlign:"center" }}>
            <div style={{maxWidth:"700px", "width":"100%", textAlign:"left",  display:"inline-block"}}>
              <pre>
                {body}
              </pre>
            </div>
            
          </div>
        </div>
      );
    } else {
  
      return (
        <CSinContenido id_pag_contenido={itemContenido.id_pag_contenido}
                       setIsModoEdit={setIsModoEdit}
                       indexItem={indexItem}
                       subtitulo="Sin Html"
        />
      );
  
    }
    
  }
  
  return fnRenderBase(itemContenido.body);
  
}