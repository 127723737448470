import React from "react";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import ACmdRow from "../../../../components/tabla/ACmdRow";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({ prom, onCmdEnd}) => {
  
  
  const listaOpciones = [
    {key: "edit", icon: <EditFilled/>, label: "Editar", style: {color: "green"}},
    {isDivider: true},
    {key: "delete", icon: <DeleteOutlined/>, label: "Eliminar", style: {color: "red"}},
  ];
  
  const tituloModal = (
    <>
      <span>Promoción </span>
      {/*<strong style={{color: "green"}}>{item.freg}</strong>*/}
    </>
  );
  
  return (
    <ACmdRow
      listaOpciones={listaOpciones}
      model={prom}
      onCmdEnd={onCmdEnd}
      btnTipo="primary"
      titulo={tituloModal}
    />
  );
  
}