import React, {useState} from 'react';
import ImgItem from "./ImgItem";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";
import ItemFotoModalDelete from "./ItemFotoModalDelete";
import Dropzone from "react-dropzone";
import CLoading from "../../../../components/page/CLoading";


/***
 * Administra las iamgenes desde un s3
 * @param listaUrlImg
 * @param onListaImgUpdated
 * @param idItem
 * @param urlImgPrincipal
 * @param onChangeUrlPrincipal
 * @returns {*}
 */
export default ({listaUrlImg, onListaImgUpdated, idItem, urlImgPrincipal, onChangeUrlPrincipal}) => {
  
  
  const [isEnProceso, setIsEnProceso] = useState(false);
  const [urlShowModalDelete, setUrlShowModalDelete] = useState(null);
  
  const onDeleteImg = async (url) => {
    
    if (url === false) {
      setUrlShowModalDelete(null);
      setIsEnProceso(false);
      return null;
    }
  
    
    if (isEnProceso) {
      return
    }
    
    setIsEnProceso(true);
    
    
    //hacer el delete
    const respuesta = await DataService.Editor.Item.FotoItem.Delete(idItem, url);
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Eliminar", respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    const listaUrlNew = listaUrlImg.filter(k => k !== url);
    onListaImgUpdated(listaUrlNew);
    
    setUrlShowModalDelete(null);
    setIsEnProceso(false);
    LibShowNotificacion.Success("Imagen Eliminada");
    
  }
  
  const onSetPrincipal = async (url) => {
    
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    const r = await DataService.Editor.Item.FotoItem.SetPrincipal(idItem, url);
    if (!r.success) {
      LibShowNotificacion.Alert("Error", r.msg);
      setIsEnProceso(false);
      return;
    }
    
    setIsEnProceso(false);
    onChangeUrlPrincipal(url);
    
  };
  
  const onShowModalDeleteImg = (url) => {
    console.log('xxxxxxxx')
    if(!url){
      setIsEnProceso(false);
      return;
    }
    setUrlShowModalDelete(url);
  };
  
  const onChangeFile = async (event) => {
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    const inputObject = event.target;
    
    let fileObject = inputObject.files[0];
    let fileTipo = fileObject.type; //image/jpeg
    
    
    const respuesta = await DataService.Editor.Item.FotoItem.getUploadS3Url(fileTipo, idItem, fileObject.name);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Subir Archivo", respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    const {urlPut, key, urlImg} = respuesta.data;
    
    
    const respuestaS3 = await DataService.putToS3(urlPut, fileObject);
    
    
    if (typeof respuestaS3 === "object") {
      LibShowNotificacion.Alert("Error AWS", JSON.stringify(respuestaS3.msg));
      setIsEnProceso(false);
      return;
    }
    
    /* actualziar registro con el nuevo key */
    const respuestaUpdate = await DataService.Editor.Item.FotoItem.AddFoto(idItem, key);
    if (!respuestaUpdate.success) {
      LibShowNotificacion.Alert("Error al Subir Archivo", respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    //eliminar la seleccion de archivo
    inputObject.value = null;
    
    //Actauliar la lista
    
    onListaImgUpdated([...listaUrlImg, urlImg]);
    setIsEnProceso(false);
  };
  
  
  const lista = listaUrlImg.map((url, index) => {
    
    return <ImgItem key={index}
                    url={url}
                    isPrincipal={url === urlImgPrincipal}
                    onShowModalDeleteImg={onShowModalDeleteImg}
                    onSetPrincipal={onSetPrincipal}/>
  });
  
  
  const modalDelete = urlShowModalDelete === null
    ? null
    : <ItemFotoModalDelete urlFoto={urlShowModalDelete}
                           onClickModalDelete={onDeleteImg} visible={true}/>;
  
  
  const onDrop = async (accepted, rejected) => {
    
    if (accepted.length === 0) {
      LibShowNotificacion.Alert("Error", "Tipo de Archivo Incorrecto");
    }
    
    const event = {
      target: {
        files: accepted
      }
    };
    
    await onChangeFile(event)
    
  };
  
  const msgEnProceso = isEnProceso
    ? <CLoading mensaje="Subiendo Archivo"/>
    : null;
  
  
  return (
    <>
      <h2 className="hasTextCenter">Imagenes</h2>
      <div style={{textAlign: "center", padding: "0px 20px 5px"}}>
        <Dropzone
          accept="image/*"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            border: " 2px dashed"
          }}
          multiple={false}
          onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: "dropzone"})}>
              <input {...getInputProps()}
                     type="file"
                     accept="image/*"
                     onChange={event => onChangeFile(event)}
              />
              <p>Arrastra el archivo JPG, PNG que deseas usar - que sea menor a 100 kb</p>
            </div>
          )}
        
        </Dropzone>
        {msgEnProceso}
      
      </div>
      
      
      <div className="panImg">
        {lista}
      </div>
      
      {modalDelete}
    </>
  
  );
};