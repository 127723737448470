import LibCopyXls from "../../../../lib/LibCopyXls";
import LibObject from "../../../../lib/LibObject";

export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'nombre', 'email', 'tel', 'tipo', 'sucursal', 'pass temp', 'id'];
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    const idUsuario = LibObject.getPropiedad(k, 'idUsuario');
    const nombre = LibObject.getPropiedad(k, 'nombre');
    const email = LibObject.getPropiedad(k, 'email');
    const tel = LibObject.getPropiedad(k, 'phone_number');
    const tipo = LibObject.getPropiedad(k, 'tipo');
    const sucursal = LibObject.getPropiedad(k, 'sucursales');
    const tempPass = LibObject.getPropiedad(k, 'tempPass');
    
    
    return [
      (indexk + 1), nombre, email, tel, tipo, sucursal, tempPass, idUsuario
    ];
  });
  LibCopyXls(listaHeader, listaBody);
}