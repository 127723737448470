import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import "../../../components/tabla/table.css";

import UrlPage from "../../UrlPage";
import FlashService from "../../../app/FlashService";
import CLoading from "../../../components/page/CLoading";


import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import DataStore from "../../../app/DataStore";
import FormPromocion from "./FormPromocion";
import * as LibFecha from "@iqdavidh/libfront/src/LibFecha";
import RowTitulo from "../../../components/page/RowTitulo";


export default () => {
  
  const history = useHistory();
  const {idPromocion} = useParams();
  
  const [promocion, setPromocion] = useState(null);
  
  const [isLoaded, setIsLoaded] = useState(false);
  
  const fNowYMD = LibFecha.getYMDFromDate(new Date());
  
  useEffect(() => {
    setPromocion({fecha_ini: fNowYMD, fecha_final: fNowYMD, body: ''});
    setIsLoaded(true);
  }, [idPromocion]);
  
  
  if (!isLoaded) {
    return (
      <CLoading/>
    );
  }
  
  
  const onPromNewSaved = (promNew) => {
    const id = promNew.id_promocion;
    DataStore.PromIndex.SetIdSelected(id);
    FlashService.Registrar.Info("Promoción Creada", promNew.titulo);
    const urlUpdate = UrlPage.PROMOCION_EDIT + `/${id}`;
    history.push(urlUpdate);
  };
  
  
  const tagTitulo = idPromocion
    ? <span><EditFilled/> Promoción <span className="resaltar">{idPromocion}</span></span>
    : <span className="resaltar">Nueva Promoción</span>
  ;
  
  
  return (<>
    
    <RowTitulo tagTitulo={tagTitulo} urlBack={UrlPage.PROMOCION_INDEX}/>
    
    <div style={{paddingRight: "5px"}}>
      <FormPromocion promocion={promocion}
                     onPromSaved={onPromNewSaved}
                     onSetModoRead={null}/>
    </div>
  
  
  </>);
  
}