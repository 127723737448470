import React from 'react';
import FormLogin from "./FormLogin";
import {Row, Col} from 'antd';
import {Helmet} from "react-helmet";
import AuthService from "../../service/auth/AuthService";
import UrlPage from "../UrlPage";
const build=require('../../buildmeta.json');

const PageLogin = () => {
  
  
  const onLoginSuccess = (payload) => {
    const publicData = payload.public_data;
    const token = payload.token;
    
    AuthService.registrarLogin(publicData, token, true)
  
    //Salir para reinicar el sitio
    window.location = UrlPage.HOME;
    return true;
  };
  
  
  return (
    <>
      <Helmet>
        <title>Tienda Telcel - Ingreso</title>
      </Helmet>
      <div className="pMaxT">
        <Row justify="center">
          <Col xs={24} sm={14} md={6} lg={8} xl={8}>
            <div className="has-text-centered mtopMax">
              <img src="https://tiendatelcel.com.mx/assets/img/logo/logo.png"
                   style={{width: "150px", marginTop: "30px", marginBottom: "20px"}}
                   alt="Tienda Telcel"/>

            </div>
            <FormLogin onLoginSuccess={onLoginSuccess}/>
            <p className="has-text-centered">
              <small>{build.version} B{build.num} | {build.fecha}</small>
            </p>
          </Col>
        </Row>
      
      </div>
    </>
  );
};

export default PageLogin