import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Page from "../page/Page";
import UrlPages from "../page/UrlPage";
import DataService from "../service/data/DataService";
import DataStore from "./DataStore";


import './css/app.css';
import './css/form.css';
import './css/text.css';


import W from "../components/page/WrapperCMain";
import CLoading from "../components/page/CLoading";
import CUsuarioIndex from "../page/adm/usuario/CUsuarioIndex";
import CUsuarioAdd from "../page/adm/usuario/CUsuarioAdd";
import CUsuarioUpdate from "../page/adm/usuario/CUsuarioUpdate";
import CActIndex from "../page/adm/act/CActIndex";
import CActVer from "../page/adm/act/CActVer";
import CItemIndex from "../page/editor/item/CItemIndex";
import CItemUpdate from "../page/editor/item/CItemUpdate";
import CPagIndex from "../page/editor/pag/CPagIndex";
import CPagUpdate from "../page/editor/pag/CPagUpdate";
import CPagFormIndex from "../page/adm/pagForm/CPagFormIndex";
import CPagFormUpdate from "../page/adm/pagForm/CPagFormUpdate";
import CPromIndex from "../page/editor/promocion/CPromIndex";
import CPromUpdate from "../page/editor/promocion/CPromUpdate";
import CVConfigIndex from "../page/adm/vconfig/CVConfigIndex";
import CVConfigUpdate from "../page/adm/vconfig/CVConfigUpdate";
import CHome from "../page/home/CHome";
import CActAdd from "../page/editor/act/CActCrear";
import CUpdateSitio from "../page/home/CUpdateSitio";
import CRecUpdate from "../page/editor/recordatorio/CRecUpdate";
import CRecIndex from "../page/editor/recordatorio/CRecIndex";
import CPromAdd from "../page/editor/promocion/CPromAdd";
import CItemAdd from "../page/editor/item/CItemAdd";
import CVentaIndex from "../page/adm/venta/CVentaIndex";
import CVentaUpdate from "../page/adm/venta/CVentaUpdate";
import CMcIndex from "../page/adm/mc/CMcIndex";
import CItemPromIndex from "../page/editor/itemprom/CItemPromIndex";
import CRecAdd from "../page/editor/recordatorio/CRecAdd";
import CCostoEnvioIndex from "../page/adm/costo_envio/CCostoEnvioIndex";
import AuthService from "../service/auth/AuthService";
import PageSetPass from "../page/pageLogin/PageSetPass";
import PageLogin from "../page/pageLogin/PageLogin";
import PageSalir from "../page/pageLogin/PageSalir";

const isDesktop = window.innerWidth > 800;
DataStore.IsDesktop.Set(isDesktop);


function App() {
  
  const [stateApp, setStateApp] = useState({isLoaded: false, isAuthenticated: false});
  
  
  useEffect(() => {
    
    (async () => {
      
      //Paso 1 -buiscar la auitenticacion desde lcoalstorage
      const isExisteToken = AuthService.getIsAuthenticated()
      
      if (isExisteToken) {
        const jwt = AuthService.getToken();
        
        console.log("************** jwt", {jwt}, "***************");
        
        const respuesta = await DataService.DataIni();
        if (respuesta.success) {
          DataStore.Inicilizar(respuesta.data);
          AuthService.setToken(respuesta.data.token);
          setStateApp({isLoaded: true, isAuthenticated: true})
        } else {
          //Borrar token y salir
          AuthService.setLogOut();
        }
      } else {
        setStateApp({isLoaded: true, isAuthenticated: false})
      }
      
      
    })();
    
  }, []);
  
  if (!stateApp.isLoaded) {
    return (
      <Page><CLoading/></Page>
    );
  }
  
  
  if (!stateApp.isAuthenticated) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={UrlPages.LOGIN + '/:tokenRecuperacion'} exact
                 render={() => <PageSetPass/>}/>
          <Route path={UrlPages.LOGIN}
                 render={() => <PageLogin/>}/>
          <Route path="/"
                 render={() => <PageLogin/>}/>
        </Switch>
      </BrowserRouter>
    );
  }
  
  
  return (
    <BrowserRouter>
      
      <Page>
        <>
          <Switch>
            <Route path={UrlPages.SALIR} exact render={() => <PageSalir/>}
            />
            
            {/***** Rutas admin *********************************** */}
            <Route path={UrlPages.ADMIN_USUARIO_INDEX}
                   exact
                   render={() => <W titulo="Lista de usuarios"><CUsuarioIndex/></W>}
            />
            
            <Route path={UrlPages.ADMIN_USUARIO_ADD}
                   exact
                   render={() => <W titulo="Agregar usuarios"><CUsuarioAdd/></W>}
            />
            
            <Route path={UrlPages.ADMIN_USUARIO_EDIT + '/:idUsuario'}
                   render={() => <W titulo="Editar Usuario"><CUsuarioUpdate/></W>}
            />
            
            {/** Act **/}
            <Route path={UrlPages.ADMIN_ACTUALIZACION_INDEX}
                   exact
                   render={() => <W titulo="Lista de Actualizaciones"><CActIndex/></W>}
            />
            
            <Route path={UrlPages.ADMIN_ACTUALIZACION_VER + '/:idAct'}
                   exact
                   render={() => <W titulo="Ver Actualización"><CActVer/></W>}
            />
            
            
            {/** Formulario **/}
            <Route path={UrlPages.ADMIN_FORM_INDEX}
                   exact
                   render={() => <W titulo="Lista de Formularios"><CPagFormIndex/></W>}
            />
            
            <Route path={UrlPages.ADMIN_FORM_EDIT + '/:idPagForm'}
                   exact
                   render={() => <W titulo="Editar Formulario"><CPagFormUpdate/></W>}
            />
            
            <Route path={UrlPages.ADMIN_FORM_ADD}
                   exact
                   render={() => <W titulo="Agregar Formulario"><CPagFormUpdate/></W>}
            />
            
            {/** Variables de configuracion ******************************** **/}
            <Route path={UrlPages.ADMIN_VAR_CONFIG_INDEX}
                   exact
                   render={() => <W titulo="Variables de configuración"><CVConfigIndex/></W>}
            />
            
            <Route path={UrlPages.ADMIN_VAR_CONFIG_EDIT + '/:var_name'}
                   exact
                   render={() => <W titulo="Editar Variable"><CVConfigUpdate/></W>}
            />
            
            <Route path={UrlPages.ADMIN_VAR_CONFIG_ADD}
                   exact
                   render={() => <W titulo="Agregar Variable"><CVConfigUpdate/></W>}
            />
            
            <Route path={UrlPages.ADMIN_COSTO_ENVIO}
                   exact
                   render={() => <W titulo="Actualizar costos de envio"><CCostoEnvioIndex/></W>}
            />
            
            {/*venta ****************************************************** */}
            
            <Route path={UrlPages.VENTAS_INDEX}
                   exact
                   render={() => <W titulo="Lista de Ventas"><CVentaIndex/></W>}
            />
            
            <Route path={UrlPages.VENTAS_VER + '/:idVenta'}
                   exact
                   render={() => <W titulo="Venta"><CVentaUpdate/></W>}
            />
            
            {/*item ****************************************************** */}
            
            <Route path={UrlPages.ITEM_INDEX}
                   exact
                   render={() => <W titulo="Lista de Items"><CItemIndex/></W>}
            />
            
            <Route path={UrlPages.ITEM_EDIT + '/:idItem'}
                   exact
                   render={() => <W titulo="Actualizar Item"><CItemUpdate/></W>}
            />
            
            <Route path={UrlPages.ITEM_ADD}
                   exact
                   render={() => <W titulo="Agregar Item"><CItemAdd/></W>}
            />
            
            {/*item prom ****************************************************** */}
            
            <Route path={UrlPages.ITEMPROM_INDEX}
                   exact
                   render={() => <W titulo="Lista de Items Promocionados en Recomendación"><CItemPromIndex/></W>}
            />
            
            
            {/* pag ****************************************************** */}
            
            <Route path={UrlPages.PAG_INDEX}
                   exact
                   render={() => <W titulo="Lista de Páginas"><CPagIndex/></W>}
            />
            
            <Route path={UrlPages.PAG_EDIT + '/:idPagina'}
                   exact
                   render={() => <W titulo="Actualizar Página"><CPagUpdate/></W>}
            />
            
            
            {/** Promocion ***************************************************** **/}
            
            <Route path={UrlPages.PROMOCION_INDEX}
                   exact
                   render={() => <W titulo="Lista de Promociones"><CPromIndex/></W>}
            />
            
            <Route path={UrlPages.PROMOCION_EDIT + '/:idPromocion'}
                   exact
                   render={() => <W titulo="Editar Promoción"><CPromUpdate/></W>}
            />
            
            <Route path={UrlPages.PROMOCION_ADD}
                   exact
                   render={() => <W titulo="Agregar Promocion"><CPromAdd/></W>}
            />
            
            {/** Recordatorio ***************************************************** **/}
            
            <Route path={UrlPages.RECORDATORIO_EVENTO_INDEX}
                   exact
                   render={() => <W titulo="Lista de Recordatorios de Eventos"><CRecIndex/></W>}
            />
            
            <Route path={UrlPages.RECORDATORIO_EVENTO_EDIT + '/:idRecordatorio'}
                   exact
                   render={() => <W titulo="Editar Recordatorio de Evento"><CRecUpdate/></W>}
            />
            
            <Route path={UrlPages.RECORDATORIO_EVENTO_ADD}
                   exact
                   render={() => <W titulo="Agregar Recodatorio de Evento"><CRecAdd/></W>}
            />
            
            {/*Mc ************************************************ */}
            <Route path={UrlPages.MC_INDEX}
                   exact
                   render={() => <W titulo="Mensajes de clientes"><CMcIndex/></W>}
            />
            
            
            {/*Home ******************************************** */}
            <Route path={UrlPages.ACTUALIZACION_ADD}
                   exact
                   render={() => <W titulo="Actualizar Items a la Venta y Precios"><CActAdd/></W>}
            />
            
            <Route path={UrlPages.ACTUALIZAR_SITIO}
                   exact
                   render={() => <W titulo="Actualizar Elementos del Sitio (Template)"><CUpdateSitio/></W>}
            />
            
            
            <Route path={UrlPages.HOME}
                   render={() => <W titulo="Inicio"><CHome/></W>}/>
            
            
            <Route render={() => <p>Ruta incorrecta</p>}/>
          </Switch>
        
        </>
      </Page>
    </BrowserRouter>
  );
  
  
}

export default App;
