import React from 'react';
import {Card, Col, Row} from "antd";
import Meta from "antd/es/card/Meta";
import UrlPage from "../UrlPage";
import {Link} from "react-router-dom";
import DataSVG from "../../app/icons/DataSVG";
import './chome.css';

export default (props) => {
  
  const listaElementos = [
    {urlLink: UrlPage.ACTUALIZACION_ADD, titulo: 'Actualizar Precios', urlImg: DataSVG.Actualizar},
    {urlLink: UrlPage.ITEM_INDEX, titulo: 'Actualizar Fotos y Descripción', urlImg: DataSVG.FotosItems},
    {urlLink: UrlPage.VENTAS_INDEX, titulo: 'Ventas', urlImg: DataSVG.Ventas},
    {urlLink: UrlPage.ACTUALIZAR_SITIO, titulo: 'Actualizar Plantilla', urlImg: DataSVG.ActualizarSitio},
  
  ];
  
  const lista = listaElementos.map(item => {
    
    return (
      <Col xs={24} sm={24} md={8}>
        <div className="wrapperIcon">
          <Link to={item.urlLink}>
            <Card
              hoverable
              style={{width: "100%"}}
              cover={<img alt="" src={item.urlImg}/>}
            >
              <Meta title={item.titulo} description={item.desc}/>
            </Card>
          </Link>
        </div>
      
      </Col>
    );
  });
  
  return (<>
    <Row>
      {lista}
    </Row>
  </>)
  
}