export default {
  config: [
    {
      campo: "",
      tipo: "nofiltro",
      label: "- Sin Filtrar Datos -"
    },
    {
      campo: "",
      tipo: "texto",
      label: "Buscar Texto"
    },
    {
      campo: 'seccion',
      tipo: "cbx",
      label: "Buscar por sección",
      opciones: [
        {key: "", label: "- Sin Filtro -"},
        {key: "main", label: "Plantilla General del Sitio"},
        {key: "store", label: "Venta en Línea"}
      ]
    }
  ],
  fn: (item, filtroNombre, filtroValor) => {
    
    if (filtroNombre === "- Sin Filtrar Datos -" || filtroValor==="") {
      return true;
    }
    
    const valorNom = filtroValor.trim().toLowerCase();
    let isIncluir = true;
    
    
    //------------------------------------------------------
    if (isIncluir && filtroNombre === "Buscar Texto") {
      if (valorNom !== "") {
        isIncluir = `${item.nombre}|${item.email}`.toLowerCase().includes(valorNom);
      }
    } else     if (filtroNombre === "Buscar por sección" && item.seccion !== filtroValor) {
      isIncluir = false;
    }
    
    return isIncluir
  }
}
;

