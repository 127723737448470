import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row} from "antd";
import LibFormAnt from "../../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";
import FlashService from "../../../../app/FlashService";


const FormItem = Form.Item;


export default ({onSetModoRead, pag_contenido, onSaved}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  
  const [form] = Form.useForm();
  
  useEffect(() => {
    
    form.setFieldsValue({
      body: pag_contenido.body,
    });
    
  }, [pag_contenido, form]);
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    const id = pag_contenido.id_pag_contenido;
    const body = formData.body;
    
    let respuesta = await DataService.Editor.PagContenido.UpdateBody(id, body);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setMsgError(respuesta.msg);
      setIsEnProceso(false);
      return;
    }
    
    FlashService.Registrar.Alert("Contenido", "Actualizada");
    
    onSaved(body);
    setIsEnProceso(false);
    onSetModoRead();
  };
  
  
  return (
    
    <Form
      form={form}
      validateMessages={LibFormAnt.ConfigMsgValidacion}
      {...LibFormAnt.LayOutLabelCorto}
      onFinish={onSubmitForm}
    >
      <Row>
        <Col xs={24} sm={24} md={24}>
          <FormItem
            label="Url"
            name="body"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
        </Col>
      </Row>
      
      <Row>
        <Col xs={24} sm={24} md={24}>
          <div style={{marginTop: "20px", textAlign: "center"}}>
            
            <Button
              style={{width: "180px", marginRight: "20px"}}
              onClick={() => onSetModoRead()}>
              <span>Cancelar</span>
            </Button>
            
            <Button type="primary"
                    htmlType="submit"
                    style={{width: "180px"}}
                    loading={isEsProceso} block>
              <CloudUploadOutlined/>
              <span>Guardar</span>
            </Button>
            
            <div className="msgError" style={{minHeight: "150px"}}>
              {msgError}
            </div>
          
          </div>
        </Col>
      </Row>
    
    </Form>
  
  );
}