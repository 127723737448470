const LibEdo = {
  getLista: () => [
    {id: null, label: "- Seleccionar -"},
    {id: "ags", label: "Aguascalientes"},
    {id: "bc", label: "Baja California"},
    {id: "bcs", label: "Baja California Sur"},
    {id: "cam", label: "Campeche"},
    {id: "cdmx", label: "Ciudad de México"},
    {id: "chi", label: "Chihuahua"},
    {id: "chp", label: "Chiapas"},
    {id: "coa", label: "Coahuila"},
    {id: "col", label: "Colima"},
    {id: "dur", label: "Durango"},
    {id: "gro", label: "Guerrero"},
    {id: "gto", label: "Guanajuato"},
    {id: "hgo", label: "Hidalgo"},
    {id: "jal", label: "Jalisco"},
    {id: "mex", label: "Estado de México"},
    {id: "mic", label: "Michoacán"},
    {id: "mor", label: "Morelos"},
    {id: "nay", label: "Nayarit"},
    {id: "nl", label: "Nuevo León"},
    {id: "oax", label: "Oaxaca"},
    {id: "pue", label: "Puebla"},
    {id: "qro", label: "Querétaro"},
    {id: "roo", label: "Quintana Roo"},
    {id: "sin", label: "Sinaloa"},
    {id: "slp", label: "San Luis Potosí"},
    {id: "son", label: "Sonora"},
    {id: "tab", label: "Tabasco"},
    {id: "tam", label: "Tamaulipas"},
    {id: "tlx", label: "Tlaxcala"},
    {id: "ver", label: "Veracruz"},
    {id: "yuc", label: "Yucatán"},
    {id: "zac", label: "Zacatecas"}
  ],

  ags: "Aguascalientes",
  bc: "Baja California",
  bcs: "Baja California Sur",
  cam: "Campeche",
  cdmx: "Ciudad de México",
  chi: "Chihuahua",
  chp: "Chiapas",
  coa: "Coahuila",
  col: "Colima",
  dur: "Durango",
  gro: "Guerrero",
  gto: "Guanajuato",
  hgo: "Hidalgo",
  jal: "Jalisco",
  mex: "Estado de México",
  mic: "Michoacán",
  mor: "Morelos",
  nay: "Nayarit",
  nl: "Nuevo León",
  oax: "Oaxaca",
  pue: "Puebla",
  qro: "Querétaro",
  roo: "Quintana Roo",
  sin: "Sinaloa",
  slp: "San Luis Potosí",
  son: "Sonora",
  tab: "Tabasco",
  tam: "Tamaulipas",
  tlx: "Tlaxcala",
  ver: "Veracruz",
  yuc: "Yucatán",
  zac: "Zacatecas"
};

export default  LibEdo;