import ConfigApp from "../../../AppConfig";
import LibLocalStorage from "../../../lib/LibLocalStorage";

const libfront = require('@iqdavidh/libfront');

const nombreHeader = ConfigApp.nombreHeader;

const factoryH = async () => {
  const headerApi = {};
  headerApi[nombreHeader] = LibLocalStorage.getTokenIdentidad();
  return Promise.resolve(headerApi);
};

const ApiReqJson = libfront.FactoryReqJson(factoryH);

ApiReqJson.setIsDebug(ConfigApp.isApiLocal || ConfigApp.isDebug);

export default ApiReqJson;
