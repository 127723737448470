/***
 *
 * @param rawReqJson
 * @param urlBase
 * @returns {{pruebadb: (function(): *|{msg: *, success: boolean}|undefined), post: (function(*=): *|{msg: *, success: boolean}), get_con_params: (function(*=): *|{msg: *, success: boolean}|undefined), get: (function(): *|{msg: *, success: boolean}|undefined), get_ruta_protected: (function(): *|{msg: *, success: boolean}|undefined)}}
 */
const factoryApiPrueba = (rawReqJson, urlBase) => {
  
  return {
    get: async () => {
      const url = `${urlBase}/pruebaget`;
      return await rawReqJson.requestGET(url);
    },
    pruebadb: async () => {
      const url = `${urlBase}/pruebadb`;
      return await rawReqJson.requestGET(url);
    },
    get_con_params: async (param = 'testparam') => {
      const url = `${urlBase}/pruebaget_con_rparams/${param}`;
      return await rawReqJson.requestGET(url);
    },
    post: async (dataObj = {tipo: 'test'}) => {
      const url = `${urlBase}/pruebapost`;
      return await rawReqJson.requestPOST(url, dataObj);
    },
    get_ruta_protected: async () => {
      const url = `${urlBase.urlBase}/admin/prueba`;
      return await rawReqJson.requestGET(url);
    }
  }
};

export default factoryApiPrueba;