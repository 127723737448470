import React from 'react';
import LibFormat from "../../../../lib/LibFormat";
import GoldOutlined from "@ant-design/icons/lib/icons/GoldOutlined";

const CDetalleVentaItems = ({venta}) => {
  
  const detalleVenta = venta.detalleVenta;
  
  
  const listaItem = detalleVenta.listaItem;
  
  const listaDetalleItem = listaItem
    .map((k, indexK) => {
      
      const subtotal = k.q * k.precio;
      
      return (
        <tr key={indexK}>
          <td>
            <a href={`https://tiendatelcel.com.mx/venta-amigo-kit-y-accesorios/${k.id_item}-`} target="_blank">
              {k.id_item}
            </a>
          </td>
          <td>
            <h4>{k.tipo} {k.entrada}</h4>
            {k.nombreProducto}
          </td>
          <td className="hasTextCenter">{k.q}</td>
          <td className="hasTextRight">$ {LibFormat.Money(k.precio)}</td>
          <td className="hasTextRight">$ {LibFormat.Money(subtotal)}</td>
        </tr>
      );
      
    })
  ;
  
  
  const trDescuento = detalleVenta.subtotalDescuento > 0
    ? <tr>
      <th colspan="6" className="hasTextRight">Subtotal Descuento</th>
      <th className="hasTextRight">- $ {LibFormat.Money(detalleVenta.subtotalDescuento)}</th>
    </tr>
    : null;
  
  
  return (
    <>
      <h2 className="tituloReporte"><GoldOutlined className="mR5"/> Items</h2>
      <table className="table  tableDataForm" >
        <thead>
        <tr>
          <th style={{textAlign:"left"}}>Code</th>
          <th>Equipo</th>
          <th className="hasTextCenter">Cant</th>
          <th className="hasTextRight">PU <br/>(MX)</th>
          <th className="hasTextRight" style={{width: "140px"}}>Subtotal<br/>(MX)</th>
        </tr>
        </thead>
        <tbody>
        {listaDetalleItem}
        <tr>
          <th colSpan="4" className="hasTextRight">Subtotal Producto</th>
          <th className="bordeTotal">$
            {LibFormat.Money(detalleVenta.subtotalProductos)}
          </th>
        </tr>
        {trDescuento}
        <tr>
          <th colSpan="4" className="hasTextRight">Subtotal Envio</th>
          <th className="hasTextRight">$ {LibFormat.Money(detalleVenta.subtotalEnvio)}</th>
        </tr>
        <tr>
          <th colSpan="4" className="hasTextRight">Gran Total</th>
          <th className="hasTextRight bordeTotal">$ {LibFormat.Money(detalleVenta.total)}</th>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default CDetalleVentaItems