import React  from 'react';
import DeleteFilled from "@ant-design/icons/lib/icons/DeleteFilled";
import './imgitem.css';
import {Button} from "antd";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";

export default ({url, isPrincipal, onShowModalDeleteImg, onSetPrincipal}) => {
  
  const css = isPrincipal
    ? "isPrincipal"
    : "";
  
  return (
    <div className={"wrapperImgItem " + css}>
      <Button
        title="Elegir como principal"
        onClick={() => onSetPrincipal(url)} className="left">
        <CheckOutlined/>
      </Button>
      <Button
        title="Eliminar imagen"
        onClick={() => onShowModalDeleteImg(url)} className="right">
        <DeleteFilled/>
      </Button>
      
      <a href={url} target="_blank"
         rel="noopener noreferrer"
      >
        <img src={url} alt={url}/>
      </a>
    </div>
  )
    ;
}