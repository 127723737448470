import LibCopyXls from "../../../../lib/LibCopyXls";


export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id_promocion', "titulo","url_rel", "urlAbs","descripction","year","url_img","fecha_ini","fecha_final","is_largo_plazo","fabricante","is_all_equipo","priodirad","created_at","updated_at"];
  
  const listaCampos=listaHeader.filter(s =>s !=="#");
  
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    
    const listaValor = [(indexk + 1)];
    
    listaCampos.forEach(key => {
      listaValor.push(k[key]);
    });
    
    return listaValor;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}