import React from "react";
import DataStore from "../../app/DataStore";
import {Dropdown, Menu} from "antd";
import ACmdRowMobile from "./ACmdRowMobile";


export default ({listaOpciones, model = null, onCmdEnd, btnTipo, titulo}) => {
  
  const isMobile = !DataStore.IsDesktop.Get();
  
  if (isMobile) {
    //En caso de ser mobile se muestra como un modal
    return (
      <ACmdRowMobile
        listaOpciones={listaOpciones}
        model={model}
        onCmdEnd={onCmdEnd}
        btnTipo={btnTipo}
        titulo={titulo}
      />
    );
    
  }
  
  const menuClickHandler = (keyMenu) => {
    onCmdEnd(keyMenu, model);
  };
  
  
  const itemsMenu = listaOpciones.map((k, indexk) => {
    
    if (k.isDivider) {
      return <Menu.Divider key={indexk}/>
    }
    
    return (
      <Menu.Item key={k.key}
                 style={k.style || null}
                 icon={k.icon || null}>
        {k.label}
      </Menu.Item>
    );
  });
  
  const menu = (
    <Menu
      onClick={(e) => menuClickHandler(e.key)}
    >
      {itemsMenu}
    </Menu>
  
  );
  
  return (
    <Dropdown.Button
      type={btnTipo}
      overlay={menu}
      placement="bottomLeft"
    />);
}