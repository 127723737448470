import * as LibTexto from "@iqdavidh/libfront/src/LibTexto";

let lista = [];

const regFlash = (titulo, msg, tipo) => {
  const id = LibTexto.getRandomString();
  lista.push({id, titulo, msg, tipo})
};

export default {
  Registrar: {
    Success: (titulo, msg) => regFlash(titulo, msg, "success"),
    Alert: (titulo, msg) => regFlash(titulo, msg, "alert"),
    Info: (titulo, msg) => regFlash(titulo, msg, "info"),
  },
  Get: () => {
    if (lista.length === 0) {
      return null;
    }
    
    const lastItem = lista[lista.length - 1];
    
    lista = lista.filter(k => {
      return k.id !== lastItem.id;
    });
    
    return lastItem;
  }
}