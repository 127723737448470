import React from "react";
import {Modal} from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export default ({urlFoto, visible, onClickModalDelete}) => {
  
  const titulo = (
    <>
      <DeleteOutlined style={{color: "red"}}/>
      <span style={{color: "red"}}>Eliminar Foto</span>
    </>
  );
  
  return (
    <Modal
      title={titulo}
      visible={visible}
      onOk={() => onClickModalDelete(urlFoto)}
      onCancel={() => onClickModalDelete(false)}
    >
      <div className="hasTextCenter">
        <img src={urlFoto} alt={urlFoto} style={{width:"60%"}} />
      </div>
    
    </Modal>
  );
}