import React, {useEffect, useState} from "react";
import {Button, Dropdown, Menu, Modal} from "antd";
import {
  DownloadOutlined, EditOutlined,
  FileImageFilled,
  FileTextFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import CLoading from "../../../../components/page/CLoading";
import DataService from "../../../../service/data/DataService";
import LibShowNotificacion from "../../../../lib/LibShowNotificacion";
import VideoCameraOutlined from "@ant-design/icons/lib/icons/VideoCameraOutlined";
import LaptopOutlined from "@ant-design/icons/lib/icons/LaptopOutlined";
import GoldOutlined from "@ant-design/icons/lib/icons/GoldOutlined";

export default ({idPag, onContenidoAdded}) => {
  
  const [dataConfirmarAdd, setDataConfirmarAdd] = useState(null);
  const [isEnProceso, setIsEnProceso] = useState(false);
  
  useEffect(() => {
    //inicailziar que no se ve la confirmacion
    setDataConfirmarAdd(null);
  }, [])
  
  
  const titulo = (
    <>
      <PlusCircleOutlined/>
      <span> Agregar Elemento</span>
    </>
  );
  
  
  function onMnuAddElemClick(tipoAdd) {
    setDataConfirmarAdd({tipo: tipoAdd});
  }
  
  
  const menuAddElem = (
    <Menu onClick={(e) => onMnuAddElemClick(e.key)}>
      <Menu.Item key="img" icon={<FileImageFilled/>}>
        Imagen
      </Menu.Item>
      <Menu.Item key="html" icon={<FileTextFilled/>}>
        Html
      </Menu.Item>
      <Menu.Item key="slider3" icon={<VideoCameraOutlined/>}>
        Slider 3 Imagenes
      </Menu.Item>
      <Menu.Item key="bloqueS3" icon={<GoldOutlined />}>
        Bloque Estático de 3 Imagenes
      </Menu.Item>
      <Menu.Item key="download" icon={<DownloadOutlined/>}>
        Link para descarga
      </Menu.Item>
      <Menu.Item key="iframe" icon={<LaptopOutlined/>}>
        IFRAME para aplicaciones
      </Menu.Item>
      <Menu.Item key="html_raw" icon={<EditOutlined />}>
        Html Raw
      </Menu.Item>
    
    </Menu>
  );
  
  
  const onClickModalConfirmar = async (isConfirmado) => {
    if (isConfirmado === false) {
      setDataConfirmarAdd(null);
    }
    
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    
    //Hacer el request del update ---------------------
    const tipo = dataConfirmarAdd.tipo;
    
    const respuesta = await DataService.Editor.PagContenido.Add(idPag, tipo);
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al guardar", respuesta.msg);
      setDataConfirmarAdd(null);
      setIsEnProceso(false);
      return;
    }
    
    const {id_pag_contenido, posicion} = respuesta.data.pag_contenido;
    
    LibShowNotificacion.Success("Elemento Creado");
    setDataConfirmarAdd(null);
    setIsEnProceso(false);
    
    const newElemento = {
      id_pag_contenido_tipo: tipo,
      id_pag_contenido,
      id_pag: idPag,
      body: "",
      posicion
    }
    
    onContenidoAdded(newElemento);
    
  }
  
  const modal = dataConfirmarAdd
    ? (<Modal
      title={titulo}
      visible={true}
      onOk={() => onClickModalConfirmar(true)}
      onCancel={() => onClickModalConfirmar(false)}>
      <p>Confirmar que se desea agregar un nuevo elemento</p>
      <h2>{dataConfirmarAdd.tipo.toString().toUpperCase()}</h2>
    </Modal>)
    : null;
  
  const msgProceso = isEnProceso
    ? <CLoading mensaje="Guardando"/>
    : null;
  
  return (<>
      <div style={{marginTop: "30px", textAlign: "center"}}>
        <Dropdown overlay={menuAddElem}>
          <Button>
            Agregar Nuevo Elemento <DownOutlined/>
          </Button>
        </Dropdown>
      </div>
      
      {modal}
      
      {msgProceso}
    </>
  );
  
}