import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import {EditFilled} from "@ant-design/icons";


export default ({pag,onSetModoEdit}) => {
  
  
  const trMetasHead = pag.metas_head
    ? <tr>
      <th>Metas Head</th>
      <td><Button>Ver</Button></td>
    </tr>
    : null
  
  const trMetasEnd = pag.metas_end
    ? <tr>
      <th>Metas End</th>
      <td><Button >Ver</Button></td>
    </tr>
    : null
  
  return (
    <div>
  
      <Row>
        <Col xs={24} sm={24} md={24}>
          <table className="tableDataForm fix">
            <tbody>
            <tr>
              <th>
                Título
              </th>
              <td>
                <h3 className="colorResaltar" style={{padding: "0", margin: "0"}}>{pag.titulo}</h3>
              </td>
            </tr>
            <tr>
              <th>
                Descripción
              </th>
              <td>
                {pag.description}
              </td>
            </tr>
            <tr>
              <th>
                Keywords
              </th>
              <td>
                {pag.keywords}
              </td>
            </tr>
            {trMetasHead}
            {trMetasEnd}
            </tbody>
          </table>
        
        </Col>
      </Row>
      <div className="hasTextRight mtopMin">
        <Button onClick={()=>onSetModoEdit()}>
          <EditFilled/>
          Editar
        </Button>
      </div>

    </div>
  );
}