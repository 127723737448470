import React from "react";


export default ({urlAbs}) => {
  
  if (!urlAbs) {
    return  null;
  }
  
  return (
    <a href={urlAbs} target="_blank"
       rel="noopener noreferrer" title={urlAbs}>
      <img src={urlAbs} className="imgIndex" alt=""/>
    </a>
  );
}