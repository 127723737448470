import React, {useEffect, useState} from 'react';
import CLoading from "../../../../components/page/CLoading";
import {Editor} from '@tinymce/tinymce-react';
import HeaderElementoContenido from "./HeaderElementoContenido";
import CSinContenido from "./CSinContenido";
import './contenidoHtml.css'

const parse = require('html-react-parser');

export default ({itemContenido, indexItem, onItemUpdated,onShowDelElementModal}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModoEdit, setIsModoEdit] = useState(false);
  const [contenidoEditor, setContenidoEditor] = useState(null);
  
  useEffect(() => {
    setIsModoEdit(false);
    setIsLoaded(true);
  }, [itemContenido]);
  
  
  if (!isLoaded) {
    return <CLoading mensaje="Loading Contenido"/>
  }
  
  /* Modo Edit  *********************************** **/
  
  if (isModoEdit) {
    
    
    const handleEditorChange = (e) => {
      setContenidoEditor(e.target.getContent());
    }
    
    const onClickSave = () => {
      
      const cbOnFinishUpdate = (isSuccess) => {
        if (isSuccess === true) {
          setIsModoEdit(false)
        }
      };
      onItemUpdated(contenidoEditor, indexItem, cbOnFinishUpdate);
    }
    
    return (
      <div className="wrapperEditorHtml">
        
        <HeaderElementoContenido
          indexItem={indexItem}
          onClickSave={onClickSave}
          subtitulo = "Texto HTML"
          onClickDelete={()=>onShowDelElementModal(indexItem)}
          setIsModoEdit={setIsModoEdit}
          isEdit={true}
          id={itemContenido.id_pag_contenido||''}
          codigo={itemContenido.codigo||''}
        />
        
        <Editor
          apiKey="jivpxq83jimcghxso8cb5qi7r0fvqseqeeohhgz3n2j8a95w"
          initialValue={itemContenido.body || ''}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent | help'
          }}
          onChange={handleEditorChange}
        
        />
      </div>
    );
  }
  
  /* Modo Lectura ********************************* **/
  
  let fnRenderBase = (body) => {
    
    if (body) {
      return (
        <div className="wrapperItemContenido">
  
          <HeaderElementoContenido
            indexItem={indexItem}
            setIsModoEdit={setIsModoEdit}
            subtitulo = "Texto HTML"
            isEdit={false}
            id={itemContenido.id_pag_contenido||''}
            codigo={itemContenido.codigo||''}
          />
          
          <div style={{textAlign:"center" }}>
            <div style={{maxWidth:"700px", "width":"100%", textAlign:"justify", display:"inline-block"}}>
              {parse(body)}
            </div>
            
          </div>
        </div>
      );
    } else {
  
      return (
        <CSinContenido id_pag_contenido={itemContenido.id_pag_contenido}
                       setIsModoEdit={setIsModoEdit}
                       indexItem={indexItem}
                       subtitulo="Sin Html"
        />
      );
  
    }
    
  }
  
  return fnRenderBase(itemContenido.body);
  
}