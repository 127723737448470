import React from 'react';
import FlashMsg from "../../app/FlashService";
import LibShowNotificacion from "../../lib/LibShowNotificacion";
import {Helmet} from "react-helmet";


export default (props) => {
  const flash = FlashMsg.Get();
  
  if (flash !== null) {
    if (flash.tipo === "Alert") {
      LibShowNotificacion.Alert(flash.titulo, flash.msg);
    } else if (flash.tipo === "Success") {
      LibShowNotificacion.Success(flash.titulo, flash.msg);
    } else {
      LibShowNotificacion.Info(flash.titulo, flash.msg);
    }
  }
  
  return (<>
    <Helmet>
      <title>{props.titulo} :: Adm Tienda Telcel</title>
    </Helmet>
    {props.children}
  </>);
}