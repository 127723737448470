import React, {useEffect, useState} from 'react';

import {Button, Card, Checkbox} from 'antd';
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";

import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import DataService from "../../../service/data/DataService";


const CEditPermisos = ({idUsuario, listaTipoPermiso, listaPermisos, onFormSubmited}) => {
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [dicPermiso, setDicPermiso] = useState({});
  const [dicIdPermisoNombre, setDicIdPermisoNombre] = useState({});
  
  
  useEffect(() => {
    
    const listaIdPermiso = listaPermisos.map(k => k.id_permiso);
    
    const dic = {};
    const dicID = {}
    listaTipoPermiso.forEach(row => {
      dicID[row.nombre] = row.id_usuario_permiso_tipo;
      dic[row.nombre] = listaIdPermiso.includes(row.id_usuario_permiso_tipo) ? "checked" : "";
    });
    
    setDicPermiso(dic)
    setDicIdPermisoNombre(dicID)
    
  }, [listaTipoPermiso, listaPermisos]);
  
  
  const onUpdatePermiso = (nombre, checked) => {
    const dicNew = {}
    dicNew[nombre] = checked; //es string
    setDicPermiso({...dicPermiso, ...dicNew});
  }
  const listaElemChk = listaTipoPermiso.map((k, indexK) => {
    
    const onChange = (event) => {
      const isChecked = event.target.checked;
      return onUpdatePermiso(k.nombre, isChecked ? "checked" : "");
    };
    
    const checked = dicPermiso[k.nombre] === "checked";
    
    return (
      <div key={indexK}>
        <Checkbox
          checked={checked}
          onChange={onChange}>
          {k.nombre}
        </Checkbox>
        <br/>
        <small>{k.comentario}</small>
      </div>
    );
  });
  
  const onSubmitForm = async () => {
    
    if (isEsProceso) {
      return;
    }
    
    setIsEnProceso(true);
    setMsgError("");
    
    const listaIdPermisoTipoNew = Object
      .keys(dicPermiso)
      .filter(key => !!dicPermiso[key])
      .map(key => dicIdPermisoNombre[key])
    ;
    
    
    const respuesta = await DataService.Admin.Usuario.UpdatePermiso(idUsuario, listaIdPermisoTipoNew)
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    setIsEnProceso(false);
    LibShowNotificacion.Success("Permisos Actualizados");
    onFormSubmited(listaIdPermisoTipoNew);
    
  };
  
  
  return (
    <div>
  
      <Card bordered={true}>
        {listaElemChk}
      </Card>
  
      <div className="wrapperSaveCancel">
        <Button type="primary"
                onClick={onSubmitForm}
                loading={isEsProceso}>
          <CloudUploadOutlined/>
          <span>Guardar Permisos</span>
        </Button>
  
      </div>
  
      <div className="msgError">
        {msgError}
      </div>
    </div>
  );
};

export default CEditPermisos