import React from 'react';
import {UserOutlined} from "@ant-design/icons";

const CDetalleVentaCliente = ({venta}) => {
  
  const c=venta.detalleVenta.cliente;
  
  return (
    <>
      <h2 className="tituloReporte"> <UserOutlined/>  Comprador</h2>
      <strong>{c.nombre} {c.apellido1} {c.apellido2}</strong><br/>
      <strong>{c.email}</strong><br/>
      <strong>{c.tel1} / {c.tel2}</strong>
    </>
  );
};

export default CDetalleVentaCliente