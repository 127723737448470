import {Alert, Spin} from "antd";
import React from "react";

export default ({mensaje = "Procesando"}) => {
  return (
    <Spin tip="">
      <Alert
        message={mensaje}
        description=""
        type="info"
      />
    </Spin>
  );
}