import React, {useEffect, useState} from 'react';
import BorderOutlined from "@ant-design/icons/lib/icons/BorderOutlined";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";
import DownSquareFilled from "@ant-design/icons/lib/icons/DownSquareFilled";
import {UpSquareFilled} from "@ant-design/icons";
import DataService from "../../../service/data/DataService";

export default ({item, onItemUpdated}) => {
  const [posHome, setPosHome] = useState(item.pos_home);
  const [isEnProceso, setIsEnProceso] = useState(false)
  
  
  useEffect(() => {
    setIsEnProceso(false);
    setPosHome(item.pos_home);
  }, [item])
  
  
  const requestSetPosHome = async (valor) => {
    
    const respuesta = await DataService.Editor.Item.Update(item.id_item, {pos_home: valor})
    setIsEnProceso(false)
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert(respuesta.msg);
      return;
    }
    LibShowNotificacion.Success("Item Actualizado", item.id_item)
    
    setPosHome(valor);
    item.pos_home=valor
    onItemUpdated(item)
  }
  
  const onCambiarPos = async (delta) => {
    
    if (isEnProceso) {
      return;
    }
    
    if (posHome === 0 && delta < 0) {
      return;
    }
    
    setIsEnProceso(true);
    const newPos = posHome + delta;
    await requestSetPosHome(newPos);
  };
  
  const onClickQuitarDeHome = async () => {
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    const newPos = 0;
    await requestSetPosHome( newPos)
    
  };
  
  
  const onClickMostrarEnHome = async () => {
    if (isEnProceso) {
      return;
    }
    
    setIsEnProceso(true);
    const newPos = posHome > 0 ? 0 : 1
    await requestSetPosHome(newPos)
    
  }
  
  const iconHome = posHome > 0
    ? (<>
      <UpSquareFilled className="cambiar" onClick={() => onCambiarPos(1)}/>
      <div title="Quitar de Home" onClick={onClickQuitarDeHome} className="cursorPointer" >
        {item.pos_home}
      </div>
      <DownSquareFilled className="cambiar" onClick={() => onCambiarPos(-1)}/>
    </>)
    : <BorderOutlined style={{color: "#b3b3b3"}} onClick={onClickMostrarEnHome}/>;
  
  
  const titulo = posHome > 0
    ? `Posición ${posHome} en home`
    : 'Seleccionar para mostrar en home'
  
  
  return (
    
    <div title={titulo} className="wrapperCmdHomePos">
      {iconHome}
    </div>
  
  );
  
};

