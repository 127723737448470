import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Col, Row, Tag} from "antd";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";

import "../../../components/tabla/table.css";
import UrlPage from "../../UrlPage";
import DataStore from "../../../app/DataStore";

import usuarioFiltroParam from "./tablaUsuario/UsuarioFiltroParam";
import CmdUsuarioRow from "./tablaUsuario/CmdUsuarioRow";
import ModalDelete from "./tablaUsuario/UsuarioModalDelete";
import ATabla from "../../../components/tabla/ATabla";
import CmdUsuarioIndex from "./tablaUsuario/CmdUsuarioIndex";
import FnCopyXlsUsuario from "./tablaUsuario/FnCopyXlsUsuario";
import LibFormat from "../../../lib/LibFormat";

let isModalDeleteEnProceso = false;

export default () => {
  const history = useHistory();
  
  const [listaUsuario, setListaUsuario] = useState([]);
  const [filtroUsuario, setFiltroUsuario] = useState({current: "- Sin Filtrar Datos -", valor: ""});
  const [listaUsuarioFiltrado, setListaUsuarioFiltrado] = useState([]);
  
  const [dataModalDelete, setDataModalDelete] = useState(null);
  
  const [idSelected, setIdSelected] = useState(DataStore.UsuarioIndex.GetIdSelected() || null);
  
  useEffect(() => {
    (async () => {
      
      const respuesta = await DataService.Admin.Usuario.Index();
      if (respuesta.success) {
        const lista = respuesta.data.lista;
        setListaUsuario(lista);
        DataStore.UsuarioIndex.Set(lista);
        LibShowNotificacion.Success("Usuarios", "Datos Recibidos")
      } else {
        LibShowNotificacion.Alert("Error", respuesta.msg);
      }
      
    })();
    
  }, []);
  
  
  useEffect(() => {
    const lista = listaUsuario.filter(item => {
      return usuarioFiltroParam.fn(item, filtroUsuario.current, filtroUsuario.valor)
    });
    setListaUsuarioFiltrado(lista);
    
  }, [listaUsuario, filtroUsuario]);
  
  
  const onCmdRowEnd = async (operacion, usuario) => {
    
    const {id_usuario, email} = usuario;
    
    if (operacion === "delete") {
      setDataModalDelete(usuario);
      
    } else if (operacion === "edit") {
      setIdSelected(id_usuario);
      history.push(UrlPage.ADMIN_USUARIO_EDIT + `/${id_usuario}`);
  
    } else if (operacion === "send_pass") {
      const respuestaSend = await DataService.Admin.Usuario.SendTempPass(id_usuario);
      if (!respuestaSend.success) {
        LibShowNotificacion.Alert(respuestaSend.msg);
      } else {
        LibShowNotificacion.Success("Correo enviado", "Invitación a " + email);
      }
    }
    
  };
  
  const listaElem = listaUsuarioFiltrado.map((k, index) => {
    
    const rowCSS = "rowTabla " + (idSelected === k.id_usuario ? " rowSelected" : "");
    
    
    return (
      <Row key={index} className={rowCSS}>
        
        <Col xs={24} sm={8} md={8}>
          
          <div className="divMainRow mL5">
            <CmdUsuarioRow usuario={k} onCmdEnd={onCmdRowEnd}/>
            <div className="pL5">
              <span className="labMob labMobT1">Nombre:</span>
              {k.nombre || '-NA-'} <br/>
            </div>
          </div>
          
        </Col>
        
        <Col xs={24} sm={8} md={8}>
          <span className="labMob">Email:</span> {k.email} <br/>
          <span className="labMob">Teléfono:</span> {LibFormat.Phone(k.tel)}
        </Col>
      
      
      </Row>
    );
  });
  
  const onClickModalDelete = async (isBottonOK) => {
    if (isModalDeleteEnProceso) {
      return;
    }
    
    isModalDeleteEnProceso = true;
    
    if (isBottonOK === true) {
      const respuesta = await DataService.Admin.Usuario.Delete(dataModalDelete.idUsuario);
      if (!respuesta.success) {
        isModalDeleteEnProceso = false;
        LibShowNotificacion.Alert(respuesta.msg);
        return;
      }
      
      LibShowNotificacion.Success("Usuario Eliminado", dataModalDelete.nombre);
      const lista = listaUsuario.filter(k => {
        return k.idUsuario !== dataModalDelete.idUsuario
      });
      setListaUsuario(lista);
    }
    
    isModalDeleteEnProceso = false;
    setDataModalDelete(null);
    
  };
  
  const modalDelete = (
    <ModalDelete
      usuario={dataModalDelete}
      visible={dataModalDelete !== null}
      onClickModalDelete={onClickModalDelete}/>
  );
  
  const header = (
    <Row className="rowHeader">
      <Col xs={24} sm={6} md={8}>
        Nombre
      </Col>
      <Col xs={24} sm={8} md={8}>
        email<br/>Teléfono
      </Col>
    </Row>
  );
  
  const onCmdIndexEnd = (operacion) => {
    if (operacion === "add") {
      DataStore.UsuarioIndex.SetIdSelected(null);
      history.push(UrlPage.ADMIN_USUARIO_ADD)
      
    } else if (operacion === "copiar_xls") {
      FnCopyXlsUsuario(listaUsuarioFiltrado);
      LibShowNotificacion.Info("Copiado", 'Lista Filtrada');
    }
  };
  const getCmdIndex = () => <CmdUsuarioIndex onCmdEnd={onCmdIndexEnd}/>;
  
  return (
    <ATabla
      header={header}
      listaElem={listaElem}
      numItemsFiltro={listaUsuarioFiltrado.length}
      modalDelete={modalDelete}
      cmdIndex={getCmdIndex()}
      tituloModel="Usuario"
      filtroConfig={usuarioFiltroParam.config}
      setFiltro={setFiltroUsuario}
      filtro={filtroUsuario}
    />
  );
  
}