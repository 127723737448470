import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import {EditFilled} from "@ant-design/icons";
import * as LibFecha from "@iqdavidh/libfront/src/LibFecha";

export default ({ promocion,onSetModoEdit}) => {
  
  const trMetasHead = promocion.metas_head
    ? <tr>
      <th>Metas Head</th>
      <td><Button>Ver</Button></td>
    </tr>
    : null
  
  const trMetasEnd = promocion.metas_end
    ? <tr>
      <th>Metas End</th>
      <td><Button >Ver</Button></td>
    </tr>
    : null
  
  return (
    <div>
  
      <Row>
        <Col xs={24} sm={24} md={24}>
          <table className="tableDataForm fix">
            <tbody>
            <tr>
              <th>
                Título
              </th>
              <td>
                <h3 className="colorResaltar" style={{padding: "0", margin: "0"}}>{promocion.titulo}</h3>
              </td>
            </tr>
            <tr>
              <th>
                Estatus
              </th>
              <td>
                {promocion.is_activo?"Activa":<span style={{color:"red"}}>En Pausa</span>}
              </td>
            </tr>
            <tr>
              <th>
                Periodo
              </th>
              <td>
                {LibFecha.getDMYFromYMD(promocion.fecha_ini)} a { LibFecha.getDMYFromYMD(promocion.fecha_final)}
              </td>
            </tr>
            <tr>
              <th>
                Alcance
              </th>
              <td>
                Fabricante: <span>{promocion.fabricante || 'NA'}</span>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th>*/}
            {/*    Descripción*/}
            {/*  </th>*/}
            {/*  <td>*/}
            {/*    {promocion.description}*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th>*/}
            {/*    Keywords*/}
            {/*  </th>*/}
            {/*  <td>*/}
            {/*    {promocion.keywords}*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {trMetasHead}
            {trMetasEnd}
            </tbody>
          </table>
  
          <div className="hasTextRight mtopMin">
            <Button onClick={()=>onSetModoEdit()}>
              <EditFilled/>
              Editar
            </Button>
          </div>
  
          
          
          
        </Col>
        
      </Row>
      

    </div>
  );
}