import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Input, Form, Card} from 'antd';
import "../../../components/form/form.css";

import LibFormAnt from "../../../lib/LibFormAnt";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DataService from "../../../service/data/DataService";
import LibShowNotificacion from "../../../lib/LibShowNotificacion";


import FlashService from "../../../app/FlashService";
import DataStore from "../../../app/DataStore";
import UrlPage from "../../../page/UrlPage";
import RollbackOutlined from "@ant-design/icons/lib/icons/RollbackOutlined";


const FormItem = Form.Item;


const FormUsuario = ({usuario, onUsuarioSaved}) => {
  
  const history = useHistory();
  
  const [isEsProceso, setIsEnProceso] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [form] = Form.useForm();
  
  const listaUsuarioTipo = DataStore.ListaUsuarioTipo.Get();
  
  
  useEffect(() => {
    form.setFieldsValue({
      idU_usuario: usuario.id_usuario||null,
      nombre: usuario.nombre||'',
      nick: usuario.nick||'',
      email: usuario.email||'',
      tel: usuario.tel||''
    });
    
    
  }, [usuario, form]);
  
  const isNewUsuario = !usuario.id_usuario;
  
  const onSubmitForm = async (formData) => {
    
    if (isEsProceso) {
      return;
    }
    
    
    setIsEnProceso(true);
    setMsgError("");
    
    const isUsuarioNew = usuario.id_usuario === undefined;
    
    let respuesta = null;
    
    if (isUsuarioNew) {
      //es un create
      respuesta = await DataService.Admin.Usuario.Add(formData);
    } else {
      //es un update
      respuesta = await DataService.Admin.Usuario.Update(usuario.id_usuario, formData);
      
    }
    
    
    if (!respuesta.success) {
      LibShowNotificacion.Alert("Error al Guardar", respuesta.msg);
      setIsEnProceso(false);
      setMsgError("Error " + respuesta.msg);
      return;
    }
    
    onUsuarioSaved(respuesta.data);
    
  };
  
  const onCancelForm = () => {
    //Hacer el refresh ----------------------------------
    DataStore.UsuarioIndex.SetIsCancel();
    
    let titulo = isNewUsuario ? "Nuevo Usuario" : `Actualización ${usuario.nombre} `;
    FlashService.Registrar.Info(titulo, "Operación cancelada");
    history.push(UrlPage.ADMIN_USUARIO_INDEX);
    
  };
  
  const tagU = isNewUsuario ? " Nuevo" : " ID " + usuario.id_usuario;
  const tituloUsuario = <div className="titCard"><span>Usuario {tagU}</span></div>;
  
  
  return (
    <div>
      <Form
        form={form}
        validateMessages={LibFormAnt.ConfigMsgValidacion}
        {...LibFormAnt.LayOutLabelCorto}
        onFinish={onSubmitForm}
      >
        <Card title={tituloUsuario} bordered={true}>
          
          <FormItem
            label="Nombre Completo"
            name="nombre"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
          <FormItem
            label="Nick"
            name="nick"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
          
          <FormItem
            label="Email"
            name="email"
            rules={[{required: true}, {type: "email"}]} hasFeedback>
            <Input/>
          </FormItem>
          
          <FormItem
            label="Celular"
            name="tel"
            rules={[{required: true}]} hasFeedback>
            <Input/>
          </FormItem>
  
        </Card>
        
        <div className="wrapperSaveCancel">
          <Button type="primary"
                  htmlType="submit"
                  loading={isEsProceso}>
            <CloudUploadOutlined/>
            <span>Guardar Campos</span>
          </Button>
          
          <Button
            onClick={() => onCancelForm()}
            className={isEsProceso ? "hide" : ""}>
            <RollbackOutlined/>
            <span>Cancelar</span>
          </Button>
        
        </div>
        
        <div className="msgError">
          {msgError}
        </div>
      
      </Form>
    </div>
  );
};

export default FormUsuario