import LibCopyXls from "../../../../lib/LibCopyXls";


export default (listaIndexFiltrado) => {
  const listaHeader = ['#', 'id_recordatorio'
    , 'fecha_ini', 'fecha_fin', 'nombre_evento', 'urlImg','urlImgMovil'
    , 'bodyHtml', 'usuarioCreated'
    , "created_at", "updated_at"];
  
  const listaCampos = listaHeader.filter(s => s !== "#");
  
  const listaBody = listaIndexFiltrado.map((k, indexk) => {
    
    
    const listaValor = [(indexk + 1)];
    
    listaCampos.forEach(key => {
      listaValor.push(k[key]);
    });
    
    return listaValor;
    
  });
  
  LibCopyXls(listaHeader, listaBody);
}